// actions.js
export const updateUserHistory = (data) => ({
    type: "UPDATE_USER_QUESTIONNAIRE_HISTORY", // <-- Update the action type
    payload: data,
  });
  
export const updateContentGenerationHistory = (data) =>({
  type:"UPDATE_CONTENT_GENERATION_HISTORY",
  payload:data,
})

export const updateUploadsHistory = (data) =>({
  type:"UPDATE_UPLOADS_HISTORY",
  payload:data,
})

export const updateReduxUsername=(data)=>({
  type:"UPDATE_REDUX_USERNAME",
  payload:data
})


export const updateReduxUserDomains=(data)=>({
  type:"UPDATE_REDUX_USER_DOMAINS",
  payload:data
})

export const updateReduxUserTopics=(data)=>({
  type:"UPDATE_REDUX_USER_TOPICS",
  payload:data
})


export const reduxLogoutHandler=()=>({
  type:"LOGOUT"
})

export const updateReduxProfileImage=(data)=>({
  type:"UPDATE_REDUX_PROFILE_IMAGE",
  payload:data
})


export const updateCanSearchCompetitor=(data)=>({
  type:"UPDATE_CAN_SEARCH_COMPETITOR",
  payload:data
})





































































































