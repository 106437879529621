import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  contactSuccessOverlay: {
    overflow: "hidden",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  contactSuccessContainer: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
  },
  icon: {
    fontSize: 80,
    margin: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const ContactSuccess = ({
  showQuestionnaireSuccess,
  setShowQuestionnaireSuccess,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.contactSuccessOverlay}>
      <div
        className={`col col-10 col-sm-7 col-lg-6 py-5 contact-success-container ${classes.contactSuccessContainer}`}
      >
        <CheckCircleIcon
          className={classes.icon}
          style={{ color: "#4CAF50" }}
        />
        <Typography variant="h5" className={classes.heading}>
          Thank You for Sharing Your Insights!
        </Typography>
        <Typography>
          Thank you for sharing your thoughts! We're crafting a personalized
          report based on your unique input. Keep an eye out for a curated list
          of domains to enhance your content creation journey.
        </Typography>
        <br></br>
        <Typography>
          We're thrilled to contribute to your upcoming YouTube success story!
        </Typography>
        <br></br>
        <Button
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "50%",
          }}
          onClick={() => {
            setShowQuestionnaireSuccess(!showQuestionnaireSuccess);
            navigate("/dashboard");
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default ContactSuccess;
