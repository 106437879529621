import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Signup.css'
import google from '../../assets/google.png'
import {useState,useEffect} from 'react'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';


// toast.configure()

function Profile(){
const [username,setUsername]=useState()
const [emailID,setEmailID]=useState()
const [channelID,setChannelID]=useState()
const [password,setPassword]=useState()
const [firstName,setFirstName]=useState()
const [lastName,setLastName]=useState()
const [inputType,setInputType] = useState('password')
const navigate = useNavigate();


const [user, setUser] = useState([]);
const [profile,setProfile] = useState()

const hashString = (password) => {
  // Hash the string using SHA-256
  const hashedString = CryptoJS.SHA256(password).toString();
  return hashedString;
};

const login = useGoogleLogin({
  onSuccess: (codeResponse) => {setUser(codeResponse)
    console.log(codeResponse)
  },
  onError: (error) => console.log("Login Failed:", error)
});


const setCustomExpiryCookie=(name, value, expirationDate)=>{
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
}


const authSubmitHandler=async (payload)=>{
 console.log("Into the auth submit handler function")
 try{
   const response=await fetch(`${process.env.REACT_APP_SERVER_URL}/addGoogleUser`,{
    method:"POST",
    headers:{
      'Content-type':'application/json'
    },
    body:JSON.stringify(payload)
   })
   const responseData=await response.json()
   return responseData
 }catch(error){
  console.log("error")
  console.log(error)
 }
}


useEffect(() => {
  if (user) {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then(async(res) => {
        console.log(res.data)
        setProfile(res.data);
        let authResponse=res.data
        authResponse.id=hashString(authResponse.id)
        console.log("The auth response is : ",authResponse)
        const response=await authSubmitHandler(authResponse)
        if(response.status==="success"){
          console.log("Success")
          console.log(response)
          toast.success("User created Successfully",{position:toast.POSITION.TOP_CENTER})
          Cookies.set('session_id',response.session_id)
          Cookies.set('expiry',response.expiry)
          const expiry_date=new Date(response.expiry)
          setCustomExpiryCookie('session_id', response.session_id, expiry_date);
          Cookies.set('user_id',response.user_id)
          window.postMessage({ action: "messageFromWebPage", data: response.session_id}, "*");                        
          navigate('/dashboard')
        }else if(response.status==="error"){
          console.log("error")
          console.log(response)
          // toast.error("Something Went Wrong!!",{position:toast.POSITION.TOP_CENTER})
        }else if(response.status==="DUPLICATE_EMAIL"){
          toast.error(response.message,{position:toast.POSITION.TOP_CENTER})
        }
      })
      .catch((err) => console.log(err));
  }
}, [user]);

const usernameHandler = event =>{
    setUsername(event.target.value)
}

const emailHandler = event =>{
    setEmailID(event.target.value)
}

const passwordHandler = event =>{
    setPassword(event.target.value)
}


const firstNameHandler = event =>{
    setFirstName(event.target.value)
}

const lastNameHandler = event =>{
    setLastName(event.target.value)
}


const changeInputType=()=>{
  let inputButton=document.getElementById("passwordInput")
  let input_type=inputButton.getAttribute("type")
  let viewerButton=document.getElementById("viewerButton")
  if(input_type=="password"){
      setInputType("text")
      viewerButton.innerText='Hide'
  }else{
      setInputType("password")
      viewerButton.innerText='Show'
  }
 }


const validateEmail = (email) => {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    return regex.test(email);
  };
  
  const checkChannelId = async (channelID) => {
    try {
      const payload = { channelID };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/checkChannelId`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.text();
    } catch (error) {
      console.error("Error:", error);
      return "Error";
    }
  };
  
  const createUserAccount = async (accountData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/createUserAccount`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(accountData)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error("Error:", error);
      return { status: "error" };
    }
  };
  
  const FormSubmitHandler = async (event) => {
    event.preventDefault();
  
    if (!validateEmail(emailID)) {
      toast.error("Invalid Email ID", { position: toast.POSITION.TOP_CENTER });
      console.log("Invalid Email ID");
      return;
    }
      const account_payload = {
        username,
        password:hashString(password),
        firstName,
        lastName,
        emailID
      };
      const userResponse = await createUserAccount(account_payload);
      console.log("User Response:", userResponse);
  
      if (userResponse.status === "success") {
        toast.success('User created successfully', { position: toast.POSITION.TOP_CENTER });
        Cookies.set('session_id',userResponse.session_id)
        Cookies.set('expiry',userResponse.expiry)
        const expiry_date=new Date(userResponse.expiry)
        setCustomExpiryCookie('session_id', userResponse.session_id, expiry_date);
        Cookies.set('user_id',userResponse.user_id)
        window.postMessage({ action: "messageFromWebPage", data: userResponse.session_id}, "*");                        
        navigate('/dashboard')
      } else if(userResponse.status==="error") {
        // toast.error('Something went wrong !!', { position: toast.POSITION.TOP_CENTER });
      }else if(userResponse.status==="DUPLICATE_EMAIL"){
        toast.error(userResponse.message,{position:toast.POSITION.TOP_CENTER})
      }
  };
  


    return(
        <div>
               <form onSubmit={FormSubmitHandler}>
                    <div className="py-2 px-3 d-flex justify-content-between w-100">
                        <input className="border-0 bg-grey py-2 br-20px px-4 w-45" type="text" placeholder="First Name" onChange={firstNameHandler} required/>
                        <input className="border-0 bg-grey py-2 br-20px px-4 w-45" type="text" placeholder="Last Name" onChange={lastNameHandler}/>
                    </div>
                    <div className="py-3 px-3"><input className="border-0 bg-grey py-2 br-20px px-4 w-100" type="text" placeholder="Username" onChange={usernameHandler} required/></div>
                    <div className="py-3 px-3"><input className="border-0 bg-grey py-2 br-20px px-4 w-100" type="text" placeholder="Email" onChange={emailHandler} required/></div>
                    {/* <div className="py-3 px-3"><input className="border-0 bg-grey py-2 br-20px px-4 w-100" type="text" placeholder="Channel ID" onChange={channelIDHandler} required/></div> */}
                    <div class="py-2 px-3 d-flex bg-grey br-20px my-2 justify-content-evenly mx-3">
                      <div>
                        <input class="border-0 bg-grey br-20px w-75 bg-transparent px-1" type={inputType} placeholder="Password" id="passwordInput" onChange={passwordHandler} required/>
                        <button class="bg-transparent border-0 pl-0 pr-0 opacity-50" id="viewerButton" onClick={changeInputType} type="button">Show</button>
                      </div>
                    </div>
                    {/* <div className="py-3 px-3"><input className="border-0 bg-grey py-2 br-20px px-4 w-100" type="password" placeholder="Password" minlength="10" onChange={passwordHandler} required/></div> */}
                    <div className="py-3 px-3 text-center"><button type="submit" className="s-in-btn-b w-75">Proceed</button></div>
                    <div className="py-4 px-3 text-center">
                        <button className="s-in-g-button w-100 px-4 py-2" onClick={() => login()}>Sign up with Google<img src={google} className="g-img" alt="google"></img></button>
                    </div>
                </form>
        </div>
    )
}


export default Profile