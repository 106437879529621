import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Signup.css'
import line from '../../assets/line_.png'
import SignupProfile from './Profile'
import SignupPayment from './Plan'
import Profile from './Profile'
import {useState,useEffect} from 'react'
import axios from 'axios'


function Signup(){
    return(
        <div class="d-flex align-items-center body-style">
            <div class="bg-white rounded d-flex col-lg-3 col-md-6 col-sm-8 col-11 h-600 mx-auto custom-shadow">
               <div class="py-3 mx-auto px-4">
                <div class="d-flex justify-content-between align-items-start px-3 py-3">
                    <div class="d-flex align-items-center flex-column">
                        <input class="custom-radio" type="radio" checked/>
                       <p class="py-2">Profile</p>
                    </div>
                   <div class="w-50">
                        <img src={line} class="line-img" alt='line icon'/>
                   </div>
                    <div class="d-flex align-items-center flex-column">
                        <input id="custom-radio-button" class="custom-radio" type="radio" checked/>
                        <p class="py-2">Payment</p>
                    </div>
                </div>
                  <Profile/>
               </div>
            </div>
        </div>
    )
}

export default Signup