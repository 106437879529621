import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import ContentCreator from "../../assets/contentCreator.jpg";
import YouTuber from "../../assets/youtuber.jpg";
import { Diversity1 } from "@mui/icons-material";
import "./InternAccountModeForm.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import ContactUsSuccess from "./ContactUsSuccess";
import Cookies from "js-cookie";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import businessIllustration from "../../assets/business_illustration.jpg";

const InternAccountModeForm = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [hasChannel, setHasChannel] = useState(0);
  const session_id = Cookies.get("session_id");
  const [showComingSoon, setShowComingSoon] = useState(false);

  useEffect(() => {
    const isLoggedIn = Cookies.get("isLoggedIn");
    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const submitInternInfoForm = (event) => {
    const ele = event.target;
    let hasChannel_ = 0;
    const type = ele.closest(".form-container").getAttribute("data-value");
    if (type === "new") {
      setHasChannel(2);
      hasChannel_ = 2;
    } else if (type === "old") {
      setHasChannel(1);
      hasChannel_ = 1;
    }

    const payload = {
      session_id,
      hasChannel: hasChannel_,
    };

    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/postInternInfoForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === "success") {
            if (hasChannel_ === 1) {
              navigate("/channelId");
            } else {
              navigate("/internship/questionnaire");
            }
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(255, 245, 235)",
        }}
      >
        <Box
          className="px-4 px-sm-5 py-4 py-sm-5"
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "fit-content",
            borderBottomLeftRadius: "50%",
            position: "absolute",
            right: "0",
          }}
        ></Box>
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="d-flex flex-column justify-content-between h-100">
            <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5">
              <Box className="contact-nav">
                <img
                  src={logo}
                  alt="logo"
                  height="30px"
                  width="auto"
                  onClick={() => navigate("/")}
                />
                <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                  <NavLink to="/">Home</NavLink>
                  {/* <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/faq">FAQ</NavLink> */}
                  <NavLink to="/features">Features</NavLink>
                  <NavLink to="">Blog</NavLink>
                  <NavLink to="">FAQ</NavLink>
                </Box>
              </Box>
            </Box>
            <Box className="py-5 px-3 px-md-5 py-3 mx-auto w-100">
              <Box>
                <Typography className="text-center pb-4" variant="h5">
                  CreatorStride Wants To Know?
                </Typography>
                <Box className="d-flex flex-column d-xl-none justify-content-center">
                  <Box className="text-center">
                    <Typography className="py-2">INDIVIDUAL</Typography>
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container mx-auto w-100"
                      data-value="new"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                      }}
                      onClick={submitInternInfoForm}
                    >
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{ flexGrow: "1" }}
                      >
                        <img
                          src={ContentCreator}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Dreaming of starting a YouTube channel? We've got you
                          covered! Join us to kickstart your content creation
                          adventure. Explore tools and resources designed for
                          beginners and turn your ideas into captivating
                          content.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am a New Youtuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="text-center my-5">
                    <Typography className="py-2">PROFESSIONAL</Typography>
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container mx-auto w-100"
                      data-value="old"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                      }}
                      onClick={submitInternInfoForm}
                    >
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{ flexGrow: "1" }}
                      >
                        <img
                          src={YouTuber}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Already rocking a YouTube channel? Share your details
                          with us, and let's amplify your content creation
                          journey together! Unlock exclusive features tailored
                          for channel owners.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am Already an Youtuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    className="text-center my-5 position-relative"
                    onMouseOverCapture={() => setShowComingSoon(true)}
                    onMouseLeave={() => setShowComingSoon(false)}
                  >
                    <Typography className="py-2">BUSINESS</Typography>
                    {showComingSoon && (
                      <Box
                        className="px-5 bg-light py-5 rounded shadow"
                        style={{
                          position: "absolute",
                          zIndex: "9999",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)", // Center both horizontally and vertically
                        }}
                      >
                        <Typography>
                          <b>COMING SOON.</b>
                        </Typography>
                        <Typography>
                          Exciting things are in the works. Stay tuned for our
                          upcoming launch!
                        </Typography>
                      </Box>
                    )}
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container mx-auto w-100"
                      data-value="new"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                        opacity: showComingSoon ? '0.5' : '1'
                      }}
                    >
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{ flexGrow: "1" }}
                      >
                        <img
                          src={businessIllustration}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Dreaming of starting a YouTube channel? We've got you
                          covered! Join us to kickstart your content creation
                          adventure. Explore tools and resources designed for
                          beginners and turn your ideas into captivating
                          content.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am a New Youtuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="d-xl-flex flex-column flex-xl-row gap-5 d-none justify-content-center">
                  <Box className="text-center">
                    <Typography className="py-2">INDIVIDUAL</Typography>
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container w-100"
                      data-value="new"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                      }}
                      onClick={submitInternInfoForm}
                    >
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{ flexGrow: "1" }}
                      >
                        <img
                          src={ContentCreator}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Dreaming of starting a YouTube channel? We've got you
                          covered! Join us to kickstart your content creation
                          adventure. Explore tools and resources designed for
                          beginners and turn your ideas into captivating
                          content.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am a New Youtuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="text-center">
                    <Typography className="py-2">PROFESSIONAL</Typography>
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container w-100"
                      data-value="old"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                      }}
                      onClick={submitInternInfoForm}
                    >
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{ flexGrow: "1" }}
                      >
                        <img
                          src={YouTuber}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Already rocking a YouTube channel? Share your details
                          with us, and let's amplify your content creation
                          journey together! Unlock exclusive features tailored
                          for channel owners.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am Already an Youtuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="text-center">
                    <Typography className="py-2">BUSINESS</Typography>
                    <Box
                      className="d-flex flex-column shadow cursor-pointer form-container w-100 position-relative"
                      data-value="new"
                      style={{
                        minHeight: "600px",
                        maxWidth: "500px",
                        border: "3px solid var(--collection-1-primary-main)",
                      }}
                      // onClick={submitInternInfoForm}
                      onMouseOverCapture={() => setShowComingSoon(true)}
                      onMouseLeave={() => setShowComingSoon(false)}
                    >
                      {showComingSoon && (
                        <Box
                          className="px-5 bg-light py-5 rounded shadow"
                          style={{
                            position: "absolute",
                            zIndex: "9999",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)", // Center both horizontally and vertically
                          }}
                        >
                          <Typography>
                            <b>COMING SOON.</b>
                          </Typography>
                          <Typography>
                            Exciting things are in the works. Stay tuned for our
                            upcoming launch!
                          </Typography>
                        </Box>
                      )}
                      <Box
                        className="d-flex flex-column h-100 justify-content-between"
                        style={{
                          flexGrow: "1",
                          opacity: showComingSoon ? "0.5" : "1",
                        }}
                      >
                        <img
                          src={businessIllustration}
                          alt="content-creator"
                          style={{ height: "50%" }}
                        />
                        <Typography className="text-center px-5">
                          Explore effective strategies and tools on our platform
                          to leverage YouTube for brand growth. Join us to
                          enhance your online presence and engage your audience
                          through compelling video content. Whether you're a
                          startup or an established enterprise, let's harness
                          the power of YouTube to drive your business success.
                        </Typography>
                        <Box
                          style={{
                            backgroundColor: "var(--collection-1-primary-main)",
                          }}
                        >
                          <Typography
                            className="text-center py-2 text-light"
                            variant="h6"
                          >
                            I am a Business Based YouTuber !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-between px-5"
              // style={{ height: "5%" }}
            >
              <Box
                className="d-flex justify-content-between"
                style={{ height: "auto" }}
              ></Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
              style={{
                minHeight: "50px",
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InternAccountModeForm;
