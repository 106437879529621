import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      // Log the error to an error tracking service if desired
      console.error(error, errorInfo);
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    // Redirect to the error page
    return <Navigate to="/" />;
  }

  return children;
};

export default ErrorBoundary;
