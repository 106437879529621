import React from "react";
import { Selection } from "./Selection";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import step1 from '../../../assets/step1.png'
import step2 from '../../../assets/step2.png'
import step3 from '../../../assets/step3.png'
import step4 from '../../../assets/step4.png'
import left from '../../../assets/Left.svg'
import right from '../../../assets/Right.svg'
import layer from '../../../assets/layer-21.svg'


const Steps = () => {
    return (
        <div className="instruction px-md-5 px-3 max-1440">
            <div className="px-1">
                <div className="px-md-5 px-1">
                    <div className="px-md-5 px-1">
                        <div className="content">
                            <div className="title-2 text-start w-100 d-flex justify-content-center flex-lg-row flex-column gap-md-3">
                                <div className="title-2" >
                                    <div className="text-wrapper-2">Instructions</div>
                                    <p className="which-segment-is">
                                        <span className="span">Which segment is recommended for using </span>
                                        <span className="text-wrapper-3">CreatorStride</span>
                                        <span className="span"> ?</span>
                                    </p>
                                </div>
                                <p className="p">
                                    Creator Stride is designed to be as friendly as possible so that it can be used by both beginner content
                                    creators and advanced content creators
                                </p>
                            </div>
                            <div className="content-2 d-flex justify-content-between flex-lg-row flex-column gap-3 pb-md-5 pb-1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Steps