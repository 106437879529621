import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Diversity1 } from "@mui/icons-material";
import "./UnsubscribeEmail.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import LoadingOverlay from "../dashboard/LoadingOverlay";

const UnsubscribeEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [AlreadyUnsubscribed, setAlreadyUnsubscribed] = useState(false);
  const session_id = Cookies.get("session_id");
  const code = searchParams.get("code");
  const email = searchParams.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    if (!code || !email) {
      navigate("/");
    }
  }, []);

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/unSubscibeEmailUpdates`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code, email }),
        });

        const responseData = await response.json();
        console.log("response data === ", responseData);

        if (responseData.status === "success" && responseData.message === "unsubscribed") {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else if (responseData.status === "success" && responseData.message === "Already unsubscribed") {
          setTimeout(() => {
            setLoading(false);
            setAlreadyUnsubscribed(true);
          }, 2000);
        } else if (responseData.status === "success" && responseData.message === "User not found") {
          navigate("/");
        } else {
          navigate("/");
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      {!loading ? (
        <Box
          style={{
            backgroundColor: "rgb(255, 245, 235)",
          }}
        >
          <Box
            className="px-4 px-sm-5 py-4 py-sm-5"
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
              width: "fit-content",
              borderBottomLeftRadius: "50%",
              position: "absolute",
              right: "0",
            }}
          ></Box>
          <Box
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <Box className="d-flex flex-column justify-content-between h-100">
              <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5"></Box>
              <Box>
                <Box
                  className="col col-11 col-sm-9 col-md-7 col-lg-5 col-xl-5 mx-auto rounded px-4 px-md-5 py-3 py-md-5 my-4 rounded"
                  style={{
                    minHeight: "500px",
                    borderTop: "15px solid var(--collection-1-primary-main)",
                    border: "1px solid var(--collection-1-primary-main)",
                  }}
                >
                  <Box className="text-center mb-2">
                    <img
                      className="text-center"
                      src={logo}
                      alt="logo"
                      height="30px"
                      width="auto"
                      onClick={() => navigate("/")}
                    />
                  </Box>
                  {AlreadyUnsubscribed ? (
                    <Typography variant="h4" className="py-3 text-center">
                      Email Already Unsubscribed!
                    </Typography>
                  ) : (
                    <Typography variant="h4" className="py-3 text-center">
                      Email Unsubscribed Successfully!
                    </Typography>
                  )}
                  <Typography className="py-2">Hi Creator,</Typography>
                  <Typography className="py-2">
                    We're sorry to see you go, but we've successfully
                    unsubscribed your email address from our mailing list. If
                    you have any feedback or if there's anything we can do to
                    improve your experience, please let us know. Your input is
                    valuable to us. Thank you for being a part of our community.
                  </Typography>
                  <Typography>Regards,</Typography>
                  <Typography>Creatorstride Team.</Typography>
                  <Typography className="text-center pt-3">
                    © 2023 creatorstride.com
                  </Typography>
                </Box>
              </Box>
              <Box
                className="d-flex justify-content-between px-5"
                // style={{ height: "5%" }}
              >
                <Box
                  className="d-flex justify-content-between"
                  style={{ height: "auto" }}
                ></Box>
              </Box>
              <Box
                className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
                style={{
                  minHeight: "50px",
                  backgroundColor: "var(--collection-1-primary-main)",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2023 creatorstride.com. All Rights Reserved.
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

export default UnsubscribeEmail;
