import React from "react";
import { Switch } from "./Switch";
import "./style.css";
import check from '../../../assets/check.svg'
import { useState, useEffect } from 'react'


const Pricing = () => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };
    return (
        <div id="pricing" className="pricing pt-0 z-1000 max-1440 bg-light-st">
            <div className="px-1 mx-auto bg-light-st">
                <div className="px-md-5" style={{ backgroundColor: '#f3f7ff;' }}>
                    <div className="px-md-5" style={{ backgroundColor: '#f3f7ff;' }}>
                        <div className="content px-1 px-sm-3 px-md-5 bg-light-st">
                            <div className="title px-3 px-md-5">
                                <div className="text-wrapper">Pricing Plans</div>
                                <p className="we-provide-some-of">
                                    We provide some of the best offers tailored to your needs, <br />
                                    whether you are a new content creator or a pro content creator
                                </p>
                            </div>
                            <div className="payment-type d-flex flex-lg-row flex-column gap-3">
                                <div className="div">Monthly Payment</div>
                                <Switch stateProp="off" className="shadow-lg" style={{height:'24px'}} onClick={handleFlip} />
                                <div className="div">Annual Payment</div>
                            </div>
                            <div className={`flip-container ${isFlipped ? 'flipped' : ''}`}>
                                <div className="flipper">
                                    <div className={`front ${isFlipped ? 'd-none' : ''}`}>
                                        <div style={{ textAlign: 'left' }} className="plans d-flex flex-lg-row flex-column gap-5 mx-auto align-items-stretch">
                                            <div className="div-2 shadow">
                                                <div className="div-3">
                                                    <div className="div-4">
                                                        <div className="text-wrapper-2 text-center">Personal Plan</div>
                                                        <p className="p text-center">
                                                            This plan is intended for those of you who <br />
                                                            just want to start your career as a content creator.
                                                        </p>
                                                    </div>
                                                    <div className="div-4">
                                                        <div className="text-wrapper-3 text-center">$19.99/Month</div>
                                                        <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                                    </div>
                                                </div>
                                                <div className="div-3">
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Find your area of ​​expertise and launch your YouTube journey.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">20 custom AI Generated Video Scripts per Month.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Competitor Tracking: Beach 3 Competitors with Advanced Analytics.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Analyze Audience Trends and Preferences.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <div className="text-wrapper-5">Comparative Performance Analysis.</div>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">200 Auto Comment Replies for Increased Engagement.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Customized Growth Plans to Plan Your Content.</p>
                                                    </div>
                                                    <br/>
                                                </div>
                                                <div className="register cursor-pointer p-0">
                                                    <div className="choose-this-plan w-100 py-3">Choose This Plan</div>
                                                </div>
                                            </div>
                                            <div className="div-2 shadow" style={{ textAlign: 'left' }}>
                                                <div className="div-3">
                                                    <div className="div-4">
                                                        <div className="text-wrapper-2 text-center">Professional / Business Plan</div>
                                                        <p className="p text-center">
                                                            The Pro Plan is specifically designed for those of you who want to dedicate their lives to content
                                                            creators.
                                                        </p>
                                                    </div>
                                                    <div className="div-4">
                                                        <div className="text-wrapper-3 text-center">$49.99/Month</div>
                                                        <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                                    </div>
                                                </div>
                                                <div className="div-3">
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Take existing channels to new heights</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">50 custom AI Generated Video Scripts per Month.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Unique Learning Sounds Learned from Previous Videos</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Advanced Competitor Tracking: Monitor 5 Competitors</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Deep Trend and Audience Analytics</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <div className="text-wrapper-5">Comprehensive Comparative Analysis</div>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">
                                                            In-depth Comment Analysis for You and Competitors - Extract Valuable Insights
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">1000&#39;s of Auto Comment Replies for Maximum Engagement</p>
                                                    </div>
                                                </div>
                                                <div className="register cursor-pointer p-0">
                                                    <div className="choose-this-plan w-100 py-3">Choose This Plan</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div style={{ textAlign: 'left' }} className="plans d-flex flex-lg-row flex-column gap-5 mx-auto">
                                            <div className="div-2 shadow">
                                                <div className="div-3">
                                                    <div className="div-4">
                                                        <div className="text-wrapper-2 text-center">Personal Plan</div>
                                                        <p className="p text-center">
                                                            This plan is intended for those of you who <br />
                                                            just want to start your career as a content creator.
                                                        </p>
                                                    </div>
                                                    <div className="div-4">
                                                        <div className="text-wrapper-3 text-center">$9.99/Month</div>
                                                        <div className="text-wrapper-2 text-center">Billed Annually at $209.99</div>
                                                        <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                                    </div>
                                                </div>
                                                <div className="div-3">
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Find your area of ​​expertise and launch your YouTube journey.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">20 custom AI Generated Video Scripts per Month.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Competitor Tracking: Beach 3 Competitors with Advanced Analytics.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Analyze Audience Trends and Preferences.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <div className="text-wrapper-5">Comparative Performance Analysis.</div>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">200 Auto Comment Replies for Increased Engagement.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Customized Growth Plans to Plan Your Content.</p>
                                                    </div>
                                                    <br/>
                                                </div>
                                                <div className="register cursor-pointer p-0">
                                                    <div className="choose-this-plan w-100 py-3">Choose This Plan</div>
                                                </div>
                                            </div>
                                            <div className="div-2 shadow" style={{ textAlign: 'left' }}>
                                                <div className="div-3">
                                                    <div className="div-4">
                                                        <div className="text-wrapper-2 text-center">Professional / Business Plan</div>
                                                        <p className="p text-center">
                                                            The Pro Plan is specifically designed for those of you who want to dedicate their lives to content
                                                            creators.
                                                        </p>
                                                    </div>
                                                    <div className="div-4">
                                                        <div className="text-wrapper-3 text-center">$29.99/Month</div>
                                                        <div className="text-wrapper-2 text-center">Billed Annually at $559.99</div>
                                                        <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                                    </div>
                                                </div>
                                                <div className="div-3">
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Take existing channels to new heights</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">50 custom AI Generated Video Scripts per Month.</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Unique Learning Sounds Learned from Previous Videos</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Advanced Competitor Tracking: Monitor 5 Competitors</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">Deep Trend and Audience Analytics</p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <div className="text-wrapper-5">Comprehensive Comparative Analysis</div>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">
                                                            In-depth Comment Analysis for You and Competitors - Extract Valuable Insights
                                                        </p>
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <img className="check" alt="Check" src={check} />
                                                        <p className="text-wrapper-5">1000&#39;s of Auto Comment Replies for Maximum Engagement</p>
                                                    </div>
                                                </div>
                                                <div className="register cursor-pointer p-0">
                                                    <div className="choose-this-plan w-100 py-3">Choose This Plan</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div style={{ textAlign: 'left' }} className="plans d-flex flex-lg-row flex-column gap-5 mx-auto">
                                <div className="div-2 shadow">
                                    <div className="div-3">
                                        <div className="div-4">
                                            <div className="text-wrapper-2 text-center">Starter Plan</div>
                                            <p className="p text-center">
                                                This plan is intended for those of you who <br />
                                                just want to start your career as a content creator.
                                            </p>
                                        </div>
                                        <div className="div-4">
                                            <div className="text-wrapper-3 text-center">$19.99/Month</div>
                                            <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                        </div>
                                    </div>
                                    <div className="div-3">
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Find your area of ​​expertise and launch your YouTube journey.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">20 custom AI Generated Video Scripts per Month.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Competitor Tracking: Beach 3 Competitors with Advanced Analytics.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Analyze Audience Trends and Preferences.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <div className="text-wrapper-5">Comparative Performance Analysis.</div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">200 Auto Comment Replies for Increased Engagement.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Customized Growth Plans to Plan Your Content.</p>
                                        </div>
                                    </div>
                                    <div className="register cursor-pointer">
                                        <div className="choose-this-plan">Choose This Plan</div>
                                    </div>
                                </div>
                                <div className="div-2 shadow" style={{ textAlign: 'left' }}>
                                    <div className="div-3">
                                        <div className="div-4">
                                            <div className="text-wrapper-2 text-center">Pro Plan</div>
                                            <p className="p text-center">
                                                The Pro Plan is specifically designed for those of you who want to dedicate their lives to content
                                                creators.
                                            </p>
                                        </div>
                                        <div className="div-4">
                                            <div className="text-wrapper-3 text-center">$49.99/Month</div>
                                            <p className="text-wrapper-4">First 1000 customers get will get 2 months free. Cancel anytime !!</p>
                                        </div>
                                    </div>
                                    <div className="div-3">
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Ideal for creating with less than 500k subscribers.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Take existing channels to new heights</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Video Topic Recommendations Powered by AI.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">50 custom AI Generated Video Scripts per Month.</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Unique Learning Sounds Learned from Previous Videos</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Advanced Competitor Tracking: Monitor 5 Competitors</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">Deep Trend and Audience Analytics</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <div className="text-wrapper-5">Comprehensive Comparative Analysis</div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">
                                                In-depth Comment Analysis for You and Competitors - Extract Valuable Insights
                                            </p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img className="check" alt="Check" src={check} />
                                            <p className="text-wrapper-5">1000&#39;s of Auto Comment Replies for Maximum Engagement</p>
                                        </div>
                                    </div>
                                    <div className="choose-this-plan-wrapper cursor-pointer">
                                        <div className="choose-this-plan-2">Choose This Plan</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing
