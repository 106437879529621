import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid,Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
  contactSuccessOverlay: {
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  contactSuccessContainer: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
  },
  icon: {
    fontSize: 80,
    margin: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const ContactSuccess = ({showContactSuccess,setShowContactSuccess}) => {
  const classes = useStyles();

  return (
    <div className={classes.contactSuccessOverlay}>
      <div className={`col col-10 col-sm-6 col-lg-5 py-5 contact-success-container ${classes.contactSuccessContainer}`}>
        <CheckCircleIcon className={classes.icon} style={{ color: '#4CAF50' }} />
        <Typography variant="h5" className={classes.heading}>
          Message Posted Successfully!
        </Typography>
        {/* <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <AccessTimeIcon className={classes.icon} style={{ color: '#F44336' }} />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Our team will get back to you soon.
            </Typography>
          </Grid>
        </Grid> */}
        <Typography>"Thank you for reaching out! Our dedicated team is working diligently to address your query. Expect to hear from us soon with the help you're seeking."</Typography>
        <br></br>
        <Button style={{backgroundColor:'var(--collection-1-primary-main)',color:'white',width:'50%'}} onClick={()=>setShowContactSuccess(!showContactSuccess)}>Done</Button>
      </div>
    </div>
  );
};

export default ContactSuccess;
