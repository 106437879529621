import React from "react";
import "./DashboardHeader.css";
import profile from "../../assets/profile.jpg";
import chat_grey from "../../assets/chat_grey.png";
import calendar from "../../assets/calendar.png";
import accept from "../../assets/accept.png";
import cross from "../../assets/cross.png";
import pending from "../../assets/pending.png";
import Cookies from "js-cookie";
import { useEffect, useState, useContext, useCallback } from "react";
import { Popover } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { UserNameHandler } from "../sideMenu/SideMenu";
import { toast } from "react-toastify";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateReduxProfileImage } from "../../redux/actions/actions";
import user from "../../assets/user.png";

const DashboardHeader = ({ channelId }) => {
  const reduxProfileImage = useSelector((state) => state.reduxProfileImage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [headerData, setHeaderData] = useState({});
  const [countData, setCountData] = useState({});
  const [history, setHistory] = useState({});
  const [userRole, setUserRole] = useState("");
  const session_id = Cookies.get("session_id");
  const [dataFetched, setDataFetched] = useState(false);
  const payload = {
    session_id: session_id,
    channel_id: channelId,
  };

  const fetchData = useCallback(async () => {
    const payload = {
      session_id: Cookies.get("session_id"),
      channel_id: channelId,
    };

    try {
      if (!channelId) {
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getDashboardCardHeader`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      console.log("success in the dashboard header");
      console.log(
        "The response data from fetch dashboard header : ",
        responseData
      );

      if (responseData.status === "Unauthorized") {
        navigate("/");
      } else if (responseData.status === "success") {
        responseData.result && setHeaderData(responseData.result);
        responseData.count && setCountData(responseData.count);
        responseData.history && setHistory(responseData.history);

        const headerData = responseData.result;
        const channel_id = headerData.channel_id;
        Cookies.set("channel_id", channel_id);
        Cookies.set("channel_name", headerData.channel_name);

        // Set the flag to indicate that data has been fetched
        setDataFetched(true);
      } else if (responseData.status === "error") {
        //   toast.error('Something went wrong !!', { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast.error('Error fetching data', { position: toast.POSITION.TOP_CENTER });
    }
  }, [channelId]);

  // Use useEffect to trigger data fetching only once when channelId changes
  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [channelId, fetchData, dataFetched]);

  useEffect(() => {
    const payload = {
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("success in the dashboard header");
          console.log(
            "The response data from fetch dashboard header : ",
            responseData
          );
          if (responseData.status === "Unauthorized") {
            navigate("/");
          } else if (responseData.status === "success") {
            responseData.user_role && setUserRole(responseData.user_role);
          } else if (responseData.status === "error") {
            // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
          } else if (responseData.status === "VIEWER_ACCESS_DENIED") {
            responseData.user_role && setUserRole(responseData.user_role);
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    } catch (error) {
      console.log("error");
      console.log("Error");
    }
  }, [channelId]);

  const approvedHandler = (event) => {
    const status = event.target.getAttribute("data-status");
    navigate(`/statusPage?id=None&Status=${status}&ChannelId=${channelId}`);
  };

  const ignoredHandler = (event) => {
    const status = event.target.getAttribute("data-status");
    navigate(`/statusPage?id=None&Status=${status}&ChannelId=${channelId}`);
  };

  const commentedHandler = (event) => {
    const status = event.target.getAttribute("data-status");
    navigate(`/statusPage?id=None&Status=${status}&ChannelId=${channelId}`);
  };

  const buttonstyle = {
    backgroundColor: "var(--collection-1-primary-main)",
    color: "white",
  };

  return (
    <>
      <div className="row d-flex flex-column flex-md-row justify-content-between w-100 mx-0 mb-3">
        <div className="px-2 pb-2 br-17px bg-white w-lg-68 pt-3">
          <div className="col d-flex justify-content-around align-items-center flex-column flex-sm-row mt-2">
            <div>
              <div>
                <h3 className="d-flex">
                  <Typography variant="h6">Hi </Typography>
                  <Typography variant="h6">
                    <b>{headerData.channel_name},</b>
                  </Typography>

                </h3>
                <div className="d-flex justify-content-between">
                  <p className="d-flex">
                    <Typography>Today you have got {history.today} </Typography>
                    <span className="mx-2">
                      <img
                        src={chat_grey}
                        className="w-20px ml-5"
                        alt="chat icon"
                      />
                    </span>
                    <Typography>comments,</Typography>
                  </p>
                  <div className="mx-2 px-2 py-2 bg-grey rounded w-fc h4 d-none">
                    {userRole}
                  </div>
                </div>
                <button className="px-3 bg-grey rounded border-0 d-flex justify-content-center align-items-center py-2">
                  {" "}
                  <Typography>
                    {countData.action_needed} comments ⇋ {countData.video_count}{" "}
                    videos
                  </Typography>
                </button>
              </div>
            </div>
            <div>
              <div className="d-flex position-relative mt-sm-50">
                <div>
                  <img
                    src={reduxProfileImage ? reduxProfileImage : user}
                    className="prof-img position-absolute"
                    alt="user-logo"
                  />
                </div>
                <div className="br-17px px-5 py-4 bg-grey d-flex flex-column justify-content-between">
                  <Typography>Videos : {headerData.video_count || 0 }</Typography>{" "}
                  <p className="mb-0">
                    <Typography>
                      Subscribers : {headerData.subscribers_count || 0}{" "}
                    </Typography>
                  </p>
                  <p className="mb-0">
                    <Typography>
                      Comments : {headerData.comments_count ?? 0}{" "}
                    </Typography>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 bg-grey h-3px my-3"></div>
          <div className="row d-flex justify-content-evenly align-items-center flex-column flex-md-row px-2 px-md-0 mb-3 mb-md-0">
            <Button
              className="b-btn px-4 bttn"
              style={buttonstyle}
              data-status="Approved"
              onClick={approvedHandler}
            >
              Approved Replies
            </Button>
            <Button
              className="b-btn px-4 my-2 my-md-0 bttn"
              style={buttonstyle}
              data-status="Ignored"
              onClick={ignoredHandler}
            >
              Ignored Comments
            </Button>
            <Button
              className="b-btn px-4 mb-sm-4 mb-md-0 bttn"
              style={buttonstyle}
              data-status="Commented"
              onClick={commentedHandler}
            >
              Replied Comments
            </Button>
          </div>
        </div>
        <div className="px-4 py-4 py-md-2 br-17px bg-white w-lg-29 d-flex flex-column flex-md-row flex-lg-column justify-content-evenly my-3 my-lg-0">
          <div className="d-flex justify-content-center flex-column py-3 px-4">
            <div className="d-flex align-items-center">
              <div>
                <img src={accept} className="w-20px mx-1" alt="tick icon" />
              </div>
              <div className="d-flex align-items-center">
                <p className="m-0 d-flex">
                  <Typography>Approved : {countData?.approved ?? 0 }</Typography>{" "}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={cross} className="w-20px mx-1" alt="cross icon" />
              </div>
              <div>
                <p className="m-1">
                  <Typography>Ignored : {countData?.ignored ?? 0 } </Typography>
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={pending} className="w-20px mx-1" alt="loading icon" />
              </div>
              <div>
                <p className="m-1">
                  <Typography>
                    Action needed : {countData?.action_needed ?? 0}
                  </Typography>
                </p>
              </div>
            </div>
          </div>
          <div className="bg-grey br-17px px-lg-2 px-sm-4 py-4 d-flex flex-column align-items-center">
            <button className="br-17px border-0 px-4 mb-3 bg-white w-75">
              <img src={calendar} className="w-20px mx-3" alt="calendar icon" />
              <Typography>Today {history?.today} comments</Typography>
            </button>
            <button className="br-17px border-0 px-2 bg-white w-75">
              <img src={calendar} className="w-20px mx-3" alt="calendar icon" />
              <Typography> Yesterday {history?.yesterday} comments</Typography>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
