import React, { useState, useEffect } from "react";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import background from '../../../assets/background@2x.png'
import JoinNow from "../joinNow/JoinNow";


const Content = () => {
  const [showJoinNowModel, setShowJoinNowModel] = useState(false)
  const [text, setText] = useState('');
  const originalText = 'Your Personalized Path to Youtube Success';
  const [imageLoaded, setImageLoaded] = useState(false);

  // useEffect(() => {
  //   let index = 0;
  //   const letters=originalText.split("")
  //   const typingInterval = setInterval(() => {
  //      console.log("Index is : ",index)
  //     setText((prevText) => {
  //       return (prevText + (originalText[index] !== undefined ? originalText[index] : ''));
  //     },[index]);
  //     index+=1
  //     setTimeout(()=>{
  //       index += 1;
  //     },100)
    

  //     if (index === originalText.length) {
  //       setText('')
  //       setTimeout(()=>{
  //          index=0
  //       },100)
  //     }
  //   }, 100);
  //   return () => clearInterval(typingInterval);
  // }, []); 


  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const hoverButtonInterval = setInterval(() => {
      const buttons = document.querySelectorAll(".custom-hover");

      const handleMouseEnter = (event) => {
        event.target.classList.add("hovered");
      };

      const handleMouseLeave = (event) => {
        event.target.classList.remove("hovered");
      };

      for (const button of buttons) {
        ; const hasCustomToggleClass = button.classList.contains("custom-toggle");

        // Toggle the class based on its presence
        if (hasCustomToggleClass) {
          button.classList.remove("custom-toggle");
        } else {
          button.classList.add("custom-toggle");
        }
      }

      return () => {
        clearInterval(hoverButtonInterval)
      }
  }, 2000);

  return () => clearInterval(hoverButtonInterval);
}, []);


return (
  <div className="header max-1440" id="home-header">
    <header className="class" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff' }}>
      <div className="title">
        <p className="elevate-your-youtube">
          <span className="text-wrapper">
            Elevate Your YouTube Journey, <br />
            No Matter Where{" "}
          </span>
          <span className="span">You Start</span>
        </p>
        <p className="p typewriting-p">Your Personalized Path to Youtube Success</p>
      </div>
      <button className="button custom-hover shadow cursor-pointer" onClick={() => setShowJoinNowModel(true)}>
        <div className="text-wrapper-2 cursor-pointer">Join Now</div>
      </button>
    </header>
    {showJoinNowModel && <JoinNow setShowJoinNowModal={setShowJoinNowModel} />}
  </div>
);
};


export default Content