import React from "react";
import "./style.css";
import down from '../../../assets/down.svg'
import line from '../../../assets/line_.png'
import capa from '../../../assets/capa-1.svg'
import illustration from '../../../assets/Ilustration.webp'

const Instruction = () => {
    return (
        <div className="instruction px-md-5 py-3 px-4 max-1440">
            <div className="px-2 px-md-5 w-100">
                <div className="px-1">
                    <div className="px-md-5 px-1 py-3 d-flex flex-lg-row flex-column gap-md-5">
                        <div className="content">
                            <div className="title" style={{ textAlign: 'left' }}>
                                <div className="div">
                                    <div className="text-wrapper">Features</div>
                                    <p className="features-that-make">
                                        <span className="span">
                                            Features that make you <br />
                                        </span>
                                        <span className="text-wrapper-2" style={{ color: 'var(--collection-1-primary-main)', fontSize: '32px', fontWeight: '700' }}>increase income</span>
                                        <span className="span"> and </span>
                                        <span className="text-wrapper-2" style={{ color: 'var(--collection-1-primary-main)', fontSize: '32px', fontWeight: '700' }}>content quality</span>
                                    </p>
                                </div>
                                <p className="p">
                                    Dive into a world of creative possibilities with features designed for both the aspiring YouTuber and the
                                    established influencer. Our intelligent questionnaire unlocks personalized content ideas, setting new
                                    creators on the path to discovery. Meanwhile, in-depth analytics and competitive insights ensure established
                                    influencers stay ahead of the curve. Engage your audience like never before, with automated comment
                                    responses and trend analysis that fuel your growth at every stage.
                                </p>
                            </div>
                            <div className="login cursor-pointer shadow">
                                <div className="text-wrapper-5 text-dark">Learn More</div>
                            </div>
                        </div>
                        {/* <div className="ilustration mobile-illustration">
                            <div className="overlap mobile-overlap">
                                <div className="rectangle mobile-rectangle" />
                                <div className="group mobile-group">
                                    <div className="overlap-group">
                                        <div className="rectangle-2 mobile-rectangle-2" />
                                        <div className="traffic-content">Traffic Content</div>
                                        <div className="element">100k</div>
                                        <div className="element-k">70k</div>
                                        <div className="element-2">50k</div>
                                        <div className="element-3">10k</div>
                                        <div className="monthly">
                                            <div className="text-wrapper">Monthly</div>
                                            <img className="down" alt="Down" src={down} />
                                        </div>
                                        <img className="line" alt="Line" src={line} />
                                        <img className="img" alt="Line" src={line} />
                                        <img className="line-2" alt="Line" src={line} />
                                        <img className="line-3" alt="Line" src={line} />
                                        <img className="line-4" alt="Line" src={line} />
                                        <div className="rectangle-3" />
                                        <div className="rectangle-4" />
                                        <div className="rectangle-5" />
                                        <div className="rectangle-6" />
                                        <div className="rectangle-7" />
                                        <div className="rectangle-8" />
                                        <div className="rectangle-9" />
                                        <div className="rectangle-10" />
                                    </div>
                                </div>
                                <div className="frame mobile-frame">
                                    <div className="content-progress">Content Progress</div>
                                    <div className="frame-2">
                                        <div className="text-wrapper-8">1000 New Subscribers</div>
                                        <div className="frame-3">
                                            <img className="capa" alt="Capa" src={capa} />
                                            <div className="text-wrapper-6">+50%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <img alt="analytics" className="illustation-img" width="437" height="288" src={illustration}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instruction
