import React, { useState, useRef, useEffect } from "react";
import { CodeOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CREATORSTRIDE_LOGO from "../../assets/quiz_header.png";
import { Box, Typography, useScrollTrigger } from "@mui/material";
import { BookOutlined, HistoryOutlined } from "@mui/icons-material";
import RegenerateButton from "./RegenerateButton";
import UserLoginAvatar from "../User/UserLoginAvatar";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import {
  updateContentGenerationHistory,
  updateUploadsHistory,
} from "../../redux/actions/actions";
import UserCard from "./UserCard";
import BotCard from "./BotCard";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const NewPlayground = () => {
  const dispatch = useDispatch();
  const contentGenerationHistory = useSelector(
    (state) => state.contentGenerationHistory
  );
  const uploadsHistory = useSelector((state) => state.uploadsHistory);
  const session_id = Cookies.get("session_id");
  const [collapsed, setCollapsed] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [nicheData, setNicheData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [historyTopics, setHistoryTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [script, setScript] = useState(null);
  const [scriptGenerated, setScriptGenerated] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [contentHistory, setContentHistory] = useState([]);
  const [searchParams,setSearchParams]=useSearchParams()
  const navigate = useNavigate();
  const layoutContainerRef = useRef(null);
  
  

  const handleScrollToTop = () => {
    if (layoutContainerRef.current) {
      layoutContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const payload = {
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserDomains`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          setIsLoading(false);
          if (responseData.status === "success") {
            let domains_ = responseData.domains;
            if (!domains_) {
              navigate("/internship/questionnaire");
            } else {
              console.log("domains_ ==== ", domains_);
              console.log("domains  ==== ", typeof domains_);
              setNicheData(domains_);
            }
            const oldTopics = responseData?.topics;
            if (oldTopics) {
              setHistoryTopics(oldTopics.slice(0, 6));
            }
            const contentHistory_ = responseData?.contentHistory;
            console.log(
              "The content history_ is ------------------>",
              contentHistory_
            );
            let deduplicatedTopics = contentHistory_?.map((history, index) => {
              return history?.topic;
            });
            console.log(
              "deduplicated topics is : ",
              Array.from(new Set(deduplicatedTopics))
            );
            deduplicatedTopics = Array.from(new Set(deduplicatedTopics));
            const newContentHistory = [];
            console.log(
              "Deduplicated topics length is : ",
              deduplicatedTopics.length
            );
            for (const topic of deduplicatedTopics) {
              var topic_id=null
              console.log("Topic is : ", topic);
              const response = [];
              contentHistory_.forEach((history, index) => {
                if (history?.topic === topic) {
                  topic_id=history?.topic_id
                  const data = {
                    userPrompt: history?.userPrompt,
                    response: history?.response,
                  };
                  response.push(data);
                }
              });
              const data = {
                topic,
                response,
                topic_id,
              };
              newContentHistory.push(data);
              console.log(
                "After appending newcontent history is : ",
                newContentHistory
              );
            }



            console.log(
              "New content history is ------------------>",
              newContentHistory
            );
            if (newContentHistory) {
              setContentHistory(newContentHistory);
              dispatch(updateContentGenerationHistory(newContentHistory));
            }
            const uploadsHistory_ = responseData?.uploadsHistory;
            console.log("The uploads history_ is : ", uploadsHistory_);
            if (uploadsHistory_) {
              console.log("Before dispatch: ", uploadsHistory_);
              dispatch(updateUploadsHistory(uploadsHistory_));
              console.log("After dispatch: ", uploadsHistory_);
            }
          } else if (responseData.status === "Unauthorized") {
            navigate("/");
            Cookies.remove("session_id");
            Cookies.set("isLoggedIn", false);
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const items = [
    // getItem("Domains", "sub1", <GlobalOutlined />, [
    //   ...nicheData.map((domain, index) => getItem(`${domain}`, `${index + 1}`)),
    // ]),
    // getItem("Topics", "sub2", <BookOutlined />, [
    //   ...topics.map((topic, index) => getItem(`${topic}`, `${index + 1}`)),
    // ]),
    // getItem("History", "sub3", <HistoryOutlined />, [
    //   ...historyTopics.map((topic, index) =>
    //     getItem(`${topic}`, `${index + 1}`)
    //   ),
    // ]),
  ];

  useEffect(() => {
    console.log(
      "The content History from the use Effect is : ",
      contentHistory
    );
    dispatch(updateContentGenerationHistory(contentHistory));
  }, [contentHistory]);

  useEffect(() => {
    console.log(
      "The redux content Generation history from use Effect is : ",
      contentGenerationHistory
    );
  }, [contentGenerationHistory]);

  useEffect(() => {
    console.log("The uploads history from useEffect is : ", uploadsHistory);
  }, [uploadsHistory]);

  useEffect(() => {
    if (windowSize.innerWidth < 600) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const logoStyle = {
    display: collapsed ? "none" : "flex",
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const generateTopics = async (item) => {
    setIsLoading(true);
    const payload = {
      selectedDomains: [item],
      session_id,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getTopicsOnDomain`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      setIsLoading(false);
      if (responseData?.status === "success") {
        console.log("The generated topics response is:", responseData);

        let data = "";

        console.log(typeof responseData?.data);

        if (typeof responseData?.data !== "object") {
          console.log("The JSON string is:", responseData?.data);
          try {
            console.log("It is object");
            data = JSON.parse(responseData?.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.log("It is not object");
          data = responseData?.data;
        }
        setTopics(data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleItemClick = (item) => {
    setSelectedDomains([item]);
    generateTopics(item);
  };

  const handleTopicClick = (item) => {
    setSelectedTopics([item]);
    generateScript(item);
  };

  const generateScript = async (item) => {
    setScript("");
    setScriptGenerated(false);
    const payload = {
      topics: [item],
      session_id,
    };
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/generateScriptOnTopic`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response?.json();
    setIsLoading(false);
    setScriptGenerated(true);
    if (responseData?.status === "success") {
      if (responseData?.data) {
        console.log("The script is : ", responseData?.data);
        setScript(responseData?.data);
        setScriptGenerated(true);
        const newHistoryTopics = new Set([...historyTopics, item]);
        setHistoryTopics(Array.from(newHistoryTopics));
        const isTopicIncluded = contentGenerationHistory.some(
          (history) => history.topic === selectedTopics[0]
        );
        console.log("Is topic included : ", isTopicIncluded);
        if (isTopicIncluded) {
          const contentGenerationHistory_ = contentGenerationHistory?.map(
            (history, index) => {
              if (history.topic === item) {
                history.response = responseData?.data;
              }
            }
          );
          console.log(
            "New content generation history by updating : ",
            contentGenerationHistory_
          );
          dispatch(updateContentGenerationHistory(contentGenerationHistory_));
        } else {
          const data = {
            topic: item,
            response: responseData?.data,
          };
          const newContentGenerationData = [...contentGenerationHistory, data];
          console.log(
            "New content generation history by adding : ",
            newContentGenerationData
          );
          dispatch(updateContentGenerationHistory(newContentGenerationData));
        }
      }
    } else if (responseData.status === "Unauthorized") {
      navigate("/");
      Cookies.remove("session_id");
      Cookies.set("isLoggedIn", false);
    }
  };

  useEffect(() => {
    const layoutContainer = layoutContainerRef.current;
    console.log("From layout container use effect : ", layoutContainer);
    // Scroll to the bottom of the layout container
    if (layoutContainer) {
      console.log(
        "The scroll height from useEffect is : ",
        layoutContainer.scrollHeight
      );
      layoutContainer.scrollTop = layoutContainer.scrollHeight;
    }
  }, [selectedDomains, selectedTopics, script]);


  useEffect(() => {
    const topic = searchParams.get("topic");
    const elements = document.querySelectorAll(".user-card");
    console.log("Elements is : ", elements);
    console.log("Topic is : ", topic);
  
    for (const element of elements) {
      console.log("Element innercontent : ", element.textContent);
  
      // Case-insensitive check
      if (element.textContent.toLowerCase().includes(topic.toLowerCase())) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
        width="450px"
        style={{
          height: "100vh",
          overflow: "none",
        }}
      >
        <Box>
          <Box
            className="py-4 text-center d-flex justify-content-center position-relative"
            style={{ position: "relative" }}
          >
            <img
              src={CREATORSTRIDE_LOGO}
              style={logoStyle}
              width="150px"
              height="auto"
              className="cursor-pointer"
              onClick={() => navigate("/")}
            />
          </Box>
          <div className="demo-logo-vertical" />
          <Menu theme="dark" mode="inline" style={{ position: "relative" }}>
            <Menu.Item key="/internship/v1/playground" icon={<CodeOutlined />}>
              <Link
                to="/internship/v1/playground"
                style={{ textDecoration: "none" }}
              >
                Playground
              </Link>
            </Menu.Item>
          </Menu>
        </Box>

        <Box
          className="d-flex justify-content-center w-100 py-3"
          // style={{ position: "absolute", bottom: "25px" }}
        >
          <UserLoginAvatar collapsed={collapsed} />
        </Box>
      </Sider>
      {contentGenerationHistory.length > 0 ? (
        <Layout
          style={{ overflow: "auto" }}
          id="layout-container"
          ref={layoutContainerRef}
        >
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              {/* <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item> */}
            </Breadcrumb>
            <Box
              className="d-flex rounded px-3 py-3 text-light gap-1"
              style={{
                backgroundColor: "var(--collection-1-primary-main)",
                width: "fit-content",
              }}
            >
              <Typography className="text-light">
                <b>History</b>
              </Typography>
              <HistoryOutlined />
            </Box>

            {Array.isArray(contentGenerationHistory) &&
              contentGenerationHistory?.map((history, index) => (
                <>
                  <Box>
                    <UserCard data={history?.topic} id={history?.topic_id} />
                    {Array.isArray(history?.response) &&
                      history?.response?.map((data, index) => (
                        <BotCard
                          topic={history?.topic}
                          script={data?.response}
                          userPrompt={data?.userPrompt}
                          isScript={true}
                          layoutContainerRef={layoutContainerRef}
                          isHistory={true}
                        />
                      ))}
                  </Box>
                </>
              ))}
            {/* {selectedDomains.length > 0 ? (
            <Box className="my-3 d-flex justify-content-end">
              <Box
                className="px-3 py-4 w-75 rounded shadow"
                style={{ backgroundColor: "#f2e5e4" }}
              >
                {selectedDomains.length > 0 ? (
                  <Typography>
                    <b>Topic : </b>
                    {selectedDomains[0]}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : null} */}
            {topics.length > 0 ? (
              <Box className="my-3 d-flex justify-content-start">
                <Box
                  className="px-3 px-md-5 py-4  py-md-5 rounded shadow bg-light"
                  stye={{ backgroundColor: "white !important" }}
                >
                  {topics.length > 0 ? (
                    <>
                      <Typography>
                        {" "}
                        Here are the topics for your upcoming videos click it to
                        generate script on the topic.
                      </Typography>
                      {topics.map((topic, index) => (
                        <Box
                          key={index}
                          className="mx-2 px-3 py-3 my-2 cursor-pointer rounded"
                          style={{
                            display: "inline-block",
                            border:
                              "2px solid var(--collection-1-primary-main)",
                            backgroundColor: selectedTopics.includes(topic)
                              ? "var(--collection-1-primary-main)"
                              : "white",
                            color: selectedTopics.includes(topic)
                              ? "white"
                              : "black",
                          }}
                          onClick={() => handleTopicClick(topic)}
                        >
                          {topic}
                        </Box>
                      ))}
                    </>
                  ) : null}
                </Box>
              </Box>
            ) : null}
            <Button
              type="primary"
              shape="circle"
              icon={<ArrowUpOutlined />}
              size="large"
              style={{
                position: "fixed",
                bottom: 20,
                right: 20,
              }}
              onClick={handleScrollToTop}
            />
          </Content>
          <Footer
            className="d-none"
            style={{
              textAlign: "center",
              position: "fixed",
              bottom: "0",
            }}
          >
            ©{new Date().getFullYear()} creatorstride.com. All Rights Reserved.
          </Footer>
        </Layout>
      ) : null}
      {isLoading && <LoadingOverlay text="Loading ...." />}
    </Layout>
  );
};
export default NewPlayground;
