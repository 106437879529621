import React from "react";
import "./style.css";
import tools from '../../../assets/tools@4x 1.webp'
import line from '../../../assets/Line 6.png'

const EngagementContent = () => {
    return (
        <div className="personalized-content px-3 px-md-5 pt-2 pt-md-5" style={{ maxWidth: "1440px", margin: "auto" }}>
            <div className="px-2 px-md-5">
                <div className="d-flex justify-content-between flex-xl-row flex-column gap-4 px-3 px-md-5">
                    <div className="mx-auto">
                        <img className="tools mobile-personalized-img mx-0 mx-lg-5" alt="Tools" src={tools} height="auto" width="456"/>
                    </div>
                    <div className="mx-0 mx-lg-5">
                        <div className="title">
                            <div className="text-wrapper">The features we offer</div>
                            <div className="div">Engagement Tools</div>
                            <p className="p">Engagement tools that are ready to help you earn extraordinary income from content</p>
                        </div>
                        <div className="content-2">
                            <img className="line" alt="Line" src={line} />
                            <div className="feature">
                                <div className="circle">
                                    <div className="ellipse" />
                                </div>
                                <p className="text-wrapper-2">
                                    Automated response systems to <br />
                                    maintain active viewer interaction.
                                </p>
                            </div>
                            <div className="feature">
                                <div className="ellipse-wrapper">
                                    <div className="ellipse" />
                                </div>
                                <p className="text-wrapper-2">
                                    Deep audience insights for a more <br />
                                    targeted and effective engagement strategy.
                                </p>
                            </div>
                            <div className="feature">
                                <div className="div-wrapper">
                                    <div className="ellipse" />
                                </div>
                                <p className="text-wrapper-2">
                                    Tools to analyze and respond to viewer comments, <br />
                                    fostering a loyal community.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EngagementContent