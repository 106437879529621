// import { useState, useEffect } from 'react'
// import { useSearchParams, useNavigate } from 'react-router-dom'
// import './JoinNowVerify.css'
// import EmailIcon from '@material-ui/icons/Email';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import check from '../../../src/assets/check.svg'
// import cross from '../../../src/assets/cross.png'
// import LoadingOverlay from '../../../src/Components/dashboard/LoadingOverlay'
// import Logo from '../../../src/assets/Logo.png'

// const JoinNowVerify = ({scriptSrc}) => {
//     const [searchParams, setSearchParams] = useSearchParams()
//     const [isVerified, setIsVerified] = useState(null)
//     const [sentEmail, setSentEmail] = useState(false)
//     const [isLoading, setIsLoading] = useState(false)
//     const [dataFetched,setDataFetched] = useState(false)
//     const [alreadyVerified, setAlreadyVerified] = useState(false)
//     const code = searchParams.get("code")
//     const email = searchParams.get("email")
//     const navigate = useNavigate()

//     useEffect(() => {
//         if(!code || !email){
//            navigate('/')
//         }
//         setIsLoading(true)
//         setTimeout(() => {
//             const payload = {
//                 code,
//                 email
//             }
//             fetch(`${process.env.REACT_APP_SERVER_URL}/joinNowEmailVerification`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'text/plain',
//                 },
//                 body: JSON.stringify(payload)
//             }).then(response => response.json())
//                 .then(responseData => {
//                     console.log("Response data is : ", responseData)
//                     setIsLoading(false)
//                     setDataFetched(true)
//                     if (responseData?.status === "success") {
//                         setIsVerified(true)
//                     } else if (responseData?.status === "verified") {
//                         setAlreadyVerified(true)
//                         setIsVerified(true)
//                     }else if(responseData?.status==="error"){
//                         navigate('/')
//                     }
//                 })
//         }, 2000)
//     }, [])

//     const sendEmail = async () => {
//         setSentEmail(true)
//         const emailTimeout = setTimeout(() => {
//             setSentEmail(false)
//         }, 3000)
//         const payload = {
//             emailId: email,
//             checked: null
//         }
//         try {
//             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/addWaitlist`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'text/plain',
//                 },
//                 body: JSON.stringify(payload)
//             });
//             if (!response.ok) {
//                 throw new Error(`Network response was not ok: ${response.status}`);
//             }

//             return response.text();
//         } catch (error) {
//             console.error("Error:", error);
//             return "Error";
//         }
//     };

//     useEffect(() => {
//         const script = document.createElement("script");
//         script.src = scriptSrc;
//         script.async = true;
//         document.body.appendChild(script);
//         return () => {
//           document.body.removeChild(script);
//         };
//       }, [scriptSrc]);

//       useEffect(() => {
//         const unlisten = navigate.subscribe(() => {
//           const script = document.querySelector(`script[src="${scriptSrc}"]`);
//           if (script) {
//             document.body.removeChild(script);
//           }
//         });

//         return () => {
//           unlisten();
//         };
//       }, [navigate, scriptSrc]);

import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./JoinNowVerify.css";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import check from "../../../src/assets/check.svg";
import cross from "../../../src/assets/cross.png";
import LoadingOverlay from "../../../src/Components/dashboard/LoadingOverlay";
import Logo from "../../../src/assets/quiz_header.png";
import { Typography } from "@mui/material";


const JoinNowVerify = ({ scriptSrc }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(null);
  const [sentEmail, setSentEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const code = searchParams.get("code");
  const email = searchParams.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    if (!code || !email) {
      navigate("/");
    }
    setIsLoading(true);
    setTimeout(() => {
      const payload = {
        code,
        email,
      };
      fetch(`${process.env.REACT_APP_SERVER_URL}/joinNowEmailVerification`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Response data is : ", responseData);
          setIsLoading(false);
          setDataFetched(true);
          if (responseData?.status === "success") {
            setIsVerified(true);
            window.gtagEmailVerifiedTrigger()
          } else if (responseData?.status === "verified") {
            setAlreadyVerified(true);
            setIsVerified(true);
          } else if (responseData?.status === "error") {
            navigate("/");
          }
        });
    }, 2000);

    // Add Google Analytics tag
    const script = document.createElement("script");
    script.src = scriptSrc;
    const script_ = document.createElement("script")
    const id=scriptSrc.split("=")[1]
    script_.innerHTML=`function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${id}")`
    script.async = true;
    script_.async=true;
    const script__=document.createElement("script")
    script__.innerHTML=`gtag('event', 'conversion', {'send_to': 'AW-11418588526/yWSFCO-87JQZEO6q58Qq'});`
    document.head.appendChild(script__)

    document.head.appendChild(script);
    document.head.appendChild(script_)

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script_)
      document.head.removeChild(script__)
    };
  }, [code, email, navigate, scriptSrc]);

  

  const sendEmail = async () => {
    setSentEmail(true);
    const emailTimeout = setTimeout(() => {
      setSentEmail(false);
    }, 3000);
    const payload = {
      emailId: email,
      checked: null,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addWaitlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      return response.text();
    } catch (error) {
      console.error("Error:", error);
      return "Error";
    }
  };

  return (
    <>
      {dataFetched && (
        <div
          className="py-5 py-md-0 verify-container d-flex align-items-center shadow-sm"
          style={{
            backgroundColor: "#fafbff",
            margin: "auto",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <div
            className="rounded shadow-lg col col-11 col-sm-9 col-md-9 col-lg-7"
            style={{
              backgroundColor: "white",
              maxWidth: "100%",
              minHeight: "70%",
              margin: "auto",
            }}
          >
            <div
              className="rounded"
              style={{
                backgroundColor: "var(--collection-1-primary-main)",
                height: "50px",
              }}
            ></div>
            <div className="px-4 px-md-5 py-3">
              {alreadyVerified && isVerified && (
                <>
                  <div className="my-3 my-md-3">
                    <div className="d-flex justify-content-center">
                      <img
                        className="text-center"
                        src={check}
                        style={{ height: "60px", width: "60px" }}
                        alt="tick"
                      />
                    </div>
                    <h4
                      className="py-md-4 py-3 text-center"
                      style={{ fontWeight: "800" }}
                    >
                      Hello {email},
                    </h4>
                    <div className="text-wrapper-verify text-center">
                      <Typography className="embark-on-an">
                        Your email has already been verified.
                      </Typography>
                    </div>
                    <div className="d-flex justify-content-center h5">
                      <button
                        className="register my-3 my-md-4 text-center shadow"
                        style={{ border: "none", color: "white" }}
                        onClick={() => navigate("/")}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </>
              )}
              {!alreadyVerified ? (
                <div className="my-3 my-md-3">
                  <div className="d-flex justify-content-center">
                    {isVerified ? (
                      <img
                        src={check}
                        style={{ height: "60px", width: "60px" }}
                        alt="Checkmark"
                      />
                    ) : (
                      <img
                        src={cross}
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "90%",
                        }}
                        alt="Cross"
                      />
                    )}
                  </div>
                  <h4
                    className="py-md-4 py-3 text-center"
                    style={{ fontWeight: "800" }}
                  >
                    Hello {email},
                  </h4>
                  <div className="text-wrapper-verify text-center">
                    {isVerified ? (
                      <>
                        <Typography className="embark-on-an">
                          Welcome to Creator Stride – Your Adventure Awaits!
                        </Typography>
                        <Typography className="py-2 embark-on-an">
                          Hello and thank you for verifying your email! We're
                          excited to have you with us.
                        </Typography>
                        <Typography className="py-2 embark-on-an">
                          You're currently on our priority waiting list. We are
                          working hard to bring more creators like you on board
                          as soon as possible.
                        </Typography>
                        <Typography className="py-2 embark-on-an">
                          We assure you that your wait will be well worth it.
                        </Typography>
                      </>
                    ) : (
                      <Typography className="embark-on-an">
                        We regret to inform you that the email verification was
                        unsuccessful. Please try again later. We apologize for
                        any inconvenience and appreciate your understanding. If
                        the issue persists, feel free to contact our support
                        team for assistance. Thank you for your patience.
                      </Typography>
                    )}
                  </div>
                  <div className="d-flex justify-content-center h5">
                    {isVerified ? (
                      <button
                        className="register my-3 my-md-4 text-center shadow"
                        style={{ border: "none", color: "white" }}
                        onClick={() => navigate("/")}
                      >
                        Proceed
                      </button>
                    ) : (
                      <button
                        className="register my-3 my-md-4 text-center shadow"
                        style={{ border: "none", color: "white" }}
                        onClick={() => sendEmail()}
                      >
                        Resend
                      </button>
                    )}
                  </div>
                  <div className="text-center">
                    {" "}
                    {sentEmail && (
                      <p
                        className="text-center h6 dropping-div"
                        style={{ fontSize: "var(--footer-div)" }}
                      >
                        Email Sent
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="my-md-3 my-2 px-4 text-center">
              <div>
                <Typography className="embark-on-an">
                  © 2023 creatorstride.com. All Rights Reserved.
                </Typography>
              </div>
              <div className="d-flex justify-content-center align-items-center my-3 my-md-5">
                <div className="bouncing-icons-container">
                  <div className="bouncing-icon1">
                    <LinkedInIcon className="mx-2 shadow-sm" />
                  </div>
                  <div className="bouncing-icon2">
                    <InstagramIcon className="mx-2 shadow-sm" />
                  </div>
                  <div className="bouncing-icon3">
                    <YouTubeIcon className="mx-2 shadow-sm" />
                  </div>
                </div>
              </div>
              <div className="my-5">
                <img alt="logo" src={Logo} width="200px" height="auto" />
                {/* <img className="my-2" src={Logo} /><h5 style={{ color: 'var(--collection-1-primary-main)', fontWeight: '600' }} alt="logo">CreatorStride</h5> */}
              </div>
              {/* <div className='py-3 text-center cursor-pointer'>
                            <a><u>Unsubscribe</u></a>
                        </div> */}
            </div>
          </div>
        </div>
      )}
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default JoinNowVerify;
