// import logo from './logo.svg';
// import './App.css';
import Header from "../header/Header";
import Content from "../content/Content";
import Features from "../features/Features";
import Instruction from "../instruction/Instruction";
import Steps from "../steps/Steps";
import Pricing from "../pricing/Pricing";
import Footer from "../footer/Footer";
import Company from "../company/Company";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-chat-widget/lib/styles.css";
import FourSteps from "../step2/FourSteps";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import WidgetIcon from './WidgetIcon'


const Home = ({
  showLoginModal,
  showRegisterModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);


  return (
    <div className="App">
      <Header
        showLoginModal={showLoginModal}
        showRegisterModal={showRegisterModal}
        handleLoginClick={handleLoginClick}
        handleRegisterClick={handleRegisterClick}
        handleCloseLoginModal={handleCloseLoginModal}
        handleCloseRegisterModal={handleCloseRegisterModal}
      />
      <div className="shadow-sm">
      <div className="bg-light-st">
        <Content />
        </div>
        {/* <div>
          <Company />
        </div> */}
        <div className="bg-light-st">
          <Features />
        </div>
        <div className="bg-light-st">
          <Instruction />
        </div>
        <div className="bg-light-st">
          <Steps />
        </div>
        <div>
          <FourSteps />
        </div>
        <div className="bg-light-st">
          <Pricing />
        </div>
      </div>
      {/* <WidgetIcon/> */}
      <Footer />
    </div>
  );
};

export default Home;
