import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid,Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  contactSuccessOverlay: {
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  contactSuccessContainer: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
  },
  icon: {
    fontSize: 80,
    margin: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const BlogAlreadySubscribed = ({alreadySubscribed,setAlreadySubscribed}) => {
  const classes = useStyles();

  return (
    <div className={classes.contactSuccessOverlay}>
      <div className={`col col-10 col-sm-6 col-lg-5 py-5 contact-success-container ${classes.contactSuccessContainer}`}>
        <InfoIcon className={classes.icon} style={{ color: '#2196F3' }} />
        <Typography variant="h5" className={classes.heading}>
          Already Subscribed!
        </Typography>
        <Typography>
          "Looks like you're already subscribed to our blog! You're already part of our community and will continue to receive exciting updates, insightful content, and valuable information directly to your inbox."
        </Typography>
        <br />
        <Button style={{ backgroundColor: 'var(--collection-1-primary-main)', color: 'white', width: '50%' }} onClick={()=>setAlreadySubscribed(!alreadySubscribed)}>
          Done
        </Button>
      </div>
    </div>
  );
  
};

export default BlogAlreadySubscribed;
