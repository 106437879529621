import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Diversity1 } from "@mui/icons-material";
import "./contactUs.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import ContactUsSuccess from "./ContactUsSuccess";
import Cookies from "js-cookie"

const Quiz = () => {
  let { id } = useParams();
  const [emailField, setEmailField] = useState("");
  const [nameField, setNameField] = useState("");
  const [messageField, setMessageField] = useState("");
  const [showContactSuccess, setShowContactSuccess] = useState(false);
  const navigate = useNavigate();
  const session_id=Cookies.get("session_id")

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const postQuestion = () => {
    const payload = {
      nameField,
      emailField,
      messageField,
      session_id
    };
    if (!nameField || !emailField || !messageField) {
      return;
    }
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/postContactUsForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      setShowContactSuccess(true);
      setNameField("");
      setEmailField("");
      setMessageField("");
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(255, 245, 235)",
        }}
      >
        <Box
          className="px-4 px-sm-5 py-4 py-sm-5"
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "fit-content",
            borderBottomLeftRadius: "50%",
            position: "absolute",
            right: "0",
          }}
        ></Box>
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="d-flex flex-column justify-content-between h-100">
            <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5">
              <Box className="contact-nav">
                <img
                  src={logo}
                  alt="logo"
                  height="30px"
                  width="auto"
                  onClick={() => navigate("/")}
                />
                <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                  <NavLink to="/">Home</NavLink>
                  {/* <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/faq">FAQ</NavLink> */}
                  <NavLink to="/features">Features</NavLink>
                  <NavLink to="">Blog</NavLink>
                  <NavLink to="">FAQ</NavLink>
                </Box>
              </Box>
            </Box>
            <Box className="d-flex flex-lg-row flex-column align-items-start justify-content-between py-5 px-3 px-md-5 py-3 mx-auto">
              <Box className="col col-11 col-sm-10 col-md-7 col-lg-7 col-xl-5 mx-auto px-3">
                <img
                  src={contactUs}
                  alt="contact-us"
                  width="100%"
                  height="auto"
                />
                <Typography variant="h5" class="py-5 px-3">
                  Expect reliable replies in just 2 days—timely and precise
                  responses assured.
                </Typography>
              </Box>
              <Box class="px-3 mx-auto">
                <Typography variant="h4">
                  Got something to say? We're ready to listen!
                </Typography>
                <Box className="my-4">
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    className="w-100"
                    variant="outlined"
                    value={nameField}
                    onChange={(e) => setNameField(e.target.value)}
                    autoComplete="off"
                  />
                </Box>
                <Box className="my-4">
                  <TextField
                    id="outlined-basic"
                    className="w-100"
                    label="Email"
                    variant="outlined"
                    value={emailField}
                    autoComplete="off"
                    onChange={(e) => setEmailField(e.target.value)}
                  />
                </Box>
                <Box className="my-4">
                  <TextField
                    id="outlined-basic"
                    className="w-100"
                    label="Message"
                    multiline
                    rows={5}
                    variant="outlined"
                    value={messageField}
                    autoComplete="off"
                    onChange={(e) => setMessageField(e.target.value)}
                  />
                </Box>
                <Button
                  style={{
                    backgroundColor: "var(--collection-1-primary-main)",
                    color: "white",
                    width: "100%",
                  }}
                  onClick={postQuestion}
                >
                  Post
                </Button>
                <Box className="py-3">
                  <Typography>
                    For inquiries or support, email us :{" "}
                    <strong>info@creatorstride.com</strong>
                  </Typography>
                  <Box className="d-flex gap-4 py-3">
                    <InstagramIcon />
                    <YouTubeIcon />
                    <LinkedInIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-between px-5"
              // style={{ height: "5%" }}
            >
              <Box
                className="d-flex justify-content-between"
                style={{ height: "auto" }}
              ></Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
              style={{
                minHeight: "50px",
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>
        </Box>
        {showContactSuccess && (
          <ContactUsSuccess
            showContactSuccess={showContactSuccess}
            setShowContactSuccess={setShowContactSuccess}
          />
        )}
      </Box>
    </>
  );
};

export default Quiz;
