import PropTypes from "prop-types";
import React, { useReducer } from "react";
import "./switchStyle.css";

export const Switch = ({ stateProp, onClick }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "off",
  });

  return (
    <div
      className={`switch ${state.state}`}
      onClick={() => {
        dispatch("click");
        if (onClick) {
          onClick(); // Call the provided onClick prop
        }
      }}
    >
      <div className="toggle" />
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "off") {
    switch (action) {
      case "click":
        return {
          state: "on",
        };
    }
  }

  if (state.state === "on") {
    switch (action) {
      case "click":
        return {
          state: "off",
        };
    }
  }

  return state;
}

Switch.propTypes = {
  stateProp: PropTypes.oneOf(["off", "on"]),
  onClick: PropTypes.func, // Add this propType declaration
};
