import logo from "./logo.svg";
import "./App.css";
import SignIn from "./Components/forms/SignIn";
import SignupProfile from "./Components/forms/SignupProfile";
import SignupPayment from "./Components/forms/SignupPayment";
import DashboardHeader from "./Components/dashboard/DashboardHeader";
import DashboardCard from "./Components/dashboard/DashboardCard";
import "./Components/dashboard/DashboardHeader.css";
import { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Plan from "./Components/forms/Plan";
import SignInForm from "./Components/forms/SignInForm";
import Cookies from "js-cookie";
import { RequireAuth } from "./Components/auth/RequireAuth";
import Home from "./Components/homePage/home/Home";
import Features from "./Components/featurePage/features/Features";
import "./styleguide.css";
import { hotjar } from "react-hotjar";
import ModelShower from "./Components/modelShower/ModelShower";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Widget } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import JoinNowVerify from "./Components/staticPage/JoinNowVerify";
import LoadingOverlay from "./Components/dashboard/LoadingOverlay";
import Questionnaire from "./Components/questionnaire/Questionnaire";
import Blog from "./Components/blog/Blog";
import ContactUs from "./Components/contactUs/ContactUs";
import Faq from "./Components/Faq/Faq";
import Internship from "./Components/internship/Internship";
import InternAccountModeForm from "./Components/internAccountModeForm/InternAccountModeForm";
import InternUpload from "./Components/playground/InternUpload";
import EmailVerification from "./Components/EmailVerification/EmailVerification";
import RegisterVerify from "./Components/staticPage/RegisterVerify";
import JoinNowGtag from "./Components/staticPage/JoinNowGtag";
import UnsubscribeEmail from "./Components/UnsubscribeEmail/UnsubscribeEmail";
import QuestionnaireHomePage from "./Components/questionnaire/QuestionnaireHomePage";
import Sidebar from "./Components/questionnaire/Sidebar";
import PlayGroundHistory from "./Components/playground/PlayGroundHistory";
import NewPlaygroundUI from "./Components/playground/PlayGroundUI";
import InternshipWorkUploadForm from "./Components/internship/InternshipWorkUploadForm";
import InternshipAssignmentUploadForm from "./Components/internship/InternshipAssignmentUploadForm";
import TermsConditions from "./Components/staticPage/TermsConditions";
import VideoPlayground from "./Components/VideoPlayground/VideoPlayground.js"

const Dashboard = lazy(() => import("./Components/dashboard/Dashboard"));
const Payment = lazy(() => import("./Components/forms/Payment"));
const SuccessPage = lazy(() => import("./Components/staticPage/SuccessPage"));
const ChannelIdPage = lazy(() => import("./Components/forms/ChannelIdPage"));
const ScrapingPage = lazy(() =>
  import("./Components/scrapingPage/ScrapingPage")
);
const DashboardScreen = lazy(() =>
  import("./Components/dashboard/DashboardScreen")
);
const VideoPage = lazy(() => import("./Components/videoPage/VideoPage"));
const StatusPage = lazy(() => import("./Components/statusPage/StatusPage"));
const SideMenu = lazy(() => import("./Components/sideMenu/SideMenu"));
const AnalyticsPage = lazy(() =>
  import("./Components/analytics/AnalyticsPage")
);
const CommentAnalysis = lazy(() =>
  import("./Components/analytics/commentAnalysis/CommentAnalysis")
);
const Analytics = lazy(() => import("./Components/analytics/Analytics"));
const CompetitorAnalysis = lazy(() =>
  import("./Components/analytics/competitorAnalysis/CompetitorAnalysis")
);
const IndividualCompetitorProfile = lazy(() =>
  import(
    "./Components/analytics/competitorAnalysis/IndividualCompetitorProfile"
  )
);
const CompetitorProfile = lazy(() =>
  import("./Components/analytics/competitorAnalysis/CompetitorProfile")
);
const TagsCovered = lazy(() =>
  import("./Components/analytics/competitorAnalysis/TagsCovered")
);
const SiteHeader = lazy(() =>
  import("./Components/dashboardHeader/SiteHeader")
);
const CompetitorProfileBody = lazy(() =>
  import("./Components/analytics/competitorAnalysis/CompetitorProfileBody")
);
const TagsSuggested = lazy(() =>
  import("./Components/analytics/competitorAnalysis/TagsSuggested")
);
const PageScrollerTop = lazy(() =>
  import("./Components/pageScroller/PageScrollerTop")
);
const ContentGeneration = lazy(() =>
  import("./Components/analytics/contentGeneration/ContentGeneration")
);
const CompetitorFilterSearch = lazy(() =>
  import("./Components/sideMenu/CompetitorFilterSearch")
);
const EditorUserPermission = lazy(() =>
  import("./Components/sideMenu/EditorUserPermission")
);
const AccessUserSignup = lazy(() =>
  import("./Components/sideMenu/AccessUserSignup")
);
const Expired = lazy(() => import("./Components/staticPage/Expired"));
const AccessorLogs = lazy(() => import("./Components/sideMenu/AccessorLogs"));
const ChangePassword = lazy(() => import("./Components/forms/ChangePassword"));
const UserProfile = lazy(() =>
  import("./Components/sideMenu/AccessUserSignup")
);
const Playground = lazy(() => import("./Components/playground/Playground"));
const IndividualBlogPage = lazy(() =>
  import("./Components/blog/IndividualBlogPage.js")
);

function App() {
  const [response, setResponse] = useState([]);
  const [dashboardCards, setDashboardCards] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);

  const cookie_session = Cookies.get("session_id");
  console.log(cookie_session);
  useEffect(() => {
    hotjar.initialize(3781181, 6);
  }, []);

  return (
    <div className="App mx-auto h-100">
      <Suspense fallback={<LoadingOverlay />}>
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <ModelShower>
                  <PageScrollerTop />
                  <Home />
                </ModelShower>
              </ErrorBoundary>
            }
          ></Route>
          <Route
            path="/playground"
            element={
              <ErrorBoundary>
                <ModelShower>
                  <PageScrollerTop />
                  <VideoPlayground />
                </ModelShower>
              </ErrorBoundary>
            }
          ></Route>
          <Route
            path="features"
            element={
              <ErrorBoundary>
                <ModelShower>
                  <Features />
                  <PageScrollerTop />
                </ModelShower>
              </ErrorBoundary>
            }
          />
          <Route
            path="signIn"
            element={
              <ErrorBoundary>
                <SignInForm />
              </ErrorBoundary>
            }
          />
          <Route
            path="signUpProfile"
            element={
              <ErrorBoundary>
                <SignupProfile />
              </ErrorBoundary>
            }
          />
          <Route
            path="/internship/v1/playground"
            element={<NewPlaygroundUI />}
          />
          <Route
            path="/internship/v1/playground/history"
            element={<PlayGroundHistory />}
          />
          <Route
            path="signUpPlan"
            element={
              <ErrorBoundary>
                <Plan />
              </ErrorBoundary>
            }
          />
          <Route
            path="/internship/questionnaire"
            element={<Questionnaire />}
          ></Route>
          <Route
            path="/internship/questionnaire-Home"
            element={<QuestionnaireHomePage />}
          ></Route>
          <Route path="/internship/playground" element={<Playground />}></Route>
          <Route path="/internship/upload" element={<InternUpload />}></Route>
          <Route
            path="emailVerification"
            element={<EmailVerification />}
          ></Route>
          <Route
            path="/unsubscribe/email"
            element={<UnsubscribeEmail />}
          ></Route>
          <Route
            path="dashboard"
            element={
              <ErrorBoundary>
                <RequireAuth>
                  <SideMenu>
                    <DashboardScreen />
                  </SideMenu>
                </RequireAuth>
              </ErrorBoundary>
            }
          />
          <Route
            path="/dashboard/analytics"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <AnalyticsPage />
                </SideMenu>
              </ErrorBoundary>
            }
          >
            <Route
              index
              element={
                <ErrorBoundary>
                  <Analytics />
                </ErrorBoundary>
              }
            />
            <Route
              path="commentAnalysis"
              element={
                <ErrorBoundary>
                  <CommentAnalysis />
                </ErrorBoundary>
              }
            />
            <Route
              path="competitorAnalysis"
              element={
                <ErrorBoundary>
                  <CompetitorAnalysis />
                </ErrorBoundary>
              }
            />
            <Route
              path="contentGeneration"
              element={
                <ErrorBoundary>
                  <ContentGeneration />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path="payment"
            element={
              <ErrorBoundary>
                <Payment />
              </ErrorBoundary>
            }
          />
          <Route
            path="channelId"
            element={
              <ErrorBoundary>
                <ChannelIdPage />
              </ErrorBoundary>
            }
          />
          <Route
            path="accountCreated"
            element={
              <ErrorBoundary>
                <SuccessPage />
              </ErrorBoundary>
            }
          />
          <Route
            path="scraping"
            element={
              <ErrorBoundary>
                <ScrapingPage />
              </ErrorBoundary>
            }
          />
          <Route
            path="videoPage"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <VideoPage />
                </SideMenu>
              </ErrorBoundary>
            }
          />
          <Route
            path="statusPage"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <StatusPage />
                </SideMenu>
              </ErrorBoundary>
            }
          />
          <Route
            path="CompetitorFilterSearch"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <CompetitorFilterSearch />
                </SideMenu>
              </ErrorBoundary>
            }
          />
          <Route
            path="UserPermission"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <EditorUserPermission />
                </SideMenu>
              </ErrorBoundary>
            }
          />
          <Route
            path="accessUserSignup"
            element={
              <ErrorBoundary>
                <AccessUserSignup />
              </ErrorBoundary>
            }
          />
          <Route
            path="expired"
            element={
              <ErrorBoundary>
                <Expired />
              </ErrorBoundary>
            }
          />
          <Route
            path="accessorLogs"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <AccessorLogs />
                </SideMenu>
              </ErrorBoundary>
            }
          />
          <Route
            path="changePassword"
            element={
              <ErrorBoundary>
                <ChangePassword />
              </ErrorBoundary>
            }
          />
          <Route
            path="profile"
            element={
              <ErrorBoundary>
                <UserProfile />
              </ErrorBoundary>
            }
          />
          <Route path="blogs" element={<Blog />} />
          <Route path="/blog/:title" element={<IndividualBlogPage />} />
          <Route path="FAQ" element={<Faq />} /> */}
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="internship" element={<Internship />} />
          <Route path="termsConditions" element={<TermsConditions/>}/>
          <Route
            path="/internship/work/upload"
            element={<InternshipWorkUploadForm />}
          />
          <Route
            path="/internship/assignment/upload"
            element={<InternshipAssignmentUploadForm />}
          />
          <Route
            path="internship/account"
            element={<InternAccountModeForm />}
          />
          <Route
            path="/dashboard/analytics/competitorAnalysis/:channelId"
            element={
              <ErrorBoundary>
                <SideMenu>
                  <CompetitorProfile />
                </SideMenu>
              </ErrorBoundary>
            }
          >
            {/* <Route index element={<CompetitorProfileBody />} /> */}
            <Route
              path="tagsCovered"
              element={
                <ErrorBoundary>
                  <TagsCovered />
                </ErrorBoundary>
              }
            />
            <Route
              path="tagsSuggestion"
              element={
                <ErrorBoundary>
                  <TagsSuggested />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path="/user/verify"
            element={
              <JoinNowVerify scriptSrc="https://www.googletagmanager.com/gtag/js?id=AW-11418588526" />
            }
          />
          <Route path="/email/verify" element={<RegisterVerify />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </div>
  );
}

export default App;
