import React from 'react'
import user from '../../assets/user.png'
import './SideMenu.css'
import { useState, useContext, useRef, useEffect } from 'react'
import close from '../../assets/close.png'
import Cookies from 'js-cookie'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux';


export const CloseHandler = React.createContext()
export const OpenHandler = React.createContext()
export const SideMenuStatus = React.createContext()
export const UserNameHandler = React.createContext()


const SideMenu = ({ children, thumbnail }) => {
    const nameData = Cookies.get("channel_name")
    const [sideMenuStatus, setSideMenuStatus] = useState(false)
    const [userRole, setUserRole] = useState("")
    const [channelImageUrl, setChannelImageUrl] = useState('')
    const session_id = Cookies.get("session_id")
    const channel_id = Cookies.get("channel_id")
    const navigate = useNavigate()
    const location = useLocation();

    const isDashboardRoute = location.pathname === '/dashboard';
    const closeHandler = () => {
        setSideMenuStatus(false)
    }

    const openHandler = () => {
        setSideMenuStatus(true)
    }

    const userNameHandlerValue = {

    }


    function logoutUpdater() {
        const payload = {
            session_id
        }
        try {
            fetch(`${process.env.REACT_APP_SERVER_URL}/logoutHandler`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(responseData => {
                    if (responseData.status === "success") {
                        
                        navigate('/dashboard')
                    } else if (responseData.status === "Unauthorized") {
                        
                        navigate('/')
                    } else if (responseData.status === "error") {
                        // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
                    }
                })
        } catch (error) {
            console.log("Error")
            console.log(error)
        }
    }



    useEffect(() => {
        try {
            const payload = {
                session_id
            }
            fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserRole`, {
                method: "POST",
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(responseData => {
                    if (responseData.status === "success") {
                        setUserRole(responseData.user_role)
                    } else if (responseData.status === "Unauthorized") {
                        
                        navigate('/')
                    } else if (responseData.status === "error") {
                        // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
                    }
                })
        } catch (error) {
            console.log("Error")
            console.log(error)
        }
    }, [sideMenuStatus])






    const logoutHandler = async () => {
        console.log("Into the function logout handler")
        logoutUpdater()
        closeHandler()
    }


    useEffect(() => {
        const payload = {
            session_id: session_id,
            channel_id: channel_id
        }
        if (!channel_id) {
            return;
          }
        try {
            fetch(`${process.env.REACT_APP_SERVER_URL}/getDashboardCardHeader`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(responseData => {
                    console.log("success in the dashboard header")
                    console.log("The response data from fetch site header : ", responseData)
                    if (responseData.status === "Unauthorized") {
                        
                        navigate('/')
                    } else if (responseData.status === "success") {
                        const thumbnail = responseData.result.thumbnail_url
                        // dispatch(setThumbnail(thumbnail));
                        thumbnail && setChannelImageUrl(thumbnail)
                        Cookies.set("channel_name", responseData.result.channel_name)

                    } else if (responseData.status === "error") {
                        // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
                    }
                })
                .catch(error => {
                    console.log("error")
                    console.log(error)
                })
        } catch (error) {
            console.log("error")
            console.log("Error")
        }
    }, [channel_id])

    return (
        <>
            {sideMenuStatus ?
                <div id="side-menu-container" className='col-12'>
                    <div id="side-menu" className='position-fixed z-9999 col-12 col-sm-9 col-md-6 col-lg-4 vh-100'>
                        <div className="d-flex flex-column bg-white jusitfy-content-between overflow-auto h-100">
                            <div className='d-flex justify-content-end px-5 py-4' onClick={closeHandler}><img alt='close icon' src={close} className='w-15px opacity-50' /></div>
                            <div className='d-flex align-items-center justify-content-center py-2 py-md-3'><img src={channelImageUrl || user} className='w-60px rounded-circle' /></div>
                            <div className='text-center py-3'><h2>Hi {nameData},</h2></div>
                            <div className='d-flex flex-column'>
                                {/* <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2'>Profile</button> */}
                                <button className={`border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button ${isDashboardRoute ? 'd-none' : 'd-block'}`} onClick={() => navigate('/dashboard')}>Dashboard</button>
                                <div className='d-block d-md-none text-center'>
                                    <Link to="/dashboard/analytics"> <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 cursor-pointer shadow menu-button d-none d-md-block' onClick={() => closeHandler()}>Analytics</button></Link>
                                    <Link to='/dashboard/analytics/commentAnalysis'>
                                        <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button' onClick={() => closeHandler()}>Comment Analysis</button>
                                    </Link>
                                    <Link to="/dashboard/analytics/competitorAnalysis">
                                        <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button' onClick={() => closeHandler()}>Competitor Analysis</button>
                                    </Link>
                                </div>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button d-none d-md-block' onClick={() => { closeHandler() ;navigate('/dashboard/analytics/competitorAnalysis');}}>Analysis</button>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button d-block d-md-none' onClick={() => { closeHandler() ;navigate('/dashboard/analytics/contentGeneration');}}>Content Generation</button>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button' onClick={() => { closeHandler() ;navigate('/CompetitorFilterSearch'); }}>Competitor Search</button>
                                <button className={`border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button ${userRole !== "Owner" ? 'd-none' : 'd-block'}`} onClick={() => { closeHandler();navigate('/userPermission');}}>User permissions</button>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button' onClick={() => { closeHandler() ;navigate('/dashboard/analytics'); }}>Tag Analysis</button>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button' onClick={() => navigate('/changePassword')}>Change Password</button>
                                <button className={`border-0 w-75 rounded-pill mx-auto my-3 py-2 shadow menu-button ${userRole !== "Owner" ? 'd-none' : 'd-block'}`}>Billing</button>
                            </div>
                            <div className='d-flex justify-content-center mb-5'>
                                <button className='border-0 w-75 rounded-pill mx-auto my-3 py-2 s-in-btn shadow-lg' onClick={logoutHandler}>Logout</button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="overlay"
                        className="position-fixed col-12"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", height: "100vh", overflow: 'hidden', zIndex: '1000', pointerEvents: 'none' }}
                        onClick={closeHandler}
                    ></div>
                </div>
                : ""}
            <OpenHandler.Provider value={openHandler}>
                <SideMenuStatus.Provider value={sideMenuStatus}>
                    {children}
                </SideMenuStatus.Provider>
            </OpenHandler.Provider>
        </>
    )
}

export default SideMenu