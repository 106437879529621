import React, { Children, useState } from 'react';

const ModelShower = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showJoinNowModal, setShowJoinNowModal] = useState(false)

  const handleLoginClick = () => {
    setShowRegisterModal(false);
    setShowJoinNowModal(false)
    setShowLoginModal(true);
  };

  const handleJoinNowClick=()=>{
    setShowRegisterModal(false);
    setShowLoginModal(false);
    setShowJoinNowModal(true)
  }

  const handleRegisterClick = () => {
    setShowLoginModal(false);
    setShowJoinNowModal(false)
    setShowRegisterModal(true);
  };

  const handleCloseLoginModal = () => {
    console.log("Closing the modal")
    setShowLoginModal(false);
  };

  const handleCloseRegisterModal = () => {
    console.log('Closing the register')
    setShowRegisterModal(false);
  };

  const handleCloseJoinNowClick =()=>{
    setShowJoinNowModal(false)
  }
  
  const JoinNowClick=()=>{
    alert("clicked")
  }

  
  return (
    <>
      {React.Children && React.Children.map(children, child => {
      return React.cloneElement(child, {
        showLoginModal,
        showRegisterModal,
        showJoinNowModal,
        handleLoginClick,
        handleRegisterClick,
        handleCloseLoginModal,
        handleCloseRegisterModal,
        handleCloseJoinNowClick,
        handleJoinNowClick,
        JoinNowClick // Ensure JoinNowClick is passed down
      });
    })}
      {/* <Children
      showLoginModal={showLoginModal}
      showRegisterModal={showRegisterModal}
      showJoinNowModal={showJoinNowModal}
      handleLoginClick={handleLoginClick}
      handleRegisterClick={handleRegisterClick}
      handleCloseLoginModal={ handleCloseLoginModal}
      handleCloseRegisterModal={handleCloseRegisterModal}
      handleCloseJoinNowClick={ handleCloseJoinNowClick}
      handleJoinNowClick={handleJoinNowClick}
      /> */}
    </>
  );
};

export default ModelShower;
