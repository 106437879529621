import * as React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';


export default function LinearColor() {
  return (
    <Stack sx={{ width: '100%', color: 'grey.500',position:"absolute",zIndex:"9999" }} spacing={2}>
    <LinearProgress style={{backgroundColor:"var(--collection-1-primary-main)"}}/>
  </Stack>
  );
}

