import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const CompetitorSearchContext = createContext();

export const CompetitorSearchCountProvider = ({ children }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.canSearchCompetitor);
    
    return (
        <CompetitorSearchContext.Provider value={{ state, dispatch }}>
            {children}
        </CompetitorSearchContext.Provider>
    );
};


export const useCompetitorSearchContext = () => {
    return useContext(CompetitorSearchContext);
};
