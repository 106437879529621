import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import internship from "../../assets/internship.jpg";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Close, Diversity1 } from "@mui/icons-material";
import "./Internship.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import ContactUsSuccess from "./ContactUsSuccess";
import Cookies from "js-cookie";
import google from "../../assets/Google.svg";
import Register from "../homePage/register/Register";
import eye from "../../assets/eye.png";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CryptoJS from "crypto-js";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import LinearColor from "../headerLoader/headerLoader";
import { toast } from "react-toastify";



const Internship = () => {
  let { id } = useParams();
  const role = "Intern";
  const navigate = useNavigate();
  const session_id = Cookies.get("session_id");
  const [showRegister, setShowRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [failedStatus, setFailedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [name, setName] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [specialization, setSpecilization] = useState(null);
  const [year, setYear] = useState(null);
  const [location, setLocation] = useState(null);
  const [emailID, setEmailID] = useState(null);
  const [position, setPosition] = useState(null);
  const [resume, setResume] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [showHeaderLoader, setShowHeaderLoader] = useState(false);

  var startYear = 2020;
  const postions = [
    "Software Engineering Intern",
    "Product Intern",
    "Quality Testing Intern",
    "Founder’s associate",
    "Marketing Intern",
    "Sales Intern",
    "All Internships",
  ];

  const yearsData = ["1st Year", "2nd Year", "3rd Year", "4th Year"];

  const specializations = [
    "Computer Science and Engineering",
    "Information Technology",
    "Electrical Engineering",
    "Mechanical Engineering",
    "Civil Engineering",
    "Aerospace Engineering",
    "Chemical Engineering",
    "Biotechnology Engineering",
  ];

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const wait = async (n) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n);
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     const isLoggedIn = Cookies.get("isLoggedIn");
  //     if (isLoggedIn === "true") {
  //       await wait(1000);
  //       navigate("/dashboard");
  //     } else {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const formSubmition = async () => {
    setShowHeaderLoader(true)
    setShowDialog(false);
    setAlreadySent(false);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("collegeName", collegeName);
    formData.append("specialization", specialization);
    formData.append("year", year);
    formData.append("location", location);
    formData.append("emailId", emailID);
    formData.append("position", position);
    formData.append("resume", resume);

    try {
      const submitResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/submitInternForm`,
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("Submit response : ", submitResponse);
      const response = await submitResponse.json();
      console.log("Response : ", response);
      setShowHeaderLoader(false)
      if (response?.status === "success") {
        setShowDialog(true);
        if (
          response?.status === "success" &&
          response?.message !== "API successfull"
        ) {
          setAlreadySent(true);
        } else {
          setName("");
          setEmailID("");
          setCollegeName("");
          setLocation("");
          setPosition("");
          setResume("");
          setYear("");
          setSpecilization("");
        }
      } else if (response?.status === "error"){
        toast.error("Something went wrong !!", { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      setShowHeaderLoader(false)
      console.log("Error occured in form submition : ", error);
      toast.error("Something went wrong !!", { position: toast.POSITION.TOP_RIGHT });
    }

    // console.log("Payload ==== ", payload);

    // try {
    //   fetch(`${process.env.REACT_APP_SERVER_URL}/internUserValidation`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   })
    //     .then((response) => response.json())
    //     .then((responseData) => {
    //       if (responseData.status === "failed") {
    //         setTimeout(() => {
    //           setFailedStatus("");
    //         }, 1000);
    //         setFailedStatus("Login Failed");
    //       } else if (responseData.status == "success") {
    //         const session_id_ = responseData.session_id;
    //         Cookies.set("isLoggedIn", true);
    //         Cookies.set("session_id", session_id_, { expires: 30 });
    //         navigate("/dashboard");
    //       }
    //     })
    //     .catch((error) => {
    //       throw new Error(error);
    //     });
    // } catch (error) {
    //   throw new Error(error);
    // }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const hashString = (password) => {
    const hashedString = CryptoJS.SHA256(password).toString();
    return hashedString;
  };

  useEffect(() => {
    console.log("Years data in the use effect : ", yearsData);
  }, [yearsData]);

  return (
    <>
      {!isLoading && (
        <Box
          style={{
            backgroundColor: "rgb(255, 245, 235)",
          }}
        >
          {showHeaderLoader && <LinearColor />}
          <Box
            className="px-4 px-sm-5 py-4 py-sm-5"
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
              width: "fit-content",
              borderBottomLeftRadius: "50%",
              position: "absolute",
              right: "0",
            }}
          ></Box>
          <Box
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <Box className="d-flex flex-column justify-content-between h-100">
              <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5">
                <Box className="contact-nav">
                  <img
                    src={logo}
                    alt="logo"
                    height="30px"
                    width="auto"
                    onClick={() => navigate("/")}
                  />
                  <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                    <NavLink to="/">Home</NavLink>
                    {/* <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/faq">FAQ</NavLink> */}
                    <NavLink to="/features">Features</NavLink>
                    <NavLink to="">Blog</NavLink>
                    <NavLink to="">FAQ</NavLink>
                  </Box>
                </Box>
              </Box>
              <Box className="d-flex flex-lg-row flex-column align-items-center justify-content-between py-5 px-3 px-md-5 py-3 mx-auto">
                <Box className="col col-12 col-sm-12 col-md-11 col-lg-7 col-xl-5 mx-auto px-3">
                  <Box className="d-flex justify-content-center">
                    <img
                      src={internship}
                      alt="contact-us"
                      class="intern-image"
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5" class="py-5 px-3">
                      Are you a dynamic individual looking to gain hands-on
                      experience in the fast-paced world of YouTube data
                      analytics and content creation? CreatorStride offers
                      exciting internship opportunities that provide a unique
                      blend of learning, collaboration, and growth.
                    </Typography>
                  </Box>
                </Box>
                <Box class="px-3 mx-auto">
                  <Typography variant="h4">
                    Ready to Experience CreatorStride?
                  </Typography>
                  <Box
                    className="my-4 w-100 d-flex aign-items-center rounded"
                    style={{
                      minHeight: "500px",
                      //border: `2px solid var(--collection-1-primary-main)`,
                    }}
                  >
                    <Box className="w-100 px-4 px-md-5 mx-auto my-auto">
                      {/* <Box className="h4 text-center">Account Login</Box> */}
                      <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="Name"
                          className="w-100"
                          variant="outlined"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="off"
                        />
                      </Box>
                      <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="College Name"
                          type="text"
                          className="w-100"
                          variant="outlined"
                          value={collegeName}
                          onChange={(e) => setCollegeName(e.target.value)}
                          autoComplete="off"
                        />
                      </Box>
                      <Box className="my-4 d-flex w-100 gap-3">
                        <Box className="w-100">
                          <FormControl className="w-100">
                            <InputLabel id="specialization-select-label">
                              Specialization
                            </InputLabel>
                            <Select
                              fullWidth
                              className="w-100"
                              labelId="specialization-select-label"
                              id="specialization-select"
                              label="Specialization"
                              value={specialization}
                              onChange={(e) => setSpecilization(e.target.value)}
                            >
                              {specializations.map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box className="w-100">
                          <FormControl className="w-100">
                            <InputLabel id="year-select-label">Year</InputLabel>
                            <Select
                              fullWidth
                              className="w-100"
                              labelId="year-select-label"
                              id="year-select"
                              label="Year"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            >
                              {yearsData.map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="Current Location"
                          type="text"
                          className="w-100"
                          variant="outlined"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          autoComplete="off"
                        />
                      </Box>
                      <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="Email ID"
                          type="text"
                          className="w-100"
                          variant="outlined"
                          value={emailID}
                          onChange={(e) => setEmailID(e.target.value)}
                          autoComplete="off"
                        />
                      </Box>
                      <Box className="my-4">
                        <FormControl fullWidth>
                          <InputLabel id="position-select-label">
                            Position
                          </InputLabel>
                          <Select
                            labelId="position-select-label"
                            id="position-select"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                          >
                            {postions.map((option) => (
                              <MenuItem value={option}>{option}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <MuiFileInput
                        placeholder="Resume"
                        fullWidth
                        label="Resume"
                        inputProps={{ accept: ".docx, .pdf" }}
                        getInputText="Resume"
                        value={resume}
                        onChange={(file_) => setResume(file_)}
                        clearIconButtonProps={{
                          title: "Remove",
                          children: <CloseIcon fontSize="small" />,
                        }}
                      />
                      {/* <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="Resume"
                          type="text"
                          className="w-100"
                          variant="outlined"
                          value={resume}
                          onChange={(e) => setResume(e.target.value)}
                          autoComplete="off"
                        />
                      </Box> */}
                      <Box className="pb-3 text-danger">{failedStatus}</Box>
                      <Button
                        className="py-2"
                        style={{
                          backgroundColor: "var(--collection-1-primary-main)",
                          // backgroundColor:'white',
                          // color: "var(--collection-1-primary-main)",
                          width: "100%",
                          color: "white",
                        }}
                        disabled={
                          showHeaderLoader ||
                          !name ||
                          !emailID ||
                          !position ||
                          !year ||
                          !resume ||
                          !location ||
                          !collegeName ||
                          !specialization
                        }
                        onClick={formSubmition}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                className="d-flex justify-content-between px-5"
                // style={{ height: "5%" }}
              >
                <Box
                  className="d-flex justify-content-between"
                  style={{ height: "auto" }}
                ></Box>
              </Box>
              <Box
                className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
                style={{
                  minHeight: "50px",
                  backgroundColor: "var(--collection-1-primary-main)",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2023 creatorstride.com. All Rights Reserved.
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isLoading && <LoadingOverlay />}
      {showDialog && (
        <Dialog
          open="true"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="bg-white d-flex justify-content-center position-relative px-5 py-5 rounded">
            <div className="w-100">
              <div className="cross cursor-pointer">
                <IconButton
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {alreadySent ? (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Excellent! You're Ready to Start. Your Details Have Already
                    Been Captured.
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Thanks for submitting! We've received your internship
                    application. Expect a confirmation email soon; check your
                    inbox or spam folder. Any questions? Feel free to reach out.
                    Thanks for your interest!
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Internship;
