import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import logo from "../../assets/quiz_header.png";
import { useNavigate } from "react-router-dom";
import QuesionnaireImage from "../../assets/questionnaire.jpg";

const QuestionnaireHomePage = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const ButtonStyle = {
    backgroundColor: isHovered ? "#f0b33a" : "var(--collection-1-primary-main)",
  };

  return (
    <>
      <Box
        className=""
        style={{
          backgroundColor: "white",
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <Box className="d-flex flex-column justify-content-between h-100">
          <Box class="d-flex flex-column flex-sm-row align-items-center justify-content-between px-5 pt-5 gap-3 gap-sm-0">
            <Box onClick={() => navigate("/")}>
              <img src={logo} alt="logo" height="30px" width="auto" />
            </Box>
          </Box>
          <Box className="d-flex flex-lg-row flex-column col col-10 col-sm-9 col-lg-9 col-xl-7 mx-auto align-items-start gap-5">
            <Box className="d-flex flex-column gap-4">
              <Box className="">
                <Typography variant="h4">
                  We're on a mission to understand you better—your favorite
                  things, pursuits, and what makes you, you!
                </Typography>
              </Box>
              <Box className="">
                <Typography
                  variant="h5"
                  style={{ fontWeight: "100", opacity: "0.8" }}
                >
                  We're eager to know more about you! Share your interests,
                  hobbies, and passions with us. By understanding what
                  captivates you, we can tailor our content to create an
                  engaging and personalized experience just for you. Let's
                  embark on a journey together to make our platform a reflection
                  of your diverse and unique interests.
                </Typography>
              </Box>
              <Box>
                <Button className="text-light py-2 px-4 shadow moving-animater" style={ButtonStyle} onMouseEnter={(()=>setIsHovered(true))} onMouseLeave={()=>setIsHovered(false)}> 
                  Take Quiz
                </Button>
              </Box>
            </Box>
            <Box>
              <img
                src={QuesionnaireImage}
                className="rounded moving-animater"
                width="450px"
                height="auto"
                alt="business"
              />
            </Box>
          </Box>
          <Box
            className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
            style={{
              minHeight: "50px",
              backgroundColor: "var(--collection-1-primary-main)",
              width: "100%",
              color: "white",
            }}
          >
            © 2023 creatorstride.com. All Rights Reserved.
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QuestionnaireHomePage;
