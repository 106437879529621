import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import './FaqDropdown.css'

const FaqDropDown = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <>
      <Box className="faq-drop-down">
        <Box className="px-5 py-2 rounded" style={{ backgroundColor: "var(--collection-1-primary-main-mild)" }}>
          <Box className="d-flex justify-content-between align-items-center cursor-pointer" onClick={()=>setShowAnswer(!showAnswer)}>
            <Typography variant="h6">{question}</Typography>
             <ArrowDropDownCircleIcon/>
            </Box>
        </Box>
        <Box className="px-5 py-2">
            <Typography variant='h6'>{showAnswer && answer}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default FaqDropDown;
