import { Box, Typography, Button } from "@mui/material";
import CopyToClipboardButton from "./CopyToClipboard";
import DownloadTextButton from "./DownloadButton";
import UploadButton from "./UploadButton";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { updateContentGenerationHistory } from "../../redux/actions/actions";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import { TbDownload } from "react-icons/tb";
import CloseIcon from "@mui/icons-material/Close";
import Textarea from "@mui/joy/Textarea";
import Dialog from "@mui/material/Dialog";
import logo from "../../assets/quiz_header.png";
import { IconButton } from "@mui/material";

const BotCard = ({
  topic,
  script,
  isScript,
  layoutContainerRef,
  isHistory,
  userPrompt,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contentGenerationHistory = useSelector(
    (state) => state.contentGenerationHistory
  );
  const session_id = Cookies.get("session_id");
  const [customizeScript, setCustomizeScript] = useState(false);
  const [customizationPrompt, setCustomizationPrompt] = useState(null);
  const [customizedScriptData, setCustomizedScriptData] = useState(null);
  const [scriptGenerated, setScriptGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Generating...");
  const [isQuotaExceeded, setIsQuotaExceeded] = useState(false);
  const dialogContent =
    "Trial accounts are limited to a set number of requests for evaluation purposes. Upgrade to a paid plan for unrestricted access";

  const customizedScriptGeneration = async () => {
    setIsLoading(true);
    setLoadingText("Customizing The Script...");
    const payload = {
      userPrompt: customizationPrompt,
      script: script,
      session_id,
      topics: [topic],
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/generateCustomizedScript`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response?.json();
    setCustomizationPrompt(null);
    setIsLoading(false);
    setCustomizeScript(false);
    setLoadingText("Generating...");
    if (responseData.status_code == "401") {
      setIsQuotaExceeded(true);
      return;
    }
    if (responseData?.status === "success") {
      if (responseData?.data) {
        console.log("The script is : ", responseData?.data);
        setCustomizedScriptData(responseData?.data);
        const isTopicIncluded = contentGenerationHistory.some(
          (history) => history.topic === topic
        );
        if (isTopicIncluded) {
          const contentGenerationHistory_ = contentGenerationHistory.map(
            (history, index) => {
              // Assuming item is declared or passed as a prop
              if (history.topic === topic) {
                history.response = responseData?.data;
              }
              return history; // Don't forget to return the modified or unmodified history object
            }
          );
          console.log(
            "New content generation history by updating : ",
            contentGenerationHistory_
          );
          dispatch(updateContentGenerationHistory(contentGenerationHistory_));
        } else {
          const data = {
            topic: topic,
            response: responseData?.data,
          };
          const newContentGenerationData = [...contentGenerationHistory, data];
          console.log(
            "New content generation history by adding : ",
            newContentGenerationData
          );
          dispatch(updateContentGenerationHistory(newContentGenerationData));
        }
      }
    } else if (responseData.status === "Unauthorized") {
      navigate("/");
      Cookies.remove("session_id");
      Cookies.set("isLoggedIn", false);
    }
  };

  const regenerateScript = async () => {
    setIsLoading(true);
    setLoadingText("Generating Script From Topic...");
    const payload = {
      topics: [topic],
      session_id,
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/generateScriptOnTopic`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response?.json();
    setIsLoading(false);
    setLoadingText("Generating...");
    console.log("Generate script on topic : ", responseData);
    if (responseData.status_code == "401") {
      setIsQuotaExceeded(true);
      return;
    }
    if (responseData?.status === "success") {
      if (responseData?.data) {
        console.log("The script is : ", responseData?.data);
        setCustomizedScriptData(responseData?.data);
        const isTopicIncluded = contentGenerationHistory.some(
          (history) => history?.topic === topic
        );
        console.log("Is topic included : ", isTopicIncluded);

        if (isTopicIncluded) {
          const contentGenerationHistory_ = contentGenerationHistory.map(
            (history, index) => {
              // Assuming item is declared or passed as a prop
              if (history?.topic === topic) {
                history.response = responseData?.data;
              }
              return history; // Don't forget to return the modified or unmodified history object
            }
          );
          console.log(
            "New content generation history by updating : ",
            contentGenerationHistory_
          );
          dispatch(updateContentGenerationHistory(contentGenerationHistory_));
        } else {
          const data = {
            topic: topic,
            response: responseData?.data,
          };
          const newContentGenerationData = [...contentGenerationHistory, data];
          console.log(
            "New content generation history by adding : ",
            newContentGenerationData
          );
          dispatch(updateContentGenerationHistory(newContentGenerationData));
        }
      }
    } else if (responseData.status === "Unauthorized") {
      navigate("/");
      Cookies.remove("session_id");
      Cookies.set("isLoggedIn", false);
    }
  };

  useEffect(() => {
    const layoutContainer = layoutContainerRef.current;
    console.log("From layout container use effect : ", layoutContainer);
    // Scroll to the bottom of the layout container
    if (layoutContainer) {
      console.log(
        "The scroll height from useEffect is : ",
        layoutContainer.scrollHeight
      );
      layoutContainer.scrollTop = layoutContainer.scrollHeight;
    }
  }, [dispatch]);

  return (
    <Box className="my-3  w-75 d-flex justify-content-start bot-card">
      <Box
        className="px-3 px-md-5 py-4 py-md-5 rounded shadow bg-light"
        stye={{ backgroundColor: "white !important" }}
      >
        {isScript ? (
          <Box className="d-flex justify-content-end gap-3">
            {/* <DownloadTextButton text={script} filename={topic} /> */}
            <CopyToClipboardButton text={script} />
          </Box>
        ) : null}
        {userPrompt && (
          <Typography>
            <b>User Prompt : </b>
            {userPrompt}
          </Typography>
        )}
        {topic ? (
          <Typography className="py-2">
            <b>{topic}</b>
          </Typography>
        ) : null}
        {customizedScriptData ? (
          <Typography>{customizedScriptData}</Typography>
        ) : (
          <Typography>{script}</Typography>
        )}
        <Box>
          {isScript && !isHistory ? (
            <Box
              className={`d-flex flex-column flex-lg-row ${
                customizeScript
                  ? "justify-content-between"
                  : "justify-content-start"
              } gap-3 py-4`}
            >
              {!customizeScript ? (
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "var(--collection-1-primary-main)",
                    height: "fit-content",
                    border: "2px solid var(--collection-1-primary-main)",
                    fontSize: "var(--p-too-tiny)",
                  }}
                  className="my-1 mx-1 px-3 py-2 rounded shadow"
                  onClick={() => {
                    setCustomizeScript(true);
                  }}
                >
                  Customize with AI
                </Button>
              ) : (
                <Box
                  className={`d-flex justify-content-between flex-column align-items-start ${
                    customizeScript ? "w-100" : ""
                  }`}
                >
                  <Box
                    className="bg-white p-4 pt-5 gap-3 w-100 rounded"
                    style={{
                      position: "relative",
                    }}
                  >
                    <Textarea
                      className="w-100"
                      minRows={4}
                      placeholder="Your customization Prompt Goes here.."
                      value={customizationPrompt}
                      onChange={(e) => setCustomizationPrompt(e.target.value)}
                    />
                    <IconButton
                      //   style={closeIconStyle}
                      style={{
                        position: "absolute",
                        right: "-5px",
                        top: "0",
                      }}
                      onClick={() => setCustomizeScript(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Box className="d-flex justify-content-end my-2 w-100">
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "var(--collection-1-primary-main)",
                          height: "fit-content",
                          border: "2px solid var(--collection-1-primary-main)",
                          fontSize: "var(--p-too-tiny)",
                        }}
                        className="w-100 my-1 mx-1 px-3 py-2 rounded shadow"
                        onClick={() => customizedScriptGeneration()}
                      >
                        Customize with AI
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              <Button
                style={{
                  color: "white",
                  backgroundColor: "var(--collection-1-primary-main)",
                  height: "fit-content",
                  border: "2px solid var(--collection-1-primary-main)",
                  fontSize: "var(--p-too-tiny)",
                }}
                className="my-1 mx-1 px-3 py-2 rounded shadow flex-grow"
                onClick={() => {
                  regenerateScript();
                }}
              >
                Regenerate with AI
              </Button>
            </Box>
          ) : null}
          {isHistory && (
            <Box className="d-flex justify-content-end py-3">
              <UploadButton topic={topic} />
            </Box>
          )}
        </Box>
      </Box>
      {isLoading && <LoadingOverlay text={loadingText} />}
      {isQuotaExceeded && (
        <Dialog open="true">
          <Box className="d-flex justify-content-end px-1 py-1">
            <IconButton onClick={() => setIsQuotaExceeded(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="pb-5 px-4 px-md-5 text-center">
            <Box className="pb-3 mx-auto text-center cursor-pointer">
              <img
                src={logo}
                alt="logo"
                height="30px"
                width="auto"
                onClick={() => navigate("/")}
              />
            </Box>
            <Box className="py-2">{dialogContent}</Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

BotCard.defaultProps = {
  isScript: false,
  topic: null,
  isHistory: false,
};

export default BotCard;
