// import React, { useState, useEffect } from "react";
// import {
//   Widget,
//   addResponseMessage,
//   addUserMessage,
// } from "react-chat-widget-2";
// import "react-chat-widget-2/lib/styles.css";
// import logo from "../../../assets/user.png";
// import Cookies from "js-cookie";

// function App() {
//   const [askingForEmail, setAskingForEmail] = useState(false);
//   const [emailField, setEmailField] = useState("");
//   const [userMessage, setUserMessage] = useState("");
//   const [chatHistory, setChatHistory] = useState([]);
//   const session_id=Cookies.get('session_id')

//   useEffect(() => {
//       const payload = {
//         session_id,
//       };
//       try {
//         fetch(`${process.env.REACT_APP_SERVER_URL}/fetchChatHistory`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "text/plain",
//           },
//           body: JSON.stringify(payload),
//         })
//           .then((response) => response.json())
//           .then((responseData) => {
//             console.log("The post user message : ", responseData);
//             if (responseData?.status === "success" && responseData?.history) {
//               setChatHistory(responseData?.history);
//               // if(!session_id_value){
//               //   console.log("The session is going to be set : ",responseData?.session_id)
//               //   setSessionId(responseData?.session_id)
//               //   Cookies.set("session_id",responseData?.session_id,{expires:30})
//               // }
//             }
//           });
//       } catch (error) {
//         throw new Error(error);
//       }
//   }, []);

//   useEffect(() => {
//     if (chatHistory.length > 0) {
//       for (var history of chatHistory) {
//         addUserMessage(history?.user);
//         addResponseMessage(history?.bot);
//       }
//     }
//   }, [chatHistory]);

//   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

//   const emailFetch = (userMessage) => {
//     const input_tokens = userMessage.split(" ");
//     console.log(input_tokens);
//     for (var tokens of input_tokens) {
//       console.log("THe token is : ", tokens);
//     }
//     const email = input_tokens.filter((token) => {
//       if (emailRegex.test(token)) {
//         return token;
//       }
//     });
//     return email[0];
//   };

//   const handleNewUserMessage = (userMessage) => {
//     try {
//       const payload = {
//         userMessage,
//         session_id,
//       };
//       fetch(`${process.env.REACT_APP_SERVER_URL}/postUserMessage`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "text/plain",
//         },
//         body: JSON.stringify(payload),
//       })
//         .then((response) => response.json())
//         .then((responseData) => {
//           console.log("The post user message : ", responseData);
//           if (responseData?.status === "success") {
//             addResponseMessage(responseData?.response);
//           }
//         });
//     } catch (error) {
//       throw new Error(error);
//     }
//   };

//   return (
//     <div className="App">
//       <Widget
//         handleNewUserMessage={handleNewUserMessage}
//         profileAvatar={logo}
//         title="Hello Creator !"
//         subtitle=""
//       />
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect, useRef } from "react";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  toggleWidget,
} from "react-chat-widget-2";
import "react-chat-widget-2/lib/styles.css";
import logo from "../../../assets/user.png";
import Cookies from "js-cookie";

function App() {
  const [askingForEmail, setAskingForEmail] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [widgetKey, setWidgetKey] = useState(0); // Increment this key to force re-render
  const session_id = Cookies.get("session_id");
  const [chatVisible, setChatVisible] = useState(false);

  const widgetRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (widgetRef.current && !widgetRef.current.contains(event.target)) {
  //       // User clicked outside the widget, reset the widget
  //       setWidgetKey((prevKey) => prevKey + 1);
  //       toggleWidget()
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [widgetRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (widgetRef.current && !widgetRef.current.contains(event.target)) {
        // User clicked outside the widget, reset the widget
        setWidgetKey((prevKey) => prevKey + 1);

        // Check if the chat is already open before calling toggleWidget
        if (chatVisible) {
          toggleWidget();
          setChatVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [widgetRef, chatVisible]);

  useEffect(() => {
    const payload = {
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchChatHistory`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("The post user message : ", responseData);
          if (responseData?.status === "success" && responseData?.history) {
            // setChatHistory(responseData?.history);
            const chatHistory_ = responseData?.history;

            if (chatHistory_.length > 0) {
              console.log("chathistry in if == ", chatHistory_);
              for (var history of chatHistory_) {
                addUserMessage(history?.user);
                addResponseMessage(history?.bot);
              }
            } else {
              console.log("chathistry in else == ", chatHistory_);
              addResponseMessage("Hi how can I help you ??");
            }
          } else if (
            responseData?.status === "success" &&
            responseData?.message === "No data found"
          ) {
            addResponseMessage("Hi how can I help you ??");
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  // useEffect(() => {
  //   if (chatHistory.length > 0) {
  //     console.log("chathistry in if == ",chatHistory)
  //     for (var history of chatHistory) {
  //       addUserMessage(history?.user);
  //       addResponseMessage(history?.bot);
  //     }
  //   }else{
  //     console.log("chathistry in else == ",chatHistory)
  //     addResponseMessage("Hi how can I help you ??")
  //   }
  // }, [chatHistory]);

  const handleNewUserMessage = (userMessage) => {
    try {
      const payload = {
        userMessage,
        session_id,
      };
      fetch(`${process.env.REACT_APP_SERVER_URL}/postUserMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("The post user message : ", responseData);
          if (responseData?.status === "success") {
            addResponseMessage(responseData?.response);
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleCloseChat = (handleToggle) => {
    setChatVisible(!chatVisible);
  };

  return (
    <div className="App" ref={widgetRef}>
      <Widget
        key={widgetKey}
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={logo}
        title="Hello Creator !"
        subtitle=""
        handleToggle={handleCloseChat}
        style={{ zIndex: "1000" }}
      />
    </div>
  );
}

export default App;
