import React from "react";
import "./style.css";
import LogoWhite from "../../../assets/Creator Stride-white.webp";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Box, Container, Typography, Button } from "@mui/material";
import light_logo from "../../../assets/new_light_logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer p-0">
      <div className="content px-1 px-md-5">
        <div className="grid-container px-4 px-md-5 gap-5">
          <div className="text-wrapper">
            <div
              className="logo d-flex flex-column cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                className="img my-2 mx-2"
                alt="Logo"
                src={light_logo}
                width="200px"
              />
            </div>
          </div>
          <div className="div mobile-footer-div">
            <div className="text-wrapper-2">About Us</div>
            <div
              className="text-wrapper-3"
              onClick={() => navigate("/features")}
            >
              Features
            </div>
            {/* <div className="text-wrapper-3">Pricing</div> */}
            <div
              className="text-wrapper-3"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </div>
            {/* <div className="text-wrapper-3">Support</div> */}
            {/* <div className="text-wrapper-3" onClick={()=>navigate('/FAQ')}>FAQ</div> */}
            <div className="text-wrapper-3">FAQ</div>
            <div className="text-wrapper-3" onClick={()=>navigate("/internship")}>
              Internship
            </div>
          </div>
          <div className="div mobile-footer-div">
            <div className="text-wrapper-2">Resource</div>
            <div className="text-wrapper-3">Blog</div>
            <div className="text-wrapper-3" onClick={()=>navigate("/termsConditions")}>Terms & Conditions</div>
            <div className="text-wrapper-3">Privacy</div>
            {/*<div className="text-wrapper-3">Cookies</div> */}
            {/* <div className="text-wrapper-3">Subscribe</div> */}
          </div>
          <div className="div">
            {/* <div className="title">
                    <div className="text-wrapper-2">Subscribe</div>
                    <p className="join-our-community">Join Our Community To Receive Updates On New Features And Releases.</p>
                  </div>
                  <div className="input">
                    <div className="frame">
                      <div className="text-wrapper-4">Enter Your Email</div>
                    </div>
                    <div className="register">
                      <div className="text-wrapper-5">SEND</div>
                    </div>
                  </div> */}
            <p className="by-subscribing-you">
              <Box className="">
                <Typography>
                  For inquiries or support, email us :{" "}
                  <strong>info@creatorstride.com</strong>
                </Typography>
                <Box className="d-flex gap-4 py-3">
                  <InstagramIcon />
                  <YouTubeIcon />
                  <LinkedInIcon />
                </Box>
              </Box>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
