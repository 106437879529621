import React from "react";
import "./style.css";
import background from '../../../assets/12628436_5026741 1.webp'

const Content = () => {
    return (
        <div className="header px-4 height-fit px-5" style={{ maxWidth: "1440px", margin: "auto" }}>
           <img className="element" alt="Element" src={background} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
            <div className="title my-auto py-5">
                <p className="improve-your-content px-3 text-break">
                    <span className="text-wrapper">
                        Improve your content with CreatorStride <br />
                    </span>
                    <span className="span">and earn amazing income</span>
                </p>
                <p className="discover-creative px-5">
                    Discover creative potential for aspiring and established content creators. Our smart questionnaire offers{" "}
                    <br />
                    personalized content ideas, guiding new creators, while providing insights to keep established influencers
                    ahead. Engage your audience with automated comment responses and track growth-driving trends.
                </p>
            </div>
        </div>
    );
};

export default Content