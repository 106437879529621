import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import faq from "../../assets/faq.png";
import { Diversity1 } from "@mui/icons-material";
import "./Faq.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import FaqDropDown from "./FaqDropdown";

const Quiz = () => {
  const navigate = useNavigate();
  const faq_questions = [
    {
      question: "Why should I trust CreatorStride?",
      answer:
        "CreatorStride values transparency, reliability, and innovation. We prioritize our users' needs, delivering top-notch service and quality. With a commitment to excellence, we aim to build trust through our actions and dedication to customer satisfaction.",
    },
    {
      question: "How secure is my data on CreatorStride?",
      answer:
        "At CreatorStride, we take data security seriously. We employ robust measures to protect your information, including encryption protocols, regular security audits, and adherence to industry best practices. Your data's safety and confidentiality are paramount to us.",
    },
    {
      question: "Why should I trust CreatorStride?",
      answer:
        "CreatorStride values transparency, reliability, and innovation. We prioritize our users' needs, delivering top-notch service and quality. With a commitment to excellence, we aim to build trust through our actions and dedication to customer satisfaction.",
    },
    {
      question: "How secure is my data on CreatorStride?",
      answer:
        "At CreatorStride, we take data security seriously. We employ robust measures to protect your information, including encryption protocols, regular security audits, and adherence to industry best practices. Your data's safety and confidentiality are paramount to us.",
    },
    {
      question: "Why should I trust CreatorStride?",
      answer:
        "CreatorStride values transparency, reliability, and innovation. We prioritize our users' needs, delivering top-notch service and quality. With a commitment to excellence, we aim to build trust through our actions and dedication to customer satisfaction.",
    },
    {
      question: "How secure is my data on CreatorStride?",
      answer:
        "At CreatorStride, we take data security seriously. We employ robust measures to protect your information, including encryption protocols, regular security audits, and adherence to industry best practices. Your data's safety and confidentiality are paramount to us.",
    },
    {
      question: "Why should I trust CreatorStride?",
      answer:
        "CreatorStride values transparency, reliability, and innovation. We prioritize our users' needs, delivering top-notch service and quality. With a commitment to excellence, we aim to build trust through our actions and dedication to customer satisfaction.",
    },
  ];

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(255, 245, 235)",
          height:'100%'
        }}
      >
        <Box
          className="px-4 px-sm-5 py-4 py-sm-5"
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "fit-content",
            borderBottomLeftRadius: "50%",
            position: "absolute",
            right: "0",
          }}
        ></Box>
        
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="d-flex flex-column justify-content-between h-100">
            <Box
              class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5"
            >
              <Box className="contact-nav">
                <img src={logo} alt="logo" height="30px" width="auto" onClick={()=>navigate('/')}/>
                <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </Box>
              </Box>
            </Box>
            <Box
              className="d-flex flex-xl-row flex-column align-items-start justify-content-between px-sm-5 px-4 py-3 mx-auto gap-5"
            >
              <Box className='col col-11 col-sm-10 col-md-7 col-lg-7 col-xl-5 mx-auto px-3'>
                <img src={faq} alt="faq" width="100%" height="auto" />
              </Box>
              <Box class="h-100 mx-auto">
                <Typography variant="h4">
                  Frequently Asked Questions:
                </Typography>
                <Box className='my-3 h-100 mx-auto mx-lg-0'>
                  {faq_questions.map((ele, index) => (
                    <Box className="my-2">
                      <FaqDropDown
                        question={ele.question}
                        answer={ele.answer}
                      />
                    </Box>
                  ))}
                </Box>

                {/* <Button
                  style={{
                    backgroundColor: "var(--collection-1-primary-main)",
                    color: "white",
                    width: "100%",
                  }}
                >
                  Submit
                </Button> */}
                {/* <Box className="py-3">
                  <Typography></Typography
                </Box> */}
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-between px-5"
            >
              <Box
                className="d-flex justify-content-between"
                style={{ height: "auto" }}
              ></Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 align-items-center faq-footer py-5 py-md-4 text-center"
              style={{
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Quiz;
