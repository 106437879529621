import React from 'react';
import './LoadingOverlay.css'; // Import your CSS file for styling
import { Typography } from '@mui/material'

const LoadingOverlay = ({text}) => {
  return (
    <div className="loading-spinner loading-overlay d-flex flex-column">
      {text && <Typography className='text-light' style={{zIndex:'999'}}>{text}</Typography>}
      <div className="loader-spin-container">
        <div className="loader-spin"></div>
      </div>
    </div>
  );
};

LoadingOverlay.defaultProps={
  text:null
}

export default LoadingOverlay;
