import React from "react";
import "./style.css";
import icon from '../../../assets/icon .svg'
import frame from '../../../assets/frame.svg'
import note from '../../../assets/Layer_1.svg'
import {useState,useEffect,useRef} from 'react'

const PersonalizedContent = () => {
    const featureRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const div1 = document.getElementById("feature-movement1")
                    const div2 = document.getElementById("feature-movement2")
                    const div3 = document.getElementById("feature-movement3")
                    if (div1 && div2 && div3) {
                        if (entry.isIntersecting) {
                            console.log('Element is in the viewport!');
                            div1.classList.add("feature-movement")
                            div1.style.right = "0px";
                            div2.style.right = "0px";
                            div3.style.right = "0px";
                            div2.classList.add("feature-movement")
                            div3.classList.add("feature-movement")
                        } else {
                            console.log('Element is out of the viewport!')
                            div1.classList.remove("feature-movement")
                            div2.classList.remove("feature-movement")
                            div3.classList.remove("feature-movement")
                        }
                    }
                });
            },
            {
                root: null, // Use the viewport as the root
                rootMargin: '0px', // No margin
                threshold: 0.5, // 0 (element is completely out of view) to 1 (element is completely in view)
            }
        );

        if (featureRef.current) {
            observer.observe(featureRef.current);
        }

        return () => {
            if (featureRef.current) {
                observer.unobserve(featureRef.current);
            }
        };
    }, [featureRef]);
    return (
        <div className="personalized-content gap-3 px-3 px-md-5" style={{ maxWidth: "1440px", margin: "auto" }}>
            <div className="px-3 px-md-5">
                <div className="title">
                    <div className="text-wrapper">The features we offer</div>
                    <div className="div">Personalized Content Discovery</div>
                    <p className="p">Customized exploration of content tailored to your preferences.</p>
                </div>
                <div className="w-100 d-flex justify-content-around gap-4 flex-lg-row flex-column py-3">
                    <div className="div-2 shadow" id="feature-movement1">
                        <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                            <img className="img" alt="Content" src={icon} width="25" height="25"/>
                        </div>
                        <div className="content-2">
                            <div className="text-wrapper-3 colored">Appropriate Recommendations</div>
                            <p className="p">Get the best advice tailored to your interests and skills</p>
                        </div>
                    </div>
                    <div className="data-driven shadow" id="feature-movement2">
                        <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                            <img className="img" alt="Content" src={frame} width="25" height="25"/>
                        </div>
                        <div className="content-2">
                            <div className="text-wrapper-3 colored">Recommendations From Ai</div>
                            <p className="p">AI-generated recommendations for your next video topic</p>
                        </div>
                    </div>
                    <div className="div-2 shadow" id="feature-movement3">
                        <div className="">
                            <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                                <img className="img" alt="Content" src={note} width="25" height="25"/>
                            </div>
                        </div>
                        <div className="content-2">
                            <div className="text-wrapper-3 colored">Tools That Help Create Scripts</div>
                            <p className="p">Effortless script creation tools to jumpstart your video production process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalizedContent