import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './SignIn.css'
import google from '../../assets/google.png'
import {useState,useEffect} from 'react'
import view from '../../assets/view.png'
import hide from '../../assets/hide.png'
import {Link, useNavigation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import Cookies from 'js-cookie';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import axios from 'axios';
import CryptoJS from 'crypto-js';



function SignInForm(){
    console.log(process.env.REACT_APP_SERVER_URL)
  const [username,setUsername]=useState()
  const [password,setPassword]=useState()
  const [showPassword,setShowPassword] = useState()
  const [removeSessionStorage,setRemoveSessionStorage]=useState(false)
  const [inputType,setInputType] = useState('password')
  const navigate =  useNavigate()
  const [user, setUser] = useState([]);
  const [profile,setProfile] = useState()
  const sessionId_=Cookies.get("session_id")


  const hashString = (password) => {
    // Hash the string using SHA-256
    const hashedString = CryptoJS.SHA256(password).toString();
    return hashedString;
  };


  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {setUser(codeResponse)
      console.log(codeResponse)
    },
    onError: (error) => console.log("Login Failed:", error)
  });


  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res)
          const userData=res.data
          SignInSubmitHandler(userData)
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  

   const nameChanger = event =>{
    setUsername(event.target.value)
   }

   const passwordChanger = event =>{
     setPassword(event.target.value)
   }


  const responseGoogle = (response) => {
    console.log(response);
  }

   const changeInputType=()=>{
    let inputButton=document.getElementById("passwordInput")
    let input_type=inputButton.getAttribute("type")
    let viewerButton=document.getElementById("viewerButton")
    if(input_type=="password"){
        setInputType("text")
        viewerButton.innerText='Hide'
    }else{
        setInputType("password")
        viewerButton.innerText='Show'
    }
   }

  
const formSubmitHandler=(event)=>{
  event.preventDefault()
        const data={
          username:username,
          password:password,
          email:null,
          id:null
        }
    SignInSubmitHandler(data)
} 



 const setCustomExpiryCookie=(name, value, expirationDate)=>{
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
}

   const SignInSubmitHandler= async (userData)=>{
        // event.preventDefault()
        let server_url=process.env.REACT_APP_SERVER_URL
        let payload={
            username:userData.email ? userData.email : userData.username,
            password:userData.id ? hashString(userData.id) : hashString(userData.password)
        }
        try{
            const response= await fetch(`${server_url}/userValidation`,{
                method:"POST",
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(payload)
            })
            if(!response.ok){
               throw new Error(`Network response was not ok:${response.status}`)
            }
            const data = await response.json();
            if(data.status === "success"){
              console.log("validation successfull");
              console.log("The responseData from validation is : ", data);
              // console.log(data)
              toast.success("Login Successfull", {
                position: toast.POSITION.TOP_CENTER,
              });
              Cookies.set("isLoggedIn", true);
              Cookies.set("user_id", data.user_id);
              alert(sessionId_)
              window.postMessage(
                { action: "messageFromWebPage", data: sessionId_ },
                "*"
              );
              navigate("/dashboard");
            }else if(data.status === "failed"){
            console.log("validation unsuccessfull")
            console.log(data)
            toast.error("Login failed",{position:toast.POSITION.TOP_CENTER})
            }else if(data.status === "error"){
                console.log(`${data.message}`)
                console.log(data)
                // toast.error("Something went wrong !!",{position:toast.POSITION.TOP_CENTER})
            }else if(data.status === "ACCESS_DENIED"){
              toast.error("User Access Denied !!",{position:toast.POSITION.TOP_CENTER})
            }

        }catch(error){
            console.error("Error :",error)
            return "Error"
        }
   }
   

  

    return(
        <form class="d-flex align-items-center justify-content-center body-style" onSubmit={formSubmitHandler}>
            <div class="col bg-white col-xs-12 col-sm-9 col-md-7 col-lg-5 col-xl-4 col-xxl-3 d-flex justify-content-center custom-shadow">
               <div class="py-2 mx-auto">
                <div class="py-3">
                    <div class="logo-circle">
                    </div>
                </div>
                <div class="py-2 px-3"><h4 class="text-center">Hello !!</h4></div>
                <div class="py-3 px-3"><input class="border-0 bg-grey py-2 br-20px px-3 w-100" type="text" placeholder="Username" onChange={nameChanger} required/></div>
                <div class="py-2 px-2 d-flex bg-grey br-20px mx-auto w-fc my-2 justify-content-evenly">
                    <input class="border-0 bg-grey br-20px w-75 bg-transparent px-1" type={inputType} placeholder="Password" id="passwordInput" onChange={passwordChanger} required/>
                    <button class="bg-transparent border-0 pl-0 pr-0 opacity-50" id="viewerButton" onClick={changeInputType} type="button">Show</button>
                </div>
                <div className='text-center py-2'>
                  <Link to='/changePassword' className='blue-link'>Forgot password?</Link>
                </div>
                <div class="py-3 px-3 text-center"><button class="s-in-btn-b w-75" type="submit">Sign In</button></div>
                <div class="py-3 px-3 text-center">
                    <button class="s-in-g-button w-100 px-4 py-2" onClick={() => login()}>Sign in with Google<img src={google} class="g-img" alt='google icon'></img></button>
                </div>
                <div class="py-4 px-3">
                    <p>Don't have an account.<span class="col-blue"> <Link to="/signUpProfile" className='blue-link'>Register here.</Link></span></p>
                </div>
               </div>
            </div>
        </form>
    )
}


export default SignInForm