import React, { useState, useEffect, useRef } from "react";
import {
  NavLink,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Login from "../login/Login";
import Register from "../register/Register";
import JoinNow from "../joinNow/JoinNow";
import Logo from "../../../assets/Logo.png";
import logo from "../../..//assets/quiz_header.png";
import "./style.css";
import Logo_white from "../../../assets/Creator Stride-white.png";
import LoadingOverlay from "../../dashboard/LoadingOverlay";
import Cookies from "js-cookie";
import user from "../../../assets/user.png";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Popover, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExtensionIcon from "@mui/icons-material/Extension";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import QuestionForm from "../../forms/QuestionForm";
import { reduxLogoutHandler } from "../../../redux/actions/actions";
import { Box } from "@mui/material";
import { UseSelector } from "react-redux";
import { updateReduxUsername } from "../../../redux/actions/actions";

const Header = ({
  showLoginModal,
  showRegisterModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
  handleCloseJoinNowClick,
  JoinNowClick,
}) => {
  const dispatch = useDispatch();
  const reduxUserName = useSelector((state) => state.reduxUserName);
  const pricingRef = useRef();
  const session_id = Cookies.get("session_id");
  const [showJoinNowModal, setShowJoinNowModal] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [questionFormVisible, setQuestionFormVisible] = useState(false);
  const [isQuestionnaire, setSsQuestionnaire] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [answers, setAnswers] = useState([]);
  const [questionnaireEmail, setQuestionnaireEmail] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLogin_, setIsLogin_] = useState(null);

  const questions = [];

  useEffect(() => {
    const isLogin__ = searchParams.get("isLogin");
    console.log("Is login __ ", isLogin__);
    console.log(Cookies.get("isLoggedIn"));
    console.log(typeof Cookies.get("isLoggedIn"));
    if (isLogin__) {
      setIsLogin_(isLogin__);
    }
    console.log("IN the settimeout");
    console.log("islogin ");
    if (
      isLogin_ &&
      isLogin_ === "True" &&
      isLogin
    ) {
      handleLoginClick();
    }
  }, [isLogin, isLogin_]);

  useEffect(() => {
    const hasQuestionnaire = searchParams.get("isQuestionnaire");
    const email = searchParams.get("email");
    if (hasQuestionnaire && email) {
      setQuestionFormVisible(true);
      setQuestionnaireEmail(email);
    }
  }, []);

  const handleHeaderDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHeaderDropDownClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyle = {
    width: "200px",
  };

  useEffect(() => {
    const isLoggedInValue = Cookies.get("isLoggedIn");

    if (isLoggedInValue === "true") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      Cookies.get("isLoggedIn", false);
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  function navBarShower() {
    toggleMenu();
  }

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const HeaderStyle = {
    backgroundColor: isScrolled ? "white" : "white",
    position: "sticky",
    top: "0",
    zIndex: "1000",
  };

  const loginButtonStyle = {
    color: isScrolled
      ? "var(--collection-1-primary-main)"
      : "var(--collection-1-primary-main)",
    fontSize: "18px",
    color: "black",
  };

  const RegisterButtonStyle = {
    backgroundColor: isScrolled
      ? "var(--collection-1-primary-main)"
      : "var(--collection-1-primary-main)",
    // border:isScrolled ? 'white 2px solid' : '',
    color: "white",
    fontSize: "18px",
  };

  const navBar = {
    color: isScrolled ? "black" : "black",
  };

  const logoStyle = {
    color: isScrolled ? "black" : "black",
  };

  const extensionHandler = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_URL}/downloadExtension`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "Extension.zip";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      })
      .catch((error) => console.error("Error:", error));
  };

  const boxStyle = {
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  const handleRegisterDrawer = () => {
    handleRegisterClick();
    closeMenu();
  };

  const handleLoginDrawer = () => {
    handleLoginClick();
    closeMenu();
  };

  const HandleLogout = () => {
    Cookies.remove("session_id");
    Cookies.set("isLoggedIn", false);
    setIsLoggedIn(false);
    navigate("/");
    // useDispatch(reduxLogoutHandler())
  };

  const whiteIconStyle = {
    color: "white",
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 800) {
        closeMenu();
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleJoinNow = () => {
    console.log("Handle join now");
    JoinNowClick();
  };

  const handlePricingScroller = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById("pricing");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1000);
    } else {
      setTimeout(() => {
        const element = document.getElementById("pricing");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 200);
    }
  };

  useEffect(() => {
    if (questionFormVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [questionFormVisible]);

  useEffect(() => {
    console.log("The reduxUserName from useEffect is : ", reduxUserName);
    if (!reduxUserName) {
      const payload = {
        session_id,
      };
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserProfileDetails`, {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response data from fetch user profile details : ", data);
          if (data.status === "success") {
            dispatch(updateReduxUsername(data.username)); // Dispatch the action here
          }
        });
    }
  }, [session_id, reduxUserName, dispatch]);

  return (
    <>
      <div
        className="navigation col-xl-12 col-lg-12 col-md-12 px-md-5 py-md-3 px-4 py-3 d-flex justify-content-between"
        style={HeaderStyle}
      >
        {/* <div className="background" /> */}
        <div
          className="logo d-flex cursor-pointer"
          style={{ position: "relative" }}
          onClick={() => navigate("/")}
        >
          {/* <img
            className="img"
            alt="Logo"
            width="33"
            height="39"
            src={logo}
            style={{ width: "33px", height: "39px" }}
          /> */}
          <div className="title px-1 px-md-3">
            <div className="text-wrapper-2" style={logoStyle}>
              <Box className="d-flex justify-content-between align-items-center gap-3">
                <img src={logo} alt="logo" width="200px" height="auto" />
                {/* {reduxUserName} */}
              </Box>
            </div>
            <p
              className="div d-none d-xl-block text-wrapper-3 m-0 py-1"
              style={logoStyle}
            >
              Your Personalized Path to Youtube Success
            </p>
          </div>
        </div>
        <div onClick={navBarShower}>
          <div class="d-block d-lg-none menu-icon-dash">
            <MenuIcon />
          </div>
        </div>
        <div className="contain d-none d-lg-flex text-wrapper">
          <div className="link">
            <div className="cursor-pointer">
              <NavLink
                to="/"
                className="text-decoration-none"
                style={{
                  color:
                    location.pathname === "/"
                      ? "var(--collection-1-primary-main)"
                      : "black",
                }}
              >
                Home
              </NavLink>
            </div>
            <div className="cursor-pointer text-wrapper">
              <NavLink
                to="/features"
                className="text-decoration-none"
                style={{
                  color:
                    location.pathname === "/features"
                      ? "var(--collection-1-primary-main)"
                      : "black",
                }}
              >
                Feature
              </NavLink>
            </div>
            <div
              className="cursor-pointer text-wrapper"
              style={{
                color:
                  location.pathname === "/#pricing"
                    ? "var(--collection-1-primary-main)"
                    : "black",
              }}
              onClick={handlePricingScroller}
            >
              Pricing
            </div>
            {/* <div className="cursor-pointer text-wrapper">
              <NavLink
                to="/internship"
                className="text-decoration-none"
                style={{
                  color:
                    location.pathname === "/internship"
                      ? "var(--collection-1-primary-main)"
                      : "black",
                }}
              >
                Internship
              </NavLink>
            </div> */}
          </div>
          <div className="d-flex justify-content-between">
            <div
              className={`login px-2.5 py-2.5 cursor-pointer d-block custom-hover ${
                isLoggedIn ? "d-none" : "d-block"
              }`}
              onClick={handleLoginClick}
            >
              <div
                className="text-wrapper"
                style={loginButtonStyle}
                style={{ fontSize: "12px" }}
              >
                Login
              </div>
            </div>
            {/* <div className="login px-5 py-2 mx-2 cursor-pointer" onClick={extensionHandler}>
              <div className="text-wrapper" style={loginButtonStyle}>Extension</div>
            </div> */}
            <div
              className={`register px-2.5 py-2.5 rounded mx-4 cursor-pointer custom-hover align-items-center ${
                isLoggedIn ? "d-none" : "d-flex"
              }`}
              style={RegisterButtonStyle}
              onClick={() => setShowJoinNowModal(true)}
            >
              <div
                className="cursor-pointer text-wrapper mx-0 my-0"
                style={{ fontSize: "12px" }}
              >
                Join Now
              </div>
            </div>
            <div
              className={`px-5 px-md-3 ${isLoggedIn ? "d-block" : "d-none"}`}
            >
              <Box
                className="d-flex justify-content-between gap-3 align-items-center cursor-pointer"
                onClick={handleHeaderDropDownClick}
              >
                <img
                  src={user}
                  className="w-35px cursor-pointer"
                  alt="User"
                  style={boxStyle}
                />
                {reduxUserName}
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleHeaderDropDownClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleHeaderDropDownClose();
                    navigate("/dashboard");
                  }}
                  style={menuItemStyle}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    handleHeaderDropDownClose();
                    navigate("/playground");
                  }}
                  style={menuItemStyle}
                >
                  <ListItemIcon>
                    <VideogameAssetIcon />
                  </ListItemIcon>
                  <ListItemText primary="Playground" />
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleHeaderDropDownClose();
                    extensionHandler();
                  }}
                  style={menuItemStyle}
                >
                  <ListItemIcon>
                    <ExtensionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Extension" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleHeaderDropDownClose();
                    HandleLogout();
                  }}
                  style={menuItemStyle}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        {showLoginModal && (
          <Login
            showJoinNowModal={showJoinNowModal}
            showLoginModal={showLoginModal}
            showRegisterModal={showRegisterModal}
            handleLoginClick={handleLoginClick}
            handleRegisterClick={handleRegisterClick}
            handleCloseLoginModal={handleCloseLoginModal}
            handleCloseJoinNowClick={handleCloseJoinNowClick}
            handleCloseRegisterModal={handleCloseRegisterModal}
            setIsLogin={setIsLogin}
          />
        )}
        {showRegisterModal && (
          <Register
            showJoinNowModal={showJoinNowModal}
            showLoginModal={showLoginModal}
            showRegisterModal={showRegisterModal}
            handleLoginClick={handleLoginClick}
            handleRegisterClick={handleRegisterClick}
            handleCloseLoginModal={handleCloseLoginModal}
            handleCloseJoinNowClick={handleCloseJoinNowClick}
            handleCloseRegisterModal={handleCloseRegisterModal}
          />
        )}
        {showJoinNowModal && (
          <JoinNow
            setShowJoinNowModal={setShowJoinNowModal}
            showJoinNowModal={showJoinNowModal}
            showLoginModal={showLoginModal}
            showRegisterModal={showRegisterModal}
            handleLoginClick={handleLoginClick}
            handleRegisterClick={handleRegisterClick}
            handleCloseLoginModal={handleCloseLoginModal}
            handleCloseJoinNowClick={handleCloseJoinNowClick}
            handleCloseRegisterModal={handleCloseRegisterModal}
          />
        )}
      </div>
      <div class="overlay">
        <div className="link d-flex flex-column gap-3 text-center ">
          <div
            className="px-5 py-2 cursor-pointer text-center border mx-4 rounded d-block"
            style={{ backgroundColor: "white" }}
            onClick={() => navigate("/")}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Home
            </div>
          </div>
          <div
            className="px-5 py-2 cursor-pointer text-center border mx-4 rounded d-block"
            style={{ backgroundColor: "white" }}
            onClick={() => navigate("/features")}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Features
            </div>
          </div>
          <div
            className="px-5 py-2 cursor-pointer text-center border mx-4 rounded d-block"
            style={{ backgroundColor: "white" }}
            onClick={() => handlePricingScroller()}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Pricing
            </div>
          </div>
          <div
            className="px-5 py-2 cursor-pointer text-center border mx-4 rounded d-block"
            style={{ backgroundColor: "white" }}
            onClick={() => handlePricingScroller()}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Pricing
            </div>
          </div>
          <div
            className={`px-5 py-2 cursor-pointer text-center border mx-4 rounded ${
              !isLoggedIn ? "d-none" : "d-block"
            }`}
            style={{ backgroundColor: "white" }}
            onClick={handleLoginClick}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Login
            </div>
          </div>
          <div
            className="px-5 py-2 cursor-pointer text-center border mx-4 rounded"
            style={{ backgroundColor: "white" }}
            onClick={extensionHandler}
          >
            <div
              className="text-wrapper text-center"
              style={{ fontSize: "18px" }}
            >
              Extension
            </div>
          </div>
          <div
            className={`px-5 py-2 rounded mx-4 cursor-pointer text-center ${
              !isLoggedIn ? "d-none" : "d-block"
            }`}
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              fontSize: "18px",
              color: "white",
            }}
            onClick={handleRegisterClick}
          >
            <div className="text-center">Register</div>
          </div>
          <div
            className={`px-5 py-2 rounded mx-4 cursor-pointer text-center ${
              !isLoading ? "d-block" : "d-none"
            }`}
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              fontSize: "18px",
              color: "white",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <div className="text-center">Dashboard</div>
          </div>
        </div>
      </div>
      <div className="responsive-nav d-none">
        <Drawer
          open={isMenuOpen}
          onClose={closeMenu}
          anchor="left"
          PaperProps={{
            sx: { width: "70%" },
          }}
        >
          <List style={{ padding: "30px" }}>
            <ListItem style={{ padding: "30px 0px" }}>
              <Box className="d-flex flex-column justify-content-center text-center gap-3 w-100">
                <Avatar alt="Logo" src={user} style={{ margin: "auto" }} />

                <Typography>{reduxUserName}</Typography>
              </Box>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/");
                closeMenu();
              }}
              style={{
                color:
                  location.pathname === "/"
                    ? "var(--collection-1-primary-main)"
                    : "black",
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/features");
                closeMenu();
              }}
              style={{
                color:
                  location.pathname === "/features"
                    ? "var(--collection-1-primary-main)"
                    : "black",
              }}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Features" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                handlePricingScroller();
                closeMenu();
              }}
              style={{
                color:
                  location.pathname === "/pricing"
                    ? "var(--collection-1-primary-main)"
                    : "black",
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Pricing" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                extensionHandler();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <ExtensionIcon />
              </ListItemIcon>
              <ListItemText primary="Extension" />
            </ListItem>
            {/* {!session_id ? (<ListItem
              button
              onClick={handleRegisterDrawer}
              className={session_id ? 'd-none' : ''}
              style={{ backgroundColor: 'var(--collection-1-primary-main)', color: 'white' }}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Register" />
            </ListItem>) : (
              <ListItem button
                onClick={handleLoginDrawer}
                className={session_id ? 'd-none' : ''}
                style={{ backgroundColor: 'var(--collection-1-primary-main)', color: 'white' }}
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>)} */}
            <ListItem
              button
              onClick={() => {
                navigate("/dashboard");
                closeMenu();
              }}
              className={isLoggedIn ? "" : "d-none"}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              onClick={handleLoginDrawer}
              className={isLoggedIn ? "d-none" : ""}
            >
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
            {/* <ListItem button
                onClick={()=>navigate('/internship')}
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Internship" />
              </ListItem> */}
          </List>
          <Divider />
          {isLoggedIn ? (
            <List
              style={{
                marginTop: "auto",
                backgroundColor: "var(--collection-1-primary-main)",
                color: "white",
              }}
              onClick={HandleLogout}
            >
              <ListItem button>
                <IconButton>
                  <ExitToAppIcon style={whiteIconStyle} />
                </IconButton>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          ) : null}
        </Drawer>
      </div>
      {isLoading && <LoadingOverlay />}
      {questionFormVisible && (
        <QuestionForm
          email={questionnaireEmail}
          setQuestionFormVisible={setQuestionFormVisible}
          questions={questions}
          answers={answers}
          setAnswers={setAnswers}
        />
      )}
    </>
  );
};

export default Header;
