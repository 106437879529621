// Login.js

import React, { useEffect, useState } from "react";
import "./style.css";
import google from "../../../assets/Google.svg";
import apple from "../../../assets/Apple.svg";
import eye from "../../../assets/Eye.svg";
import line from "../../../assets/Line.png";
import hideEye from "../../../assets/invisible.png";
import logo from "../../../assets/quiz_header.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import close from "../../../assets/delete.png";
import join_now from "../../../assets/join_now1.webp";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import QuestionForm from "../../forms/QuestionForm";
import { Box, Typography } from "@mui/material";
import { Helmet } from 'react-helmet';



const JoinNow = ({
  showLoginModal,
  showRegisterModal,
  setShowJoinNowModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
  handleCloseJoinNowClick,
  handleJoinNowClick,
  showJoinNowModal,
}) => {
  const [emailId, setEmailId] = useState("");
  const [checked, setChecked] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState("");
  const [alreadyVerified,setAlreadyVerified] = useState(false)


  useEffect(()=>{
    const script=document.createElement("script")
    script.innerHTML=`gtag('event', 'conversion', {'send_to': 'AW-11418588526/yWSFCO-87JQZEO6q58Qq'});`
    document.head.appendChild(script)

    return ()=>{
        document.head.removeChild(script)
    }
 },[])


  useEffect(() => {
    const body = document.querySelector('body');
  
    if (!showJoinNowModal || !showRegisterModal || !showLoginModal) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }
    return () => {
      body.style.overflow = 'visible';
    };
  }, [showJoinNowModal, showLoginModal, showRegisterModal]);
  

  const handleChange = (event) => {
    if (emailId) {
      setChecked(event.target.checked);
    }
  };

  const emailHandler = (event) => {
    if(messageBoxData) setMessageBoxData("")
    const email = event.target.value;
    setEmailId(email);
  };

  const FormSubmitHandler = async () => {
    if(messageBoxData) setMessageBoxData("")
    if (!emailId) {
      return;
    }
    const payload = {
      emailId,
      checked,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addWaitlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      } else {
        const responseData = await response.json();
        if (
          responseData.status == "success" &&
          responseData.message == "Already verified"
        ) {
          setMessageBoxData(responseData.message);
          setAlreadyVerified(true)
          setSentEmail(false)
        } else {
          setMessageBoxData("Email Sent");
          setSentEmail(true);
          setAlreadyVerified(false)
        }
      }
    } catch (error) {
      console.error("Error:", error);
      return "Error";
    }
  };

  // useEffect(()=>{
  //    return ()=>clearTimeout(emailTimeout)
  // },[])

  const buttonOpacity = {
    opacity: checked ? "1" : "0.5",
    PointerEvent: checked ? "" : "none",
  };

  return (
    <div className="modal-overlay">
      {!sentEmail ? (
        <div className="join-now-container d-flex justify-content-between flex-md-row flex-column flex-column-reverse">
          <div className="join-now text-container px-3 px-md-3 py-3 py-md-0 mx-0 mx-md-4">
            <div
              className="cross cursor-pointer"
              onClick={() => setShowJoinNowModal(false)}
            >
              <img src={close} alt="close icon" />
            </div>
            <div className="text-wrapper-12">
              "Generate Content Instantly, Track Your Progress Consistently."
            </div>
            <p className="let-s-build-your text-center text-wrapper-11 my-4">
              Join Over 1K Professionals Who Use CreatorStride and Becoming
              Creators....
            </p>
            <input
              className="input-2 w-100 shadow-sm"
              placeholder="Enter Email"
              type="email"
              onChange={emailHandler}
            />
            <div className="d-flex align-items-center my-2">
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Subscribe to Email Updates"
              />
            </div>
            <button
              className={`shadow p-0 py-3 my-3 ${
                checked
                  ? "text-wrapper-13 join-now-register-checked"
                  : "text-wrapper-17 join-now-register"
              }`}
              style={buttonOpacity}
              disabled={!checked}
              onClick={FormSubmitHandler}
            >
              Join Now
            </button>
            {messageBoxData && (
              <p
                className="text-end h6 dropping-div"
                style={{ fontSize: "var(--footer-div)" }}
              >
                {messageBoxData}
              </p>
            )}
          </div>
          <div className="gradient-div">
            <img class="gradient-img" src={join_now} alt="Image" />
          </div>
        </div>
      ) : (
        <div className="bg-white col col-11 col-md-5 d-flex justify-content-center position-relative px-5 py-5 rounded">
          <div className="w-100">
            <div
              className="cross cursor-pointer"
              onClick={() => setShowJoinNowModal(false)}
            >
              <img src={close} alt="close icon" />
            </div>
            <Typography variant="h4" className="pb-3 text-center">"Great! You're All Set to Start."</Typography>
            <p className="py-3 text-center">
              Check your inbox for a confirmation email to complete your signup
              and dive into Creator Stride. You're just a click away from
              transforming your YouTube journey
            </p>
            <Box className="text-center">
              <img src={logo} width="200px" height="auto" />
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinNow;
