import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import UploadWork from "./UploadWork";
import { useState, useEffect } from "react";
import { LuUpload } from "react-icons/lu";
import { Box, Typography, Button } from "@mui/material";

const UploadButton = ({ topic }) => {
  const [isUploadButtonClicked, setIsUploadButtonClicked] = useState(false);
  const handleUpload = () => {
    setIsUploadButtonClicked(true);
  };

  return (
    <>
        <div>
          {/* <LuUpload onClick={handleUpload} style={{fontSize:'20px'}}/> */}
          <Button
            style={{
              color: "white",
              backgroundColor: "var(--collection-1-primary-main)",
              height: "fit-content",
              border: "2px solid var(--collection-1-primary-main)",
              fontSize: "var(--p-too-tiny)",
            }}
            className="my-1 mx-1 px-3 py-2 rounded shadow"
            onClick={() => {
              handleUpload();
            }}
          >
            Upload link
          </Button>
        </div>
      {isUploadButtonClicked && (
        <UploadWork
          topic={topic}
          isUploadButtonClicked={isUploadButtonClicked}
          setIsUploadButtonClicked={setIsUploadButtonClicked}
        />
      )}
    </>
  );
};

export default UploadButton;
