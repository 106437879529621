import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Diversity1 } from "@mui/icons-material";
import "./Blog.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import RecipeReviewCard from "./BlogCard";
import FeaturedBlogCard from "./FeaturedBlogCard";
import FlatBlogCard from "./FlatBlogCard";
import BlogRequest from "./BlogRequest";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Quiz = () => {
  let { id } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [blogSliderRange, setBlogSliderRange] = useState(0);
  const blogCount = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      id,
    };
    fetch(`${process.env.REACT_APP_SERVER_URL}/fetchBlogData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === "success") {
          setBlogData(responseData.results);
        }
      });
  }, []);

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(255, 245, 235)",
        }}
      >
        <Box
          className="px-4 px-sm-5 py-4 py-sm-5"
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "fit-content",
            borderBottomLeftRadius: "50%",
            position: "absolute",
            right: "0",
          }}
        ></Box>
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="d-flex flex-column justify-content-between vh-100">
            <Box class="d-flex align-items-center justify-content-between  px-md-5 px-3 pt-5">
              <Box className="blog-nav">
                <img src={logo} alt="logo" height="30px" width="auto" onClick={()=>navigate('/')}/>
                <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/features">Features</NavLink>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                  <NavLink to="/faq">FAQ</NavLink>
                </Box>
              </Box>
            </Box>
            <Box className="px-2 px-md-5 py-5 mx-auto d-flex align-items-stretch flex-column flex-lg-row gap-4 max-1440">
              <Box class="d-flex flex-column" style={{ width: "100%" }}>
                <Box>
                  <Typography class="py-2" variant="h3">
                    <u>Blog/Articles.</u>
                  </Typography>
                  {blogData.slice(2, 3).map((blog, index) => (
                    <FeaturedBlogCard
                      imageUrl={blog.cover_image_url}
                      title={blog.title}
                    />
                  ))}
                </Box>
                <Box className="d-flex flex-column justify-content-between">
                  <Box class="d-flex flex-wrap gap-2 py-3 mx-auto justify-content-center">
                    {blogData
                      .slice(
                        blogData.length - blogSliderRange - blogCount,
                        blogData.length - blogSliderRange
                      )
                      .map((blog, index) => (
                        // <RecipeReviewCard
                        //   className="shadow-lg"
                        //   title={blog.title}
                        //   content={blog.content}
                        //   imageUrl={blog.cover_image_url}
                        //   postedOn={blog.posted_on}
                        //   likes={blog.likes}
                        // />
                        <>
                          <FlatBlogCard
                            title={blog.title}
                            imageUrl={blog.cover_image_url}
                            postedOn={blog.posted_on}
                            content={blog.content}
                          />
                        </>
                      ))}
                  </Box>
                  <Box className="d-flex justify-content-between w-100">
                    <Box>
                      <Button
                        className={`${
                          blogData.length - blogSliderRange - blogCount <= 0
                            ? "d-none"
                            : "d-block"
                        }`}
                        style={{ color: "black" }}
                        onClick={() => setBlogSliderRange((prev) => prev + 2)}
                      >
                        {" "}
                        <ArrowBackIcon /> Back
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className={`${
                          blogData.length - blogSliderRange === blogData.length
                            ? "d-none"
                            : "d-block"
                        }`}
                        style={{ color: "black" }}
                        onClick={() => setBlogSliderRange((prev) => prev - 2)}
                      >
                        More <ArrowForwardIcon />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box class="">
                <BlogRequest blogData={blogData} />
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 py-5 py-sm-4 align-items-center"
              style={{
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Quiz;
