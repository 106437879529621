import { Box, Typography, Button } from "@mui/material";
import { ReloadOutlined } from "@ant-design/icons";

const Regenerate = ({action}) => {
  const boxStyle = {
    backgroundColor: "var(--collection-1-primary-main)",
    display: "flex",
    justifyContent: "betweeen",
    alignItems: "center",
    color: "white",
    width: "fit-content",
  };


  const handleButtonClick=()=>{
    if (typeof action === "function") {
      action();
    }
  }

  return (
    <Box
      className="d-flex justify-content-between align-items-center cursor-pointer rounded shadow"
      style={{
        width: "fit-content",
        border: "2px solid var(--collection-1-primary-main)",
      }}
    >
      <Button className="p-0" onClick={handleButtonClick}>
        <Box style={boxStyle} className="px-3 py-2">
          <Typography>Regenerate</Typography>
        </Box>
        <Box className="px-2 d-flex justify-content-betweeen">
          <ReloadOutlined />
        </Box>
      </Button>
    </Box>
  );
};

export default Regenerate;
