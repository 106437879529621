import React from "react";
import user from "../../assets/user.png";
import pieChart from "../../assets/pie-chart.png";
import home from "../../assets/home-button.png";
import "./SiteHeader.css";
import { useContext, useState, useEffect } from "react";
import {
  CloseHandler,
  OpenHandler,
  SideMenuStatus,
} from "../sideMenu/SideMenu";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import next from "../../assets/next.png";
import Logo from "../../assets/Logo.png";
import Logo_white from "../../assets/Creator Stride-white.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Avatar,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Popover, Typography } from "@material-ui/core";
import { ExitToApp, ThumbDown } from "@material-ui/icons";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExtensionIcon from "@mui/icons-material/Extension";
import HomeIcon from "@mui/icons-material/Home";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import LockIcon from "@mui/icons-material/Lock";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import logo from "../../assets/quiz_header.png";
import light_logo from "../../assets/new_light_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { updateReduxProfileImage } from "../../redux/actions/actions";

const SiteHeader = ({ channelId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxProfileImage = useSelector((state) => state.reduxProfileImage);
  const openHandler = useContext(OpenHandler);
  const sideMenuStatus = useContext(SideMenuStatus);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const location = useLocation();
  const [isScrolled, setScrolled] = useState(false);
  const [channelImageUrl, setChannelImageUrl] = useState("");
  const session_id = Cookies.get("session_id");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:600px) and (max-width:1199px)"
  );

  const getDrawerWidth = () => {
    if (isLargeScreen) {
      return "20%";
    } else if (isMediumScreen) {
      return "50%";
    } else {
      return "80%";
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const boxStyle = {
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
  };

  const headerStyle = {
    position: "sticky",
    top: 0,
    background: isScrolled ? "#ffffff" : "var(--collection-1-primary-main)",
    width: "100%",
    transition: "background 0.3s ease",
    zIndex: "1000",
  };

  const logoTextStyle = {
    color: isScrolled ? "var(--collection-1-primary-main)" : "white",
    fontSize: "20px",
  };

  const handleLogout = () => {
    navigate("/");
    Cookies.remove("session_id");
    Cookies.set("");
    closeMenu();
    Cookies.set("isLoggedIn",false)
  };

  const whiteIconStyle = {
    color: "white",
  };

  const textAlignStyle = {
    // display: 'flex',
    // alignItems: 'center', // Vertically align items to the center
    // justifyContent: 'center', // Horizontally center items
    // cursor: 'pointer',
    // textAlign:'center'
  };

  const iconStyle = {
    // marginRight: "8px", // Adjust margin as needed
  };

  useEffect(() => {
    if (reduxProfileImage) {
      return;
    }
    const payload = {
      session_id: session_id,
      channel_id: channelId,
    };
    if (!channelId) {
      return;
    }
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/getDashboardCardHeader`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("success in the dashboard header");
          console.log(
            "The response data from fetch site header : ",
            responseData
          );
          if (responseData.status === "Unauthorized") {
            window.location.replace("/");
          } else if (responseData.status === "success") {
            const thumbnail = responseData.result.thumbnail_url;
            // dispatch(setThumbnail(thumbnail));
            setChannelImageUrl(thumbnail);
            dispatch(updateReduxProfileImage(thumbnail));
            Cookies.set("channel_name", responseData.result.channel_name);
          } else if (responseData.status === "error") {
            // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    } catch (error) {
      console.log("error");
      console.log("Error");
    }
  }, [channelId]);

  useEffect(() => {
    console.log("The redux profile image from useEffect : ", reduxProfileImage);
  }, [reduxProfileImage]);

  return (
    <div className="px-3 px-md-5 py-2 shadow-sm" style={headerStyle}>
      <div className="d-none d-md-flex justify-content-between py-1 px-4 align-items-center">
        <div className="title px-1 px-md-3 d-flex justify-content-between gap-3 align-items-center cursor-pointer">
          <img
            className="img"
            alt="Logo"
            src={isScrolled ? logo : light_logo}
            onClick={() => navigate("/")}
            width="200px"
          />
        </div>
        <img
          src={reduxProfileImage ? reduxProfileImage : user}
          className="w-35px cursor-pointer"
          alt="User"
          style={boxStyle}
          onClick={toggleMenu}
        />
      </div>
      <div className="d-block d-md-none">
        <div className="d-flex justify-content-between align-items-center cursor-pointer">
          <div
            className="title px-1 px-md-3 d-flex justify-content-between gap-3 align-items-center"
            onClick={() => {
              CloseHandler();
              navigate("/");
            }}
          >
            <img
              className="img"
              alt="Logo"
              src={isScrolled ? logo : light_logo}
              width="150px"
              height="auto"
            />
          </div>
          <img
            src={reduxProfileImage ? reduxProfileImage : user}
            className="w-35px cursor-pointer"
            alt="User"
            style={boxStyle}
            onClick={toggleMenu}
          />
        </div>
      </div>
      <Drawer
        open={isMenuOpen}
        onClose={closeMenu}
        anchor="left"
        PaperProps={{
          sx: { width: getDrawerWidth(), textAlign: "center" },
        }}
      >
        <List style={{ padding: "30px" }}>
          <ListItem style={{ padding: "30px 0px" }}>
            <Avatar
              alt="Logo"
              src={reduxProfileImage ? reduxProfileImage : user}
              style={{ margin: "auto" }}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/");
            }}
          >
            <ListItemIcon style={iconStyle}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/dashboard/analytics/competitorAnalysis");
            }}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis" style={textAlignStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/dashboard/analytics/contentGeneration");
            }}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="Content Generation" style={textAlignStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/CompetitorFilterSearch");
            }}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Competitor Search" style={textAlignStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/userPermission");
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="User permissions" style={textAlignStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/dashboard/analytics");
            }}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Tag Analysis" style={textAlignStyle} />
          </ListItem>
          {/* <ListItem button className="d-block d-md-none" onClick={() => {
            navigate('/dashboard/analytics');
            closeMenu();
          }}>
            <ListItemText
              primary="Analytics"
            />
          </ListItem> */}
          <ListItem
            button
            className="d-md-none"
            onClick={() => {
              closeMenu();
              navigate("/dashboard/analytics/commentAnalysis");
            }}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Comment Analysis" style={textAlignStyle} />
          </ListItem>
          <ListItem
            button
            className="d-md-none"
            onClick={() => {
              closeMenu();
              navigate("/dashboard/analytics/competitorAnalysis");
            }}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText
              primary="Competitor Analysis"
              style={textAlignStyle}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closeMenu();
              navigate("/changePassword");
            }}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Change Password" style={textAlignStyle} />
          </ListItem>
        </List>

        <Divider />
        {session_id ? (
          <List
            style={{
              marginTop: "auto",
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
            }}
            onClick={handleLogout}
          >
            <ListItem button>
              <IconButton>
                <ExitToAppIcon style={whiteIconStyle} />
              </IconButton>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        ) : null}
      </Drawer>
    </div>
  );
};

export default SiteHeader;
