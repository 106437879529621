import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  console.log("from require auth");
  const session_id = Cookies.get("session_id");
  const expiry_session = Cookies.get("expiry");
  const navigate = useNavigate();

  const location = useLocation();
  console.log(session_id);
  console.log(expiry_session);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [data, setData] = useState({});

  // useEffect(() => {
  //   const sessionExpiryTimestamp = new Date("Sat, 21 Oct 2023 13:18:15 GMT");

  //   const currentTimestamp = new Date();
  //   console.log(currentTimestamp)
  //   if (currentTimestamp >= sessionExpiryTimestamp) {
  //     setIsSessionExpired(true);
  //   } else {
  //     setIsSessionExpired(false);
  //   }
  // }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("Into checkform completion function");
        const payload = {
          session_id,
        };
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/checkFormCompletion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error(`Response code was not ok: ${response.code}`);
        }
        const data = await response.json();
        if (data.status === "Unauthorized") {
          Cookies.set("isLoggedIn", false);
          navigate("/");
        }
        console.log("THe check from completion form response is :", data);
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData(); // Call the async function to fetch data
  }, [session_id]);

  console.log("data plan form is ", data.plan_form);
  console.log("data payment form is ", data.payment_form);
  if (data.role === "Intern") {
    if (data.plan_form === "INCOMPLETE") {
      return <Navigate to="/SignupPlan" path={{ path: location.pathname }} />;
    } else if (data.payment_form === "INCOMPLETE") {
      navigate("/payment");
    } else if (data.channelId_form === "INCOMPLETE") {
      navigate("/channelId");
      // } else if (isSessionExpired) {
      //   return <Navigate to="/signIn" path={{ path: location.pathname }} />
    } else if (
      data.scrapped_comments === "NO" &&
      data.channelId_form === "COMPLETED"
    ) {
      navigate("/accountCreated");
    } else if (data.scrapped_comments === "INPROGRESS") {
      navigate("/accountCreated");
    } else if (
      data.scrapped_comments === "YES" ||
      data.scrapped_comments === "NOT_REQUIRED"
    ) {
      return children;
    }
  } else {
    if (data.hasChannelForm === "INCOMPLETE") {
      navigate("/internship/account");
    } else if (
      data.hasChannel === 1 &&
      data.channelVerification === "INCOMPLETE"
    ) {
      navigate("/channelId");
    } else if (data.hasChannel === 1 && data.channelID === null) {
      navigate("/channelId");
    } else if (
      data.hasChannel === 2 &&
      data.internQuestionnaireCompleted === 2
    ) {
      navigate("/internship/questionnaire");
    } else if (
      data.hasChannel === 2 &&
      data.internQuestionnaireCompleted === 1
    ) {
      navigate("/internship/v1/playground");
    } else if (data.hasChannel === 1 && data.scrapped_comments !== "YES") {
      navigate("/accountCreated");
    } else if (data.scrapped_comments === "YES") {
      return children;
    }
  }
};
