
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Radio, RadioGroup, FormControl, FormControlLabel, Box } from '@mui/material';
import './QuestionForm.css'
import { List, ListItem, ListItemText } from '@material-ui/core';
import LoadingOverlay from '../../Components/dashboard/LoadingOverlay'
import { useNavigate } from 'react-router-dom'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Cookies from 'js-cookie'

const QuestionForm = ({ setQuestionFormVisible, questions, answers, setAnswers, email,setNicheData }) => {
    const session_id=Cookies.get("session_id")
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [topics, setTopics] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedDomains, setSelectedDomains] = useState([])
    const [domains, setDomains] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [domainsCount, setDomainsCount] = useState(1)
    const [script, setScript] = useState('')
    const navigate = useNavigate()
    const [contentFeedback, setContentFeedback] = useState(false)
    const [shortsScript, setShortsScript] = useState('')
    const [shortsContentVisibility, setShortsContentVisibility] = useState(false)
    const [isHovering,setIsHovering] = useState(false)


    const handleItemClick = (item) => {
        if (selectedDomains.length === 0) {
            setSelectedDomains((prevSelected) =>
                prevSelected.includes(item)
                    ? prevSelected.filter((selected) => selected !== item)
                    : [...prevSelected, item]
            );
        } else {
            setSelectedDomains([item])
        }
    };

    const handleTopicsClick = (item) => {
        setSelectedTopics([item])
    }


    const getDomains = async () => {
        const payload = {
            answers,
            session_id
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/fetchDomainsFromAnswers`, {
                method: "POST",
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(payload)
            })
            if (!response.ok) {
                throw new Error("Error during fetching domains : ", response)
            }
            return response.json()
        } catch (error) {
            console.log("Error occured in the getDomains function")
        }
    }


    const handleNextQuestion = () => {
        if (!answers[currentQuestion]) {
            return
        }
        try {
            if (currentQuestion == questions.length - 1) {
                // console.log("The form data is : ",answers)
                setIsLoading(true)
                const responseData = getDomains()
                // setTimeout(() => {
                responseData.then((result) => {
                    setIsLoading(false)
                    let domains = ""
                    if (result?.status === "success") {
                        console.log("The domains is : ", result?.data)
                        if (result?.data) {
                            if (typeof result?.data === 'string') {
                                console.log("The domains data is a string")
                                domains = JSON.parse(result?.data.replace(/'/g, '"'));
                                console.log("Domains after parsing is ", domains)
                                domains = domains.slice(0, 6)
                            } else {
                                console.log("The domains data is not a string")
                                if(Array.isArray(result?.data[0])){
                                   alert("first element is an array")
                                }
                                domains = (result?.data).slice(0, 6)
                            }
                        }
                        console.log("The domains is after slice : ", domains)
                        setNicheData(domains)
                        setQuestionFormVisible(false)
                    }
                }).catch((error) => {
                    console.error("Error fetching data: ", error);
                });
                // }, 2000)
                // setQuestionFormVisible(false)
            } else {
                setCurrentQuestion((prev) => prev + 1);
            }
        } catch (error) {
            throw new Error(error)
        }
    };

    const handleRadioChange = (event) => {
        setAnswers((prev) => {
            const newAnswers = [...prev];
            newAnswers[currentQuestion] = { "question": questions[currentQuestion]?.text, "answer": event.target.value }
            // newAnswers[currentQuestion] = event.target.value;
            return newAnswers;
        });
    }

    const progressDiv = {
        width: `${(answers.length / questions.length) * 100}%`,
        height: '5px',
        backgroundColor: 'var(--collection-1-primary-main)',
    };

    const isItemSelected = (item) => selectedDomains.includes(item);
    const isTopicSelected = (item) => selectedTopics.includes(item)

    const selectionStyle = {
        'border': '2px solid var(--collection-1-primary-main)',
        'width': 'fit-content'
    }


    const generateTopics = async () => {
        const payload = {
            selectedDomains,
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getTopicsOnDomain`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            const responseData = await response.json()
            setIsLoading(false)
            if (responseData?.status === "success") {
                console.log("The generated topics response is:", responseData);

                let data = '';  // Declare outside the if statement

                console.log(typeof responseData?.data);

                if (typeof responseData?.data === 'string') {
                    console.log("The JSON string is:", responseData?.data);

                    // Try to parse the JSON string
                    try {
                        data = JSON.parse(responseData?.data);
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                } else {
                    data = responseData?.data;
                }
                const topics = []
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        console.log("The key value is:", data[key]);
                        if (Array.isArray(data[key])) {
                            for (var arrData of data[key]) {
                                topics.push(arrData)
                            }
                        }
                    }
                }
                console.log("The topics after looping the object is : ", topics)
                setTopics(topics.slice(0, 6))
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const generateScript = async () => {
        const payload = {
            topics,
        }
        setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/generateScriptOnTopic`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        const responseData = await response?.json()
        setIsLoading(false)
        if (responseData?.status === "success") {
            if (responseData?.data) {
                console.log("The script is : ", responseData?.data)
                setScript(responseData?.data)
            }
        }
    }


    const fetchShortsContent = async () => {
        setShortsScript('')
        setIsLoading(true)
        const payload = {
            script,
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/fetchShortsContent`, {
                method: "POST",
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(payload)
            })
            const responseData = await response.json()
            console.log("The response Data from fetch shorts content : ", responseData)
            setIsLoading(false)
            if (responseData?.status === "success") {
                if (responseData?.data) {
                    setShortsScript(responseData?.data)
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const shortsContentVisibiltyStyle = {
        display: shortsContentVisibility ? 'block' : 'none',
    }

    const dropdownMenuStyle = {
        transform: !shortsContentVisibility ? '' : 'rotate(180deg)'
    }


    const handleMouseOver=()=>{
         setIsHovering(true)
    }

    const handleMouseOut=()=>{
        setIsHovering(false)
    }

    return (
        <div className='question-container'>
            <div className='form-container bg-light rounded col-11 col-sm-10 col-md-9 col-lg-6 col-xl-5'>
                {domains.length === 0 && topics.length === 0 ? (
                    <div className='question-card'>
                        <div className='bg-light'>
                            <div style={progressDiv}></div>
                            <Container className='d-flex flex-column justify-content-between h-100 px-4 px-md-5 py-4 py-md-5'>
                                {currentQuestion < questions.length ? (
                                    <>
                                        <Typography variant='h5' className='text-center' gutterBottom>
                                            {questions[currentQuestion]?.text}
                                        </Typography>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label={`Question ${currentQuestion + 1}`}
                                                name={`question${currentQuestion + 1}`}
                                                value={answers[currentQuestion]?.answer || ''}
                                                onChange={handleRadioChange}
                                            >
                                                {questions[currentQuestion].options.map((option, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={option}
                                                        control={<Radio />}
                                                        label={option}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        {currentQuestion === questions.length - 1 ? (
                                            <Button variant="contained" className='my-3 mt-md-5' style={{ backgroundColor: 'var(--collection-1-primary-main)' }} onClick={handleNextQuestion}>
                                                Proceed
                                            </Button>
                                        ) : (
                                            <Button variant="contained" className='my-3 mt-md-5' style={{ backgroundColor: 'var(--collection-1-primary-main)' }} onClick={handleNextQuestion}>
                                                Next Question
                                            </Button>
                                        )}
                                    </>
                                ) : null}
                            </Container>
                        </div>
                    </div>
                ) : (
                    topics.length === 0 ? (
                        <div className='question-card'>
                            <div className='bg-light rounded'>
                                <Container className='px-4 px-md-5 py-4 py-md-5 d-flex flex-column justify-content-between h-100 gap-2'>
                                    <div className='h3'>'Develop content tailored to the subjects that intrigue you the most.'</div>
                                    <div className='d-flex flex-wrap flex-row align-items-start mt-3'>
                                        {domains?.map((item, index) => (
                                            <button
                                                key={index}
                                                style={{
                                                    backgroundColor: isItemSelected(item) ? 'var(--collection-1-primary-main)' : 'white',
                                                    color: isItemSelected(item) ? 'white' : 'var(--collection-1-primary-main)',
                                                    height: 'fit-content',
                                                    border: '3px solid var(--collection-1-primary-main)',
                                                    fontSize: 'var(--h-tag-font)',
                                                }}
                                                onClick={() => handleItemClick(item)}
                                                className='mx-1 my-1 px-3 py-2 rounded shadow-sm'
                                            >
                                                {item}
                                            </button>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Button variant="contained" className='my-3 shadow' style={{ backgroundColor: 'var(--collection-1-primary-main)' }} onClick={() => {
                                            if (!(Array.isArray(selectedDomains) && !selectedDomains.length)) {
                                                setIsLoading(true)
                                                generateTopics()
                                            }
                                        }}>
                                            Generate
                                        </Button>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    ) : (
                        !script ? (<div className='question-card'>
                            <div className='bg-light rounded'>
                                <Container className='px-4 px-md-5 py-4 py-md-5'>
                                    <div className='h3'>'Explore fascinating content on your favorite topics effortlessly.'</div>
                                    <div className='d-flex flex-wrap flex-row align-items-start mt-3'>
                                        {topics?.map((item, index) => (
                                            <button
                                                key={index}
                                                style={{
                                                    backgroundColor: isTopicSelected(item) ? 'var(--collection-1-primary-main)' : 'white',
                                                    color: isTopicSelected(item) ? 'white' : 'var(--collection-1-primary-main)',
                                                    height: 'fit-content',
                                                    border: '3px solid var(--collection-1-primary-main)',
                                                    fontSize: 'var(--h-tag-font)',
                                                }}
                                                onClick={() => handleTopicsClick(item)}
                                                className='mx-1 my-1 px-3 py-2 rounded shadow-sm'
                                            >
                                                {item}
                                            </button>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Button variant="contained" className='my-3 mt-md-5 shadow' style={{ backgroundColor: 'var(--collection-1-primary-main)' }} onClick={generateScript}>
                                            Generate
                                        </Button>
                                    </div>
                                </Container>
                            </div>
                        </div>) : (
                            <div className='question-card'>
                                <Box className="script-container-questionaire bg-light rounded">
                                    <Container className='px-4 px-md-5 py-4 py-md-5 d-flex flex-column justify-content-between h-100'>
                                        <Box className="py-3">
                                            <Typography variant="h5" style={{ fontWeight: '600' }}>
                                                "Hey creator, here's your inaugural video script, set to captivate!"
                                            </Typography>
                                        </Box>
                                        <p style={{ font: 'var(--p-miny)' }}>
                                            {script}
                                        </p>
                                        <Box class="script-button-container">
                                            <Button variant="contained" className='my-3 shadow' style={{ backgroundColor: 'var(--collection-1-primary-main)' }} onClick={() => {
                                                setQuestionFormVisible(false)
                                                navigate('/')
                                            }}>
                                                Done
                                            </Button>
                                            <Button variant="contained" className='my-3 shadow' style={{ backgroundColor: 'var(--collection-1-primary-main)' }}  onClick={() => fetchShortsContent()}>
                                                Shorts Scirpt Generation
                                            </Button>
                                        </Box>
                                        <Box>
                                            {shortsScript && (
                                                <>
                                                    <Box className="d-flex justify-content-between bg-mild py-2 cursor-pointer rounded px-2" style={{ borderBottom: '2px solid var(--collection-1-primary-main)', backgroundColor:isHovering ? 'rgba(196, 204, 255,0.2)':''}} onMouseOver={handleMouseOver}  onMouseOut={handleMouseOut} onClick={() => setShortsContentVisibility(!shortsContentVisibility)} >
                                                        <Box>Shorts Script</Box>
                                                        <ArrowDropDownCircleIcon style={dropdownMenuStyle} />
                                                    </Box>
                                                    <Box className="bg-light py-4" style={shortsContentVisibiltyStyle}>
                                                        <Typography>{shortsScript}</Typography>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Container>
                                </Box>
                            </div>
                        )
                    )
                )}
            </div>
            {isLoading && <LoadingOverlay />}
        </div>
    );
}

export default QuestionForm;
