import React from "react";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import icon from '../../../assets/icon .svg'
import frame from '../../../assets/frame.svg'
import video from '../../../assets/video.svg'
import { useState, useEffect, useRef } from 'react';

const Features = () => {
    // const targetRef = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 const div1 = document.getElementById("div-movement1")
    //                 const div2 = document.getElementById("div-movement2")
    //                 const div3 = document.getElementById("div-movement3")
    //                 if (div1 && div2 && div3) {
    //                     if (entry.isIntersecting) {
    //                         console.log('Element is in the viewport!');
    //                         div1.classList.add("div-movement")
    //                         div1.style.left = "0px";
    //                         div2.style.left = "0px";
    //                         div3.style.left = "0px";
    //                         div2.classList.add("div-movement")
    //                         div3.classList.add("div-movement")
    //                     } else {
    //                         console.log('Element is out of the viewport!')
    //                         div1.classList.remove("div-movement")
    //                         div2.classList.remove("div-movement")
    //                         div3.classList.remove("div-movement")
    //                     }
    //                 }
    //             });
    //         },
    //         {
    //             root: null, 
    //             rootMargin: '0px', 
    //             threshold: 0.5, 
    //         }
    //     );

    //     if (targetRef.current) {
    //         observer.observe(targetRef.current);
    //     }

    //     return () => {
    //         if (targetRef.current) {
    //             observer.unobserve(targetRef.current);
    //         }
    //     };
    // }, [targetRef]);

    return (
        <div className="feature-section col-12 px-md-5 py-md-3 px-5 py-3 max-1440">
            <div className="px-md-5 px-1 py-3">
                <div className="px-1">
                    <div className="px-md-5 px-1">
                        <div className="title d-flex justify-content-between flex-lg-row flex-column gap-4 py-3">
                            <div className="div">
                                <div className="text-wrapper">The features we offer</div>
                                <p className="discover">
                                    <span className="span">
                                        Discover Personalized <br />
                                        Content to{" "}
                                    </span>
                                    <span className="text-wrapper-2">Engage Your Audience</span>
                                </p>
                            </div>
                            <p className="embark-on-an">
                                Embark on an unparalleled YouTube journey with our all-encompassing tool. Designed for creators at every
                                stage, our platform seamlessly adapts to your unique needs - whether you&#39;re crafting your first video or
                                aiming to top the trending list. Discover, engage, and grow with a tool that evolves with you.&#34;
                            </p>
                        </div>
                        <div className="content d-flex justify-content-between flex-lg-row flex-column gap-3 py-3">
                            <div className="div-2 shadow" id="div-movement1">
                                <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                                    <img className="img" alt="Content" src={icon} width="25" height="25"/>
                                </div>
                                <div className="content-2">
                                    <div className="text-wrapper-3">Enhanced Discovery</div>
                                    <p className="p">Uncover valuable insights to optimize your content strategy and captivate your viewers.</p>
                                </div>
                            </div>
                            <div className="div-2 shadow" id="div-movement2">
                                <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                                    <img className="img" alt="Content" src={frame} width="25" height="25"/>
                                </div>
                                <div className="content-2">
                                    <div className="text-wrapper-3">Data-driven Decisions</div>
                                    <p className="p">Leverage advanced analytics to make informed decisions and drive YouTube’s success.</p>
                                </div>
                            </div>
                            <div className="div-2 shadow" id="div-movement3">
                                <div className="px-2 py-2 rounded" style={{ backgroundColor: '#fafbff' }}>
                                    <img className="img" alt="Content" src={video} width="25" height="25"/>
                                </div>
                                <div className="content-2">
                                    <div className="text-wrapper-3">Tools And Scripts That Will Help</div>
                                    <p className="p">
                                        We provide tools and scripts that will help improve your YouTube video production process
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features