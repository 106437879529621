import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import internship from "../../assets/internship.jpg";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Close, Diversity1 } from "@mui/icons-material";
import "./Internship.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import ContactUsSuccess from "./ContactUsSuccess";
import Cookies from "js-cookie";
import google from "../../assets/Google.svg";
import Register from "../homePage/register/Register";
import eye from "../../assets/eye.png";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CryptoJS from "crypto-js";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import { useSearchParams } from "react-router-dom";
import LinearColor from "../headerLoader/headerLoader";
import { toast } from "react-toastify";

const Internship = () => {
  const session_id = Cookies.get("session_id");
  let { id } = useParams();
  const navigate = useNavigate();
  const [youtubeVideoURL, setYoutubeVideoURL] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [emailId, setEmailId] = useState(null);
  const [value, setValue] = useState([0, 10]);
  const [feature, setFeature] = useState(null);
  const [searchParams, setSearchParamas] = useSearchParams();
  const [form1CompletionStatus, setForm1CompletionStatus] = useState(false);
  const [file, setFile] = useState(null);
  const checksum = searchParams.get("checksum");
  const code = null;
  const currentURL = window.location.pathname + window.location.search;
  const [showHeaderLoader, setShowHeaderLoader] = useState(false);

  const handleChange = (event, newValue) => {
    const [minValue, maxValue] = newValue;
    setValue([0, maxValue]);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const isLoggedIn = Cookies.get("isLoggedIn");
  //     console.log("Is logged In : ", isLoggedIn);
  //     if (isLoggedIn !== "true") {
  //       navigate("/?isLogin=True");
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    const payload = {
      checksum,
      session_id,
    };
    const fetchUserData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/jwtTokenAuthenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      if (responseData?.status_code == "200") {
        setEmailId(responseData?.email_id);
      } else if (responseData?.status_code == "301") {
        Cookies.set("isLoggedIn", false);
        Cookies.remove("session_id");
        Cookies.set("redirect_url", currentURL);
        navigate("/?isLogin=True");
      } else {
        toast.error("Something Went Wrong !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    fetchUserData();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    console.log("Email id from useeffect : ", emailId);
  }, [emailId]);

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const wait = async (n) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n);
    });
  };

  const formSubmition = async () => {
    setShowHeaderLoader(true);
    setShowDialog(false);
    setAlreadySent(false);
    if(file){
      if(file?.size > 200000000){
       setShowHeaderLoader(false)
       toast.error("File size too large !!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return 
      } // 200MB

    }
    try {
      const formData = new FormData();
      formData.append("feedback", feedback);
      formData.append("value", value);
      formData.append("email_id", emailId);
      formData.append("file", file);
      formData.append("session_id", session_id);
      formData.append("code", code);

      const submitResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/upload/intern/Assignment`,
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("Submit response : ", submitResponse);
      const response = await submitResponse.json();
      setShowHeaderLoader(false);
      if (response?.status === "success") {
        setShowDialog(true);
        if (
          response?.status === "success" &&
          response?.message !== "API successfull"
        ) {
          setAlreadySent(true);
        } else {
          setFeedback("");
          setFile("");
        }
      } else if (response?.status === "error") {
        toast.error("Something went wrong !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setShowHeaderLoader(false);
      console.log("Error occured in form sumition : ", error);
      toast.error("Something went wrong !!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {!isLoading && (
        <Box
          style={{
            backgroundColor: "rgb(255, 245, 235)",
          }}
        >
          {showHeaderLoader && <LinearColor />}
          <Box
            className="px-4 px-sm-5 py-4 py-sm-5"
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
              width: "fit-content",
              borderBottomLeftRadius: "50%",
              position: "absolute",
              right: "0",
            }}
          ></Box>
          <Box
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <Box className="d-flex flex-column justify-content-between h-100">
              <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5">
                <Box className="contact-nav">
                  <img
                    src={logo}
                    alt="logo"
                    height="30px"
                    width="auto"
                    onClick={() => navigate("/")}
                  />
                  <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                    <NavLink to="/">Home</NavLink>
                    {/* <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/faq">FAQ</NavLink> */}
                    <NavLink to="/features">Features</NavLink>
                    <NavLink to="">Blog</NavLink>
                    <NavLink to="">FAQ</NavLink>
                  </Box>
                </Box>
              </Box>
              <Box className="d-flex flex-lg-row flex-column align-items-center justify-content-between py-5 px-3 px-md-5 py-3 mx-auto">
                <Box className="col col-12 col-sm-12 col-md-11 col-lg-7 col-xl-5 mx-auto px-3">
                  <Box className="d-flex justify-content-center">
                    <img
                      src={internship}
                      alt="contact-us"
                      class="intern-image"
                    />
                  </Box>
                  <Box>
                    <Typography variant="h5" class="py-5 px-3">
                      Are you a dynamic individual looking to gain hands-on
                      experience in the fast-paced world of YouTube data
                      analytics and content creation? CreatorStride offers
                      exciting internship opportunities that provide a unique
                      blend of learning, collaboration, and growth.
                    </Typography>
                  </Box>
                </Box>
                <Box class="px-3 mx-auto">
                  <Typography variant="h4">
                    Contribute by Sharing Your Work with Us!
                  </Typography>
                  <Box
                    className="my-4 w-100 d-flex aign-items-center rounded"
                    style={{
                      minHeight: "500px",
                      //border: `2px solid var(--collection-1-primary-main)`,
                    }}
                  >
                    <Box className="w-100 px-4 px-md-5 mx-auto my-auto">
                      {/* <Box className="h4 text-center">Account Login</Box> */}
                      <Box className="my-4">
                        <TextField
                          id="outlined-password-input"
                          label="Email ID"
                          className="w-100"
                          variant="outlined"
                          value={emailId}
                          autoComplete="off"
                          disabled="true"
                        />
                      </Box>
                      <Box className="my-4">
                        <Typography id="range-slider" gutterBottom>
                          Feedback :
                        </Typography>
                        <Textarea
                          id="outlined-password-input"
                          placeholder="Detailed feedback highlighting both strengths and potential improvements."
                          type="textarea"
                          minRows={4}
                          className="w-100"
                          variant="outlined"
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          autoComplete="off"
                        />
                      </Box>
                      <Box className="my-3">
                        <Typography id="range-slider" gutterBottom>
                          Rate Us:
                        </Typography>
                        <Slider
                          style={{
                            color: "var(--collection-1-primary-main)",
                          }}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          min={0}
                          max={10}
                        />
                      </Box>
                      <Box className="my-3">
                        <MuiFileInput
                          placeholder="Zip File"
                          fullWidth
                          label="Zip File"
                          getInputText="Zip File"
                          value={file}
                          onChange={(file_) => setFile(file_)}
                          clearIconButtonProps={{
                            title: "Remove",
                            children: <CloseIcon fontSize="small" />,
                          }}
                        />
                      </Box>
                      <Button
                        className="py-2"
                        style={{
                          backgroundColor: "var(--collection-1-primary-main)",
                          // backgroundColor:'white',
                          // color: "var(--collection-1-primary-main)",
                          width: "100%",
                          color: "white",
                        }}
                        disabled={!value || !feedback || !file || showHeaderLoader}
                        onClick={formSubmition}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                className="d-flex justify-content-between px-5"
                // style={{ height: "5%" }}
              >
                <Box
                  className="d-flex justify-content-between"
                  style={{ height: "auto" }}
                ></Box>
              </Box>
              <Box
                className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
                style={{
                  minHeight: "50px",
                  backgroundColor: "var(--collection-1-primary-main)",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2023 creatorstride.com. All Rights Reserved.
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isLoading && <LoadingOverlay />}
      {showDialog && (
        <Dialog
          open="true"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="bg-white d-flex justify-content-center position-relative px-5 py-5 rounded">
            <div className="w-100">
              <div className="cross cursor-pointer">
                <IconButton
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {alreadySent ? (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Your response to the CreatorStride Internship Stage 2:
                    Field-Specific Assignment has already been received.
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Your response for the CreatorStride Internship Stage 2:
                    Field-Specific Assignment has been recorded. You will soon
                    receive an email about the further process !!
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Internship;
