import { Box, Container, Typography, Button, Input } from "@mui/material";
import { lazy, Suspense, useState, useEffect } from "react";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import "./Playground.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuestionForm from "../forms/QuestionForm";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Cookies from "js-cookie";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  FormHelperTextButtonRadio,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const Header = lazy(() => import("../homePage/header/Header"));

const InternUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const session_id = Cookies.get("session_id");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const topic = searchParams.get("topic");
  const [script, setScript] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");

  useEffect(() => {
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchScriptFromHistory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id, topic }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Response data is : ", responseData);
          if (responseData.status === "success") {
            setScript(responseData.script);
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const uploadFile = () => {
    const formData = new FormData();
    const fileInput = document
      .getElementsByClassName("input-video-file")[0]
      .querySelector("input");
    console.log("File input === ", fileInput);
    console.log("Number of files selected:", fileInput.files.length);
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      formData.append("videoFile", file);
      formData.append("session_id", session_id);
      formData.append("topic", topic);
      console.log("Formdata ===== ", formData);
      const hasSessionId = formData.get("session_id") !== null;
      const fill = formData.get("videoFile");
      console.log("file === ", fill);
      fetch(`${process.env.REACT_APP_SERVER_URL}/uploadInternVideo`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("File uploaded successfully:", data);
          if (data.status === "success") {
            formData.delete("videoFile");
            formData.delete("session_id");
            formData.delete("topic");
            fileInput.value = null;
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      console.log("No file selected.");
    }
  };

  const uploadInternLink = () => {
    const payload = {
      session_id,
      topic,
      youtubeLink,
    };
    if (!youtubeLink) {
      return;
    }
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/uploadInternLink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Response data is : ", responseData);
          if (responseData.status === "success") {
            setYoutubeLink("");
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  };

  const downloadFile = () => {
    const blob = new Blob([script], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = topic;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Suspense fallback={<LoadingOverlay />}>
        <Box className="vh-100 d-flex flex-column justify-content-between">
          <Header style={{ flex: "0 0 auto" }} />
          <Box
            className="py-4 rounded questionnaire-page-container d-flex flex-column gap-4 flex-lg-row"
            style={{
              flex: "1",
              overflow: "auto",
              padding: "15px",
              minHeight: "0",
            }}
          >
            <Box className="bg-grey d-flex flex-column" style={{ flex: "1" }}>
              <Box className="bg-light px-5 py-5 my-auto h-100 overflow-auto script-generation-container">
                <Typography variant="h4">{topic}</Typography>
                <Typography className="my-3">{script}</Typography>
              </Box>
              <Box>
                <Button
                  className="w-100 my-3"
                  style={{
                    backgroundColor: "var(--collection-1-primary-main)",
                    color: "white",
                  }}
                  onClick={downloadFile}
                >
                  Download Script
                </Button>
              </Box>
            </Box>
            <Box style={{flex:'1'}}>
              <Box className="mx-auto">
                <Box
                  className="px-4 px-sm-5 py-3 my-3 rounded"
                  style={{
                    border: "1px solid var(--collection-1-primary-main)",
                  }}
                >
                  <Typography className="py-3" variant="h5">
                    Ready With Your Video? Then Share With Us...
                  </Typography>
                  <TextField
                    id="outlined-password-input"
                    label="Youtube Link"
                    className="w-100"
                    variant="outlined"
                    autoComplete="off"
                    value={youtubeLink}
                    onChange={(e) => setYoutubeLink(e.target.value)}
                  />
                  <Button
                    className="w-100 my-3"
                    style={{
                      backgroundColor: "var(--collection-1-primary-main)",
                      color: "white",
                    }}
                    onClick={uploadInternLink}
                  >
                    Upload Link
                  </Button>
                </Box>
                <Box>
                  <Box
                    className="py-3 px-4 px-sm-5 rounded"
                    style={{
                      border: "1px solid var(--collection-1-primary-main)",
                    }}
                  >
                    <Typography variant="h5" className="py-3">
                      Not Having A Youtube Channel? Then Upload Your File With
                      Us..
                    </Typography>
                    <Input type="file" className="input-video-file" />
                    <Button
                      className="w-100 my-3"
                      style={{
                        backgroundColor: "var(--collection-1-primary-main)",
                        color: "white",
                      }}
                      onClick={uploadFile}
                    >
                      Upload File
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="d-flex justify-content-center px-5 align-items-center text-center py-4 py-md-4"
            style={{
              minHeight: "50px",
              backgroundColor: "var(--collection-1-primary-main)",
              width: "100%",
              color: "white",
              flex: "0 0 auto",
            }}
          >
            © 2023 creatorstride.com. All Rights Reserved.
          </Box>
        </Box>
        {isLoading && <LoadingOverlay />}
      </Suspense>
    </>
  );
};

export default InternUpload;
