// Login.js

import React from "react";
import "./style.css"; // Import the general styles
import google from "../../../assets/Google.svg";
import apple from "../../../assets/Apple.svg";
import eye from "../../../assets/Eye.svg";
import hideEye from "../../../assets/invisible.png";
import line from "../../../assets/Line.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Link, useNavigation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import CryptoJS from "crypto-js";
import close from "../../../assets/delete.png";
import { updateReduxUsername } from "../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, IconButton, Button, Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useHistory } from "react-router-dom";
import LoadingOverlay from "../../dashboard/LoadingOverlay";

const Login = ({
  showLoginModal,
  showRegisterModal,
  showJoinNowModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
  setIsLogin,
}) => {
  const reduxUserName = useSelector((state) => state.reduxUserName);
  const dispatch = useDispatch();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading,setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState();
  const [removeSessionStorage, setRemoveSessionStorage] = useState(false);
  const [inputType, setInputType] = useState("password");
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState();
  const session_id = Cookies.get("session_id");
  const location = useLocation();
  const redirect_url = Cookies.get("redirect_url");

  useEffect(() => {
    const body = document.querySelector("body");

    if (!showJoinNowModal || !showRegisterModal || !showLoginModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }
    return () => {
      body.style.overflow = "visible";
    };
  }, [showJoinNowModal, showLoginModal, showRegisterModal]);

  const hashString = (password) => {
    // Hash the string using SHA-256
    const hashedString = CryptoJS.SHA256(password).toString();
    return hashedString;
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      console.log(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          const userData = res.data;
          userData.mode = "G-USER"
          SignInSubmitHandler(userData);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const nameChanger = (event) => {
    setUsername(event.target.value);
  };

  const passwordChanger = (event) => {
    setPassword(event.target.value);
  };

  const responseGoogle = (response) => {
    console.log(response);
  };

  const changeInputType = () => {
    let inputButton = document.getElementById("passwordInput");
    let input_type = inputButton.getAttribute("type");
    let viewerButton = document.getElementById("viewerButton");
    if (input_type == "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const formSubmitHandler = (event) => {
    // event.preventDefault();
    const data = {
      username: username,
      password: password,
      email: null,
      id: null,
      mode:"N-USER"
    };
    SignInSubmitHandler(data);
  };

  const setCustomExpiryCookie = (name, value, expirationDate) => {
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  };

  const SignInSubmitHandler = async (userData) => {
    setLoading(true)
    // event.preventDefault()
    let server_url = process.env.REACT_APP_SERVER_URL;
    let payload = {
      username: userData.email ? userData.email : userData.username,
      password: userData.id
        ? hashString(userData.id)
        : hashString(userData.password),
      session_id,
      mode: userData?.mode
    };
    try {
      const response = await fetch(`${server_url}/userValidation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok:${response.status}`);
      }
      const data = await response.json();
      setLoading(false)
      if (String(data?.status_code) === "200") {
        console.log("validation successfull");
        console.log("The responseData from validation is : ", data);
        // console.log(data)
        toast.success("Login Successfull", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: "500",
        });
        Cookies.set("user_id", data.user_id);
        window.postMessage(
          { action: "messageFromWebPage", data: data.session_id },
          "*"
        );
        Cookies.set("isLoggedIn", true);
        Cookies.set("session_id", data.session_id, { expires: 30 });
        dispatch(updateReduxUsername(username));
        if (redirect_url) {
          Cookies.remove("redirect_url")
          navigate(redirect_url)
        } else {
          navigate("/dashboard");
        }
      }else if(String(data?.status_code) === "405"){
        toast.error(data?.message, { position: toast.POSITION.TOP_RIGHT });
      } else if (String(data?.status_code) === "401") {
        console.log(data);
        toast.error("Incorrect Password !!", { position: toast.POSITION.TOP_RIGHT });
      } else if (String(data?.status_code) === "404") {
        console.log(data);
        toast.error("Email Not Registered !!", { position: toast.POSITION.TOP_RIGHT });
      } else if (data.status === "error") {
        console.log(`${data.message}`);
        console.log(data);
        toast.error("Something went wrong !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (data.status === "ACCESS_DENIED") {
        toast.error("User Access Denied !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setLoading(false)
      console.error("Error :", error);
      return "Error";
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    setTimeout(() => {
      if (!showJoinNowModal || !showRegisterModal || !showLoginModal) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "visible";
      }
    }, 200);

    return () => {
      body.style.overflow = "visible";
    };
  }, [showJoinNowModal, showLoginModal, showRegisterModal]);

  return (
    <div className="modal-overlay">
      {loading && <LoadingOverlay/>}
      <div className="login-container col-11 col-sm-8 col-md-6 col-lg-4 col-xl-4 col-xxl-3 px-4 py-4 px-md-5 py-md-5 position-relative">
        <div
          className="d-flex justify-content-end w-100"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          {/* <img onClick={handleCloseLoginModal} src={close} alt="close icon" /> */}
          <IconButton
            //   style={closeIconStyle}
            onClick={() => {
              setIsLogin(false);
              handleCloseLoginModal();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="title mt-4">
          <div className="d-flex justify-content-center w-100 text-center">
            <div
              className="text-wrapper h4 text-center"
              style={{ fontWeight: "700", fontSize: "20px" }}
            >
              Welcome to CreatorStride
            </div>
          </div>
          <p className="let-s-build-your text-center">
            Let&#39;s build your channel to be better
          </p>
        </div>
        <div className="input">
          <div className="div w-100">
            <div className="text-wrapper-4">Email</div>
            <input
              className="input-2"
              placeholder="Enter Username"
              type="text"
              onChange={nameChanger}
            />
          </div>
          <div className="div w-100">
            <div class="div w-100">
              <div className="text-wrapper-4">Password</div>
              <div className="input-2 d-flex justify-content-between">
                <input
                  className="border-0 w-100"
                  type={inputType}
                  placeholder="Password"
                  id="passwordInput"
                  onChange={passwordChanger}
                  required
                />
                <img
                  className="img"
                  alt="Eye"
                  src={inputType === "password" ? eye : hideEye}
                  onClick={changeInputType}
                />
              </div>
            </div>
            <div className="text-wrapper-4 cursor-pointer w-100 text-end py-2">
              Forget Password ?
            </div>
          </div>
        </div>
        <div
          className="register cursor-pointer"
          style={{ backgroundColor: "var(--collection-1-primary-main)" }}
          onClick={() => {
            if (username && password) {
              formSubmitHandler();
            }
          }}
        >
          <div className="text-wrapper-5">Login</div>
        </div>
        <div className="register-2">
          <img className="line" alt="Line" src={line} />
          <div className="text-wrapper-6">Or Login With</div>
          <img className="line" alt="Line" src={line} />
        </div>
        <div
          className="button-container"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="google cursor-pointer" onClick={() => login()}>
            <img className="img" alt="Google" src={google} />
            <div className="text-wrapper-7">Google</div>
          </div>
          {/* <div className="google cursor-pointer">
            <img className="img" alt="Apple" src={apple} />
            <div className="text-wrapper-7">Apple ID</div>
          </div>  */}
        </div>
        <p className="don-t-have-an">
          <span className="span">Don&#39;t have an account yet? </span>
          <span
            className="text-wrapper-8 cursor-pointer"
            style={{ color: "var(--collection-1-primary-main)" }}
            onClick={handleRegisterClick}
          >
            Register
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
