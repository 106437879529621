import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";

import { useState, useEffect } from "react";

import SiteHeader from "../dashboardHeader/SiteHeader";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import SendIcon from "@mui/icons-material/Send";
import TextArea from "antd/es/input/TextArea";
import InputLabel from "@mui/material/InputLabel";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-bootstrap/Carousel";

const VideoPlayground = () => {
  const [youtubeUrl, setYoutubeUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scriptData, setScriptData] = useState(null);
  const [loadingText, setLoadingText] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [query, setQuery] = useState(null);
  const [queryResponse, setQueryResponse] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [videoPath, setVideoPath] = useState([]);
  const [imageResponseJson, setImageResponseJson] = useState([]);
  const promptText = "Wanna Edit Prompt ?";
  const [isOpen, setIsOpen] = useState(false);
  const defaultPrompt =
    "Your goal is to stick to the knowledge base and answer according to the knowledge base.If the you couldn't say anything just say the data is not provided in the knowledge provided.Make sure the answers are relevant to the query provided ..";
  const [prompt, setPrompt] = useState(defaultPrompt);

  const fileTypes = ["mp4", "webp", "mp3"];

  const [sourceFile, setSourceFile] = useState(null);

  const handleChange = (file) => {
    // setSourceFile(file);
    console.log("File ======= ", file);
    uploadFile(file);
  };

  const uploadFile = async () => {
    setChatHistory([]);
    setImageResponseJson([]);
    setIsLoading(true);
    setLoadingText("Uploading ...");
    setYoutubeUrl("");
    const formData = new FormData();
    formData.append("file", sourceFile);
    console.log("FormData ==== ", formData);
    console.log("Source file ===== ", sourceFile);
    if (formData.has("file")) {
      console.log("'file' key exists in FormData");
    } else {
      console.log("'file' key does not exist in FormData");
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/uploadPlaygroundFile`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response in upload file is:", responseData);
      if (responseData?.status === "success") {
        //toast.success("Image downloaded Successfully !!!");

        setScriptData(responseData?.text_data);
        // setIsLoading(false);
        // setLoadingText(null);
        const id = responseData?.id;
        if (id) {
          setVideoId(id);
          trainData(id);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error in uploadFile:", error);
      throw new Error(error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    console.log("Toggle drawer ... function");
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //     return;
    // }
    setIsOpen(open);
  };

  const submitUrl = async () => {
    if (!submitUrl) {
      return;
    }
    setImageResponseJson([]);
    setSourceFile(null);
    setIsLoading(true);
    setScriptData(null);
    setLoadingText("Uploading");
    const payload = {
      youtubeUrl,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/submitPlaygroundUrl`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      console.log("Response data : ", responseData);
      if (responseData?.status === "success") {
        //toast.success("Image downloaded Successfully !!!");
        setScriptData(responseData?.text_data);
        // setIsLoading(false);
        // setLoadingText(null);
        const video_path = responseData?.video_path;
        console.log("video path ====== ", video_path);
        const video_path_splitted = video_path.split("/");
        console.log("video path splitted ====== ", video_path_splitted);
        const video_file_name =
          video_path_splitted[video_path_splitted.length - 1];
        console.log("video file name ===== ", video_file_name);
        const video_id = video_file_name.split(".")[0];
        console.log("video id ========== ", video_id);
        setVideoId(video_id);
        trainData(video_id);
        // generating_image_json(responseData?.json_data,responseData?.text_data,responseData?.video_path)
        setJsonData(responseData?.json_data);
        setVideoPath(responseData?.video_path);
      } else {
        // toast.error(responseData?.message);
        toast.error("Url Looks Invalid !!");
        setIsLoading(false);
        setLoadingText(null);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const generating_image_json = async () => {
    console.log("Generting image json ....");
    setImageResponseJson([]);
    setLoadingText("Fetching Images");
    setIsLoading(true);
    try {
      const payload = {
        json_data: jsonData,
        text: scriptData,
        video_path: videoPath,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/generatingImageJson`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      setIsLoading(false);
      console.log("Response in generating_image_json", responseData);
      if (responseData?.status === "success") {
        setImageResponseJson(responseData?.response_json);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error();
    }
  };

  const trainData = async (video_id) => {
    console.log("training data ...");
    setLoadingText("training ..");
    try {
      const payload = {
        video_id,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/trainPlaygroundData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      setIsLoading(false);
      console.log("Response in generating_image_json", responseData);
    } catch (error) {
      throw new Error(error);
    }
  };

  const updatePrompt = () => {
    toast.success("Prompt Updated ...");
    toggleDrawer(true);
  };

  const postQuery = async () => {
    if (!query || !scriptData) {
      return;
    }
    setQuery("");
    setIsLoading(true);
    setLoadingText("Fetching Response ....");
    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { type: "query", text: query },
    ]);
    try {
      const payload = {
        videoId,
        query,
        prompt,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/postPlaygroundQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      setIsLoading(false);
      setLoadingText(null);
      console.log("Response is : ", response);
      const responseData = await response.json();
      console.log("responsedata ==== ", responseData);
      if (responseData?.status === "success") {
        setQueryResponse(responseData?.data);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { type: "response", text: responseData?.data },
        ]);
      }
    } catch (error) {
      setIsLoading(false);
      setLoadingText(null);
      throw new Error(error);
    }
  };

  useEffect(() => {
    console.log("The chathistory from use effect ==== ", chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    if (sourceFile) {
      uploadFile();
    }
  }, [sourceFile]);

  useEffect(() => {
    if (scriptData) {
      setChatHistory([
        {
          type: "response",
          text:
            "Hey there I am here to answer your queries on the video uploaded ....",
        },
      ]);
    }
  }, [scriptData]);

  return (
    <Box>
      <SiteHeader />
      <ToastContainer />
      <Box
        className={`p-5 d-flex vh-100 ${
          scriptData ? "justify-content-between" : "justify-content-center"
        } gap-3 h-100 overflow-auto`}
      >
        <Box className="d-flex flex-column w-50">
          {!scriptData && (
            <Box>
              <h4
                className="text-center py-2"
                style={{
                  color: "var(--collection-1-primary-main)",
                  fontWeight: "800",
                }}
              >
                Analyze Any YouTube Video Instantly with One Click !!
              </h4>
              <p className="text-center" style={{ fontSize: "18px" }}>
                Effortlessly gain insights from any YouTube video with just a
                single click. Our tool provides instant analysis, helping you
                understand content in no time
              </p>
            </Box>
          )}
          <Box className="w-100 d-flex flex-row">
            <TextField
              id="outlined-basic"
              label="Youtube URL"
              className="w-100"
              variant="outlined"
              autoComplete="off"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
            />
            <Button
              className="rounded text-light p-3 mx-3"
              style={{ backgroundColor: "var(--collection-1-primary-main)" }}
              onClick={submitUrl}
            >
              <SendIcon />
            </Button>
          </Box>
          {!scriptData && (
            <Box>
              <h4
                className="text-center py-2"
                style={{
                  color: "var(--collection-1-primary-main)",
                  fontWeight: "800",
                }}
              >
                Drop your videos here to get them analyzed.
              </h4>
              {/* <p className="text-center" style={{ fontSize: "18px" }}>
              Effortlessly gain insights from any YouTube video with just a
              single click. Our tool provides instant analysis, helping you
              understand content in no time
            </p> */}
            </Box>
          )}
          <Box className="my-3 d-flex justify-content-center">
            {/* <FileUploader
              className="w-100"
              multiple={true}
              handleChange={handleChange}
              name={"file"}
              types={fileTypes}
            /> */}
            <MuiFileInput
              placeholder="File"
              fullWidth
              label="File"
              inputProps={{ accept: ".mp4, .webp" }}
              getInputText="File"
              value={sourceFile}
              onChange={(file_) => setSourceFile(file_)}
              clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="small" />,
              }}
            />
          </Box>
          <Box className="p-2 rounded my-2">
            {chatHistory.length > 0 &&
              chatHistory.map((data_) => (
                <Box
                  className={`d-flex ${
                    data_?.type === "query"
                      ? "justify-content-start"
                      : "justify-content-end"
                  } `}
                >
                  <Box
                    className={`my-2 p-3 rounded shadow ${
                      data_?.type === "query"
                        ? "chat-bot text-light"
                        : "chat-user text-dark"
                    } w-75`}
                  >
                    {data_?.text}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>

        {scriptData && (
          <Box
            className="p-4 m-2 rounded w-50 h-100 shadow"
            style={{ backgroundColor: "#fcf7d2", height: "90vh !important" }}
          >
            {scriptData && <h5>Script:-</h5>}
            <Box>{scriptData}</Box>
            {/* <Box>
              <Button
                style={{ backgroundColor: "var(--collection-1-primary-main)" }}
                className="p-2 text-light rounded my-3"
                onClick={generating_image_json}
              >
                Generate Images
              </Button>
            </Box> */}
            <Box className="d-flex flex-column gap-3 bg-white overflow-auto rounded shadow">
              {imageResponseJson.map((json) =>
                json?.image.length > 0 ? (
                  <Box className="d-flex align-items-center">
                    <Box
                      className="d-flex flex-column rounded p-2 m-3 align-items-center justify-content-center shadow"
                      style={{ backgroundColor: "#fcf7d2", width: "300px" }}
                    >
                      <h6>{json.time}</h6>
                      <Carousel>
                        {json.image.map((image) => (
                          <Carousel.Item>
                            <img src={image} width="200px" height="auto" />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </Box>
                    <Box>{json?.text}</Box>
                    {/* <Box className="px-3">
                      <input type="checkbox" onChange={() => {}} />
                    </Box> */}
                  </Box>
                ) : null
              )}
              {imageResponseJson.length > 0 ? (
                <Button
                  style={{
                    backgroundColor: "var(--collection-1-primary-main)",
                  }}
                  className="p-2 text-light rounded m-3 w-25"
                  onClick={generating_image_json}
                >
                  Generate Video
                </Button>
              ) : null}
            </Box>
          </Box>
        )}
      </Box>
      {scriptData && (
        <Box
          className="w-100 bg-white"
          style={{ position: "fixed", bottom: "0" }}
        >
          <Box
            className="w-100 d-flex justify-content-end cursor-pointer"
            onClick={toggleDrawer(true)}
          >
            <Box
              className="px-3 py-1 text-dark cursor-pointer shadow"
              style={{ backgroundColor: "#ffc299" }}
              onClick={toggleDrawer(true)}
            >
              {promptText}
            </Box>
          </Box>
          <Box className="p-3">
            <TextField
              id="outlined-basic"
              label="Enter Your Query Here ..."
              className="w-100"
              variant="outlined"
              autoComplete="off"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") postQuery();
              }}
            />
          </Box>
        </Box>
      )}
      <Box>
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
          <List className="h-100" style={{ width: "500px" }}>
            <Box className="p-4 d-flex flex-column justify-content-between h-100">
              <Box>
                <h5>Prompt:-</h5>
                <TextArea
                  id="outlined-basic"
                  label="Prompt"
                  className="w-100"
                  variant="outlined"
                  autoComplete="off"
                  rows={10}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={(e) => {}}
                />
              </Box>
              <Button
                className="w-100 p-2 rounded text-light"
                style={{ backgroundColor: "var(--collection-1-primary-main)" }}
                onClick={updatePrompt}
              >
                Update Prompt
              </Button>
            </Box>
          </List>
        </Drawer>
      </Box>
      {isLoading && <LoadingOverlay text={loadingText} />}
    </Box>
  );
};

export default VideoPlayground;
