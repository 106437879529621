// SessionIdManager.jsx
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

const SessionIdManager = ({ children }) => {
  // document.body.style.zoom = '90%';

  useEffect(() => {
    const sessionId = Cookies.get('session_id');
    console.log("Into the session id manager :")
    console.log("Sessionid is ====== ",sessionId)
    if (!sessionId || sessionId === "undefined") {
      // If there is no session ID in cookies, make an API call to create one
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSessionId`)
        .then(response => response.json())
        .then(data => {
          const { session_id, expiration_time } = data;

          // Set the session ID in cookies
          Cookies.set('session_id', session_id, { expires: new Date(expiration_time) });
        })
        .catch(error => {
          console.error('Error fetching or creating session ID:', error);
        });
    }
  });

  return <>{children}</>;
};

export default SessionIdManager;
