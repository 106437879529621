import React from 'react'
import step1 from '../../../assets/step1.webp'
import step2 from '../../../assets/step2.webp'
import step3 from '../../../assets/step3.webp'
import step4 from '../../../assets/step4.webp'
import '../steps/style.css'
import { useState, useRef, useEffect } from 'react'

const FourSteps = () => {
    const pricingRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const div1 = document.getElementById("price-movement1")
                    if (div1 && window.innerWidth > 768) {
                        if (entry.isIntersecting) {
                            if (!div1.classList.contains("price-movement")) {
                                div1.classList.add("price-movement");
                                div1.style.top = "0";
                            }
                        } else {
                            console.log('Element is out of the viewport!')
                            div1.classList.remove("price-movement")
                        }
                    }
                });
            },
            {
                root: null, // Use the viewport as the root
                rootMargin: '0px', // No margin
                threshold: 0.5, // 0 (element is completely out of view) to 1 (element is completely in view)
            }
        );

        if (pricingRef.current) {
            observer.observe(pricingRef.current);
        }

        return () => {
            if (pricingRef.current) {
                observer.unobserve(pricingRef.current);
            }
        };
    }, [pricingRef]);

    return (
        <div className='instruction bg-white py-5 max-1440'>
            <div className="steps d-flex flex-lg-column" id="price-movement1">
                <div className="text-center w-100 which-segment-is">Four simple steps to Youtube <span className='text-wrapper-3'>Success</span></div>
                <div className="col d-flex flex-column flex-md-row">
                    <div className="step mx-auto shadow-lg">
                        <img
                            className="element-modern"
                            alt="Element modern"
                            src={step1}
                        />
                        <div className="content-3">
                            <div className="text-wrapper-4">The First Step</div>
                            <p className="text-wrapper-5">
                                Answer several questions from AI. This will make it easier for Creator Stride to validate your
                                interest and talent in a field
                            </p>
                        </div>
                    </div>
                    <div className="step mx-auto shadow-lg">
                        <img
                            className="element-modern"
                            alt="Element modern"
                            src={step2}
                        />
                        <div className="content-3">
                            <div className="text-wrapper-4">Second Step</div>
                            <p className="text-wrapper-5">
                                Use AI to identify your area of ​​expertise. This is useful for finding out how much potential you
                                have in a field
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col d-flex flex-column flex-md-row">
                    <div className="step mx-auto shadow-lg">
                        <img
                            className="element-modern"
                            alt="Element modern"
                            src={step3}
                        />
                        <div className="content-3">
                            <div className="text-wrapper-4">Third Step</div>
                            <p className="text-wrapper-5">
                                Get AI-generated video scripts to simplify your video production process
                            </p>
                        </div>
                    </div>
                    <div className="step mx-auto shadow-lg">
                        <img
                            className="element-modern"
                            alt="Element modern"
                            src={step4}
                        />
                        <div className="content-3">
                            <div className="text-wrapper-4">Step Four</div>
                            <p className="text-wrapper-5">
                            Analyze the analytics of your channel and evaluate your performance, while also comparing it with your competitors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourSteps