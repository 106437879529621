import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Diversity1 } from "@mui/icons-material";
import "./EmailVerification.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";

const EmailVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const session_id = Cookies.get("session_id")

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };


  useEffect(()=>{
    try{
      fetch(`${process.env.REACT_APP_SERVER_URL}/checkEmailVerifiedStatus`,{
        method:"POST",
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({session_id})
      }).then(response=>response.json())
      .then(responseData=>{
        console.log("response data === ",responseData)
        if(responseData.status==="success"){
          if(responseData.verify_status==="verified"){
            navigate('/dashboard')
          }
        }
      })
    }catch(error){
      throw new Error(error)
    }
  },[])

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(255, 245, 235)",
        }}
      >
        <Box
          className="px-4 px-sm-5 py-4 py-sm-5"
          style={{
            backgroundColor: "var(--collection-1-primary-main)",
            color: "white",
            width: "fit-content",
            borderBottomLeftRadius: "50%",
            position: "absolute",
            right: "0",
          }}
        ></Box>
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Box className="d-flex flex-column justify-content-between h-100">
            <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5"></Box>
            <Box>
              <Box
                className="col col-10 col-sm-9 col-md-7 col-lg-5 col-xl-4 mx-auto rounded px-4 px-md-5 py-5 rounded"
                style={{
                  minHeight: "500px",
                  borderTop: "15px solid var(--collection-1-primary-main)",
                  border: "1px solid var(--collection-1-primary-main)",
                }}
              >
                <Box className="text-center mb-2">
                  <img
                    className="text-center"
                    src={logo}
                    alt="logo"
                    height="30px"
                    width="auto"
                    onClick={() => navigate("/")}
                  />
                </Box>
                <Typography variant="h4" className="py-3 text-center">
                  Email Verification
                </Typography>
                <Typography className="py-2">Hi Creator !!,</Typography>
                <Typography className="py-2">
                  Thank you for registering with creatorstride.com. An email has
                  been sent to the address you provided. To complete the
                  registration process, please click on the verification link in
                  the email.
                </Typography>
                <Typography>Regards,</Typography>
                <Typography>Creatorstride Team.</Typography>
                <Typography className="text-center pt-3">
                  © 2023 creatorstride.com
                </Typography>
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-between px-5"
              // style={{ height: "5%" }}
            >
              <Box
                className="d-flex justify-content-between"
                style={{ height: "auto" }}
              ></Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
              style={{
                minHeight: "50px",
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EmailVerification;
