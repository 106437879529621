import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Signup.css'
import paypal from '../../assets/paypal.png'
import visa from '../../assets/visa.png'
import line from '../../assets/line_.png'
import './Payment.css'
import {useState,useEffect} from 'react'
import Cookies from 'js-cookie'
import {Navigate} from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import './Plan.css'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';




function Plan(){
    const [plan,SetPlan]=useState("")
    const [canProceed,setCanProceed]=useState(false)
    const location= useLocation()
    const navigate = useNavigate();

    const planHandler = event =>{

        const planContainers = document.querySelectorAll('[data-type="plan-container"]');
        planContainers.forEach((container) => {
            if (container.classList.contains("bg-db")) {
                container.classList.remove("bg-db");
            }
        });

        let  plan_div=event.target.closest("[data-plan]")
        if(plan_div){
            let user_plan=plan_div.getAttribute("data-plan")
            if (plan_div.classList.contains("bg-db")) {
                plan_div.classList.remove("bg-db");
            } else {
                plan_div.classList.add("border", "border-grey","bg-db");
            }
            SetPlan(user_plan)
            setCanProceed(true)
        }else{
            alert("notfound")
        }
       
    }


    const planSubmitHandler=async ()=>{
        if(plan===""){
            toast.info("Please select a valid Plan!!",{position:toast.POSITION.TOP_CENTER})
            return
        }
        const session_id=Cookies.get('session_id')
        const payload={
            session_id:session_id,
            plan:plan
        }
        try{
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/updateUserPlan`,{
                method:"POST",
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(payload)
            })
            if(!response.ok){
                throw new Error(`Network status was not ok :${response.status}`)
            }
            return response.json()
            .then(response=>{
              console.log("success")
              console.log(response)
              console.log(response.status)
              if(response.status==="success"){
                  navigate('/payment')
              }else if(response.status==="Unauthorized"){
                
                navigate('/')
              }else{
                //  toast.error("Something went wrong!!",{position:toast.POSITION.TOP_CENTER})
              }
            })
            .catch(error=>{
                console.log('error')
                console.log(error)
            })
        }catch(error){
            console.log("error")
            console.log(error)
        }
    }

    const divStyle={
        backgroundColor:'var(--collection-1-primary-main)'
    }



    return(
       <>
          <div class="d-flex align-items-center body-style">
          {/* <div class="bg-white rounded d-flex col-lg-9 col-md-6 col-sm-8 col-11 h-600 mx-auto"> */}
          <div class="bg-white rounded d-flex col-lg-9 col-sm-10 col-md-10 mx-auto px-sm-5 col-12 px-0 custom-shadow">
             <div class="py-sm-3 mx-auto px-sm-4 px-2 py-1">
              <div class="d-flex justify-content-center px-3 pt-5 pb-2">
                  <div class="d-flex align-items-center flex-column">
                      <input class="custom-radio" type="radio" checked/>
                     <p class="py-2">Profile</p>
                  </div>
                 <div class="mx-4 pl-1">
                      <img src={line} alt="line icon" class="line-img"/>
                 </div>
                  <div class="d-flex align-items-center flex-column">
                      <input id="custom-radio-button" class="custom-radio" type="radio" checked/>
                      <p class="py-2">Payment</p>
                  </div>
              </div>
              <div>
              <div>
                <div>
                    <div className='row d-flex justify-content-around w-100 flex-column flex-lg-row m-0'>
                        <div className="px-4 py-5 payment-div rounded w-lg-30 text-center w-sm-100 my-lg-0 my-sm-3 my-xsm-3 cursor-pointer" style={divStyle} data-plan="trial" data-type="plan-container" onClick={planHandler}>
                             <div><h2>Trial</h2></div>
                             <div><p className='mb-0'><strike>15$</strike></p></div>
                             <div><h1>0$</h1></div>
                             <div><p>per month</p></div>
                             <div>
                                <ul>
                                    <li>AI generated Customizable 100 replies.</li>
                                    <li>0.10$ per response after the first 100 responses.</li>
                                    <li>Commenter's subscription status remains private.</li>
                                </ul>
                             </div>
                        </div>
                        <div className="px-4 py-5 payment-div rounded w-lg-30 text-center w-sm-100 my-lg-0 my-sm-3 my-xsm-3 cursor-pointer" style={divStyle} data-plan="basic" data-type="plan-container" onClick={planHandler}>
                              <div><h2>Basic</h2></div>
                              <div><h1>69$</h1></div>
                              <div><p>per month</p></div>
                              <div>
                                <ul>
                                    <li>AI generated Customizable 500 replies.</li>
                                    <li>0.10$ per response after the first 500 responses.</li>
                                    <li>Commenter's subscription status remains private.</li>
                                </ul>
                             </div>
                        </div>
                        <div className="px-4 py-5 payment-div rounded w-lg-30 text-center w-sm-100 my-lg-0 my-sm-3 my-xsm-3 cursor-pointer" style={divStyle}  data-plan="premium" data-type="plan-container" onClick={planHandler}>
                              <div><h2>Premium</h2></div>
                              <div><h1>99$</h1></div>
                              <div><p>per month</p></div>
                              <div>
                                <ul>
                                    <li>AI generated Customizable 1000 replies.</li>
                                    <li>0.15$ per response after the first 1000 responses.</li>
                                    <li>Commenter's subscription status remains visible.</li>
                                </ul>
                             </div>
                        </div>
                    </div>
                </div>
                <div> 
                  <div class="py-3 text-center my-4">
                    <button class="s-in-btn" onClick={planSubmitHandler}>Proceed to Pay</button>
                 </div>
                </div>
              </div>
        </div>
             </div>
          </div>
      </div>
      </>
    )
}


export default Plan