import Header from "../../homePage/header/Header";
import Content from "../content/Content";
import Footer from "../../homePage/footer/Footer";
import PersonalizedContent from "../personalizedcontent/PersonalizedContent";
import GrowthContent from "../growthcontent/GrowthContent";
import EngagementContent from "../engagementcontent/EngagementContent";
import AdvancedAnalytics from "../advancedanalytics/AdvancedAnalytics";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";

const Features = ({
  showLoginModal,
  showRegisterModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div className="App">
      <Header
        showLoginModal={showLoginModal}
        showRegisterModal={showRegisterModal}
        handleLoginClick={handleLoginClick}
        handleRegisterClick={handleRegisterClick}
        handleCloseLoginModal={handleCloseLoginModal}
        handleCloseRegisterModal={handleCloseRegisterModal}
      />
        <div className="bg-light-st">
          <Content />
        </div>
        <div className="bg-light-st">
          <PersonalizedContent />
        </div>
        <div className="bg-light-st">
          <AdvancedAnalytics />
        </div>
        <div className="bg-light-st">
          <EngagementContent />
        </div>
        <div className="bg-light-st">
          <GrowthContent />
        </div>
      <Footer />
    </div>
  );
};

export default Features;
