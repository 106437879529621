import React from "react";
import "./style.css";
import line from '../../../assets/Line 6.png'
import Capa from '../../../assets/Capa_1.webp'

const GrowthContent = () => {
    return (
        <div className="py-3 py-md-5" style={{ backgroundColor: '#fafbff',maxWidth: "1440px", margin: "auto" }}>
            <div className="py-2 py-md-5">
                <div className="personalized-content px-4 py-4">
                    <div className="d-flex justify-content-between flex-xl-row flex-column gap-4 mx-auto px-3 px-md-5">
                        <div className="px-md-5">
                            <div className="d-flex justify-content-between flex-xl-row flex-column gap-4">
                                <div className="content mx-0 mx-lg-5">
                                    <div className="title">
                                        <div className="text-wrapper">The features we offer</div>
                                        <div className="div">Personalized Growth Plan</div>
                                        <p className="p">CreatorStride allows you to quickly and strategically design your channel growth plan</p>
                                    </div>
                                    <div className="content-2">
                                        <img className="line" alt="Line" src={line} />
                                        <div className="feature">
                                            <div className="circle">
                                                <div className="ellipse" />
                                            </div>
                                            <p className="text-wrapper-2">
                                                Custom suggestions on video posting frequency and optimal content scheduling.
                                            </p>
                                        </div>
                                        <div className="feature">
                                            <div className="ellipse-wrapper">
                                                <div className="ellipse" />
                                            </div>
                                            <p className="text-wrapper-2">Tailored advice for long-term channel growth and audience retention.</p>
                                        </div>
                                        <div className="feature">
                                            <div className="div-wrapper">
                                                <div className="ellipse" />
                                            </div>
                                            <p className="text-wrapper-2">
                                                Strategic planning tools to help you consistently deliver compelling content.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-auto">
                                    <img className="mobile-personalized-img capa mx-0 mx-lg-5 mx-auto" alt="Capa" src={Capa}  height="auto" width="456"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrowthContent