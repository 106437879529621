import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import Gradient from "../../assets/black_gradient.png";
import { useNavigate } from "react-router-dom";

const FeaturedBlogCard = ({ imageUrl, title }) => {
    const navigate=useNavigate()
    const [loading, setLoading] = useState(true);
    const handleImageLoad = () => {
      setLoading(false);
    };

  return (
    <>
      <Box class="shadow-lg rounded cursor-pointer" style={{ position: "relative" }} onClick={()=>navigate(`/blog/${title}`)}>
        <img src={imageUrl} alt="blog-image" height="auto" width="100%" />
        <img
          src={Gradient}
          style={{ display: loading ? 'none' : 'block',position: "absolute", bottom: "0", left: "0" }}
          onLoad={handleImageLoad}
          alt="gradient"
          width="100%"
          height="100%"
        />
        <Box class='w-100' style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <Typography class="h3 px-5 py-2" style={{ color: "white" }}>
            {title}
          </Typography>
          <Typography
            className="w-100 px-4 py-2 cursor-pointer"
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
              zIndex: "999",
            }}
          >
            Featured
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default FeaturedBlogCard;
