// Login.js

import React, { useEffect, useState } from "react";
import "./style.css";
import google from "../../../assets/Google.svg";
import apple from "../../../assets/Apple.svg";
import eye from "../../../assets/Eye.svg";
import line from "../../../assets/Line.png";
import hideEye from "../../../assets/invisible.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { Box, IconButton } from "@mui/material";
import close from "../../../assets/delete.png";
import CloseIcon from "@mui/icons-material/Close";
import { FaRProject } from "react-icons/fa";
import LoadingOverlay from "../../dashboard/LoadingOverlay";

const Register = ({
  showLoginModal,
  showRegisterModal,
  handleLoginClick,
  handleRegisterClick,
  handleCloseLoginModal,
  handleCloseRegisterModal,
}) => {
  // const Register = ({ handleLoginClick, setShowRegister, role }) => {
  const [username, setUsername] = useState("");
  const [emailID, setEmailID] = useState("");
  const [channelID, setChannelID] = useState();
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inputType, setInputType] = useState("password");
  const navigate = useNavigate();
  const session_id = Cookies.get("session_id");
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState();
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    const body = document.querySelector("body");

    if (!showRegisterModal || !showLoginModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }
    return () => {
      body.style.overflow = "visible";
    };
  }, [showLoginModal, showRegisterModal]);

  const hashString = (password) => {
    const hashedString = CryptoJS.SHA256(password).toString();
    return hashedString;
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      console.log("The login response is : ", codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const setCustomExpiryCookie = (name, value, expirationDate) => {
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  };

  const authSubmitHandler = async (payload) => {
    setLoading(true)
    console.log("Into the auth submit handler function");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addGoogleUser`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      setLoading(false)
      return responseData;
    } catch (error) {
      setLoading(false)
      console.log("error");
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          setProfile(res.data);
          let authResponse = res.data;
          authResponse.id = hashString(authResponse.id);
          console.log("The auth response is : ", authResponse);
          const response = await authSubmitHandler(authResponse);
          if (response.status === "success") {
            // console.log("Success");
            // console.log(response);
            toast.success("User created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            // Cookies.set("session_id", response.session_id);
            // Cookies.set("expiry", response.expiry);
            // const expiry_date = new Date(response.expiry);
            // Cookies.set("isLoggedIn",true)
            // setCustomExpiryCookie(
            //   "session_id",
            //   response.session_id,
            //   expiry_date
            // );
            // Cookies.set("user_id", response.user_id);
            // window.postMessage(
            //   { action: "messageFromWebPage", data: response.session_id },
            //   "*"
            // );
            navigate(`/emailVerification`);
          } else if (response.status === "error") {
            console.log("error");
            console.log(response);
            // toast.error("Something Went Wrong!!", { position: toast.POSITION.TOP_CENTER })
          } else if (response.status === "DUPLICATE_EMAIL") {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };

  const emailHandler = (event) => {
    setEmailID(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };

  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };

  const changeInputType = () => {
    let inputButton = document.getElementById("passwordInput");
    let input_type = inputButton.getAttribute("type");
    let viewerButton = document.getElementById("viewerButton");
    if (input_type == "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    return regex.test(email);
  };

  const checkChannelId = async (channelID) => {
    try {
      const payload = { channelID };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/checkChannelId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.text();
    } catch (error) {
      console.error("Error:", error);
      return "Error";
    }
  };

  const createUserAccount = async (accountData) => {
    setLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/createUserAccount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(accountData),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      setLoading(false)
      return response.json();
    } catch (error) {
      setLoading(false)
      console.error("Error:", error);
      return { status: "error" };
    }
  };

  const FormSubmitHandler = async (event) => {
    if(loading){
      return
    }
    event.preventDefault();
    if (!firstName || !lastName || !emailID || !password || !username) {
      toast.error("Fill All The Fields!!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const account_payload = {
      username,
      password: hashString(password),
      firstName,
      lastName,
      emailID,
      session_id,
    };

    const userResponse = await createUserAccount(account_payload);
    console.log("User Response:", userResponse);

    if (userResponse.status === "success") {
      toast.success('User created successfully', { position: toast.POSITION.TOP_RIGHT });
      // const expiry_date = new Date(userResponse.expiry);
      // setCustomExpiryCookie("session_id", session_id, expiry_date);
      // Cookies.set("user_id", userResponse.user_id);
      // window.postMessage(
      //   { action: "messageFromWebPage", data: userResponse.session_id },
      //   "*"
      // );
      // Cookies.set("isLoggedIn", true);
      navigate(`/emailVerification`);
    } else if (userResponse.status === "error") {
      toast.error('Something went wrong !!', { position: toast.POSITION.TOP_RIGHT });
    } else if (userResponse.status === "DUPLICATE_EMAIL") {
      toast.error(userResponse.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="modal-overlay">
      {loading && <LoadingOverlay/>}
      <div
        className="login-container1 col col-10 col-sm-8 col-md-7 col-lg-5 col-xl-4 col-xxl-3"
        style={{ padding: "35px" }}
      >
        <div
          className="d-flex justify-content-end w-100"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <IconButton
            //   style={closeIconStyle}
            onClick={() => handleCloseRegisterModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="title text-center my-3">
          <div className="w-100 my-2 d-flex justify-content-center">
            <div
              className="text-wrapper text-center"
              style={{ fontWeight: "700", fontSize: "20px" }}
            >
              Welcome to CreatorStride
            </div>
          </div>
          <p className="let-s-build-your">
            Let&#39;s build your channel to be better
          </p>
        </div>
        <div className="input">
          <div className="d-flex justify-content-between gap-2">
            <div className="w-50 div">
              <div className="text-wrapper-4" style={{ textAlign: "left" }}>
                First Name
              </div>
              <input
                className="input-2"
                placeholder="First Name"
                type="text"
                onChange={firstNameHandler}
              />
            </div>
            <div className="w-50 div">
              <div className="text-wrapper-4" style={{ textAlign: "left" }}>
                Last Name
              </div>
              <input
                className="input-2"
                placeholder="Last Name"
                type="text"
                onChange={lastNameHandler}
              />
            </div>
          </div>
          <div className="div w-100">
            <div className="text-wrapper-4" style={{ textAlign: "left" }}>
              Username
            </div>
            <input
              className="input-2 w-100"
              placeholder="Username"
              type="text"
              onChange={usernameHandler}
            />
          </div>
          <div className="div w-100">
            <div className="text-wrapper-4" style={{ textAlign: "left" }}>
              Email
            </div>
            <input
              className="input-2 w-100"
              placeholder="Enter Email"
              type="email"
              onChange={emailHandler}
              autoComplete="off"
            />
          </div>
          <div className="div w-100">
            <div class="div w-100">
              <div className="text-wrapper-4" style={{ textAlign: "left" }}>
                Password
              </div>
              <div className="input-2 d-flex justify-content-between">
                <input
                  className="border-0 w-100"
                  type={inputType}
                  placeholder="Password"
                  id="passwordInput"
                  onChange={passwordHandler}
                  autoComplete="off"
                  required
                />
                <img
                  className="img"
                  alt="Eye"
                  src={inputType === "password" ? eye : hideEye}
                  onClick={changeInputType}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="register cursor-pointer" onClick={FormSubmitHandler}>
          <div className="text-wrapper-2 cursor-pointer text-light">
            Register
          </div>
        </div>
        {/* <div className="register-2">
          <img className="line" alt="Line" src={line} />
          <div className="text-wrapper-6">Or Register With</div>
          <img className="line" alt="Line" src={line} />
        </div> */}
        <div className="button-container" style={{ backgroundColor: 'transparent' }} onClick={()=>login()}>
          <div className="google cursor-pointer">
            <img className="img" alt="Google" src={google} />
            <div className="text-wrapper-7 cursor-pointer">Google</div>
          </div>
          {/* <div className="google cursor-pointer">
            <img className="img" alt="Apple" src={apple} />
            <div className="text-wrapper-7 cursor-pointer">Apple ID</div>
          </div>  */}
        </div> 
        <p className="don-t-have-an">
          <span className="span">Already Have Account? </span>
          <span
            className="text-wrapper-8 cursor-pointer"
            style={{ color: "var(--collection-1-primary-main)" }}
            onClick={() => handleLoginClick()}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
