import React from "react";
import {
  Box,
  Container,
  Typography,
  ButtonRadio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import QuestionnaireSuccess from "./QuestionnaireSuccess";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ConstructionOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateUserHistory } from "../../redux/actions/actions";
import SideBarMenu from "./Sidebar";
import UserLoginAvatar from "../User/UserLoginAvatar";
import { GrNext } from "react-icons/gr";
import { IoChevronBack } from "react-icons/io5";

const Questionnaire = () => {
  const dispatch = useDispatch();
  const userReduxHistory = useSelector(
    (state) => state.userQuestionnaireHistory
  );
  let { id } = useParams();
  const [questionnarieData, setQuestionnaireData] = useState([]);

  const [answerData, setAnswerData] = useState([]);
  const [option, setOption] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [answerPosted, setAnswerPosted] = useState(false);
  const [textData, setTextData] = useState("");
  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] = useState(
    false
  );
  const [showQuestionnaireSuccess, setShowQuestionnaireSuccess] = useState(
    false
  );
  const [userRole, setUserRole] = useState(null);

  const [currentSection, setCurrentSection] = useState(null);
  const [sectionQuestionsData, setSectionQuestionsData] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [totalSections, setTotalSections] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const session_id = Cookies.get("session_id");
  const navigate = useNavigate();

  useEffect(() => {
    setAnswerData("");
    userReduxHistory.map((data, index) => {
      if (data?.title === sectionQuestionsData[currentQuestion]?.title) {
        setAnswerData(data.answer);
        console.log("THe answer data is : ", data?.answer);
        console.log("options is : ", data?.options);
        const differentElement = data?.answer?.find(
          (element) =>
            element !== "Others, please specify" &&
            !data?.options.includes(element)
        );
        console.log("Differenet element is : ", differentElement);
        if (differentElement) {
          setTextData(differentElement);
        } else {
          setTextData("");
        }
      }
    });
  }, [currentQuestion]);

  useEffect(() => {
    const sectionQuestionsData_ =
      totalSections[currentSection]?.questionnaireData;
    console.log("Section questions data : ", sectionQuestionsData_);
    setSectionQuestionsData(sectionQuestionsData_);
    dispatch(updateUserHistory(sectionQuestionsData_));
    setTextData("");
    setAnswerData([]);
  }, [currentSection]);

  useEffect(() => {
    console.log("User redux history is : ", userReduxHistory);
  }, [userReduxHistory]);

  useEffect(() => {
    const payload = {
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchQuestionnaireProgress`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
          if (responseData.status === "Unauthorized") {
            navigate("/");
            Cookies.remove("session_id");
            Cookies.set("isLoggedIn", false);
          } else if (responseData.status === "success") {
            if(responseData?.message){
              if(responseData?.message === "Completed"){
                navigate("/dashboard");
                return
              }
            }
            console.log(
              "The fetch questinnnaire progress response is : ",
              responseData
            );
            const current_question = responseData?.currentQuestion;
            setCurrentQuestion(responseData?.currentQuestion);
            setCurrentSection(responseData?.currentSection);
            setSectionsData(responseData?.sectionsData);
            setUserRole(responseData?.userRole);
            setTotalSections(responseData?.totalSections);
            const currentSection_ = responseData?.currentSection;
            const sectionQuestionsData_ =
              responseData?.totalSections[currentSection_]?.questionnaireData;
            console.log("Section questions data : ", sectionQuestionsData_);
            setSectionQuestionsData(sectionQuestionsData_);
            dispatch(updateUserHistory(sectionQuestionsData_));
            if (
              sectionQuestionsData_[responseData?.currentQuestion]?.answer
                ?.length > 0
            ) {
              setAnswerData(
                sectionQuestionsData_[responseData?.currentQuestion]?.answer
              );
            }
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const widthPercentage = `${
    ((currentQuestion + 1) / sectionQuestionsData?.length) * 100
  }%`;

  const quizLoaderStyle = {
    height: "3px",
    width: widthPercentage,
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const postQuestionnaireData = (currentQuestion) => {
    if (textData === "" && answerData.includes("Others, please specify")) {
      return;
    }

    if (textData) {
      console.log("Text data length :: ",textData.length)
      answerData.push(textData);
    }
    userReduxHistory.map((data, index) => {
      if (data?.title === sectionQuestionsData[currentQuestion]?.title) {
        data.answer = answerData;
        dispatch(updateUserHistory(userReduxHistory));
      }
    });
    if (
      sectionsData.indexOf(currentSection) === sectionsData?.length - 1 &&
      currentQuestion + 1 === sectionQuestionsData?.length
    ) {
      setIsLoading(true);
    }

    if (currentQuestion < sectionQuestionsData?.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }

    setAnswerPosted(false);
    if (currentQuestion + 1 === questionnarieData?.length) {
      setIsLoading(true);
    }

    const payload = {
      currentQuestion,
      sectionsData,
      currentSection,
      answerData,
      textData,
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/postQuestionnaireJsonData`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          setIsLoading(false);
          if (
            responseData?.status === "success" &&
            !responseData?.isCompleted
          ) {
            setCurrentSection(responseData?.currentSection);
            if (responseData?.currentSection !== currentSection) {
              setCurrentQuestion(0);
              setAnswerData([]);
            }
          } else if (responseData?.status === "Unauthorized") {
            navigate("/");
            Cookies.remove("session_id");
            Cookies.set("isLoggedIn", false);
          } else if (
            responseData?.status === "success" &&
            responseData.isCompleted
          ) {
            setShowQuestionnaireSuccess(true);
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  };

  function optionChecker(option) {
    if (option === "Others, please specify") {
      userReduxHistory.map((data, index) => {
        if (data.title === sectionQuestionsData[currentQuestion].title) {
          const differentElement = data?.answer?.find(
            (element) => !answerData.includes(element)
          );

          return true; // or false based on your requirement
        }
      });
    } else {
      return answerData.includes(option);
    }
  }

  useEffect(() => {
    console.log("Answer data from the useEffect is : ", answerData);
  }, [answerData]);

  useEffect(() => {
    console.log("questionnarie data from use effect : ", questionnarieData);
  }, [questionnarieData]);

  const sectionWidthPercentage =
    ((sectionsData?.indexOf(currentSection) + 1) / sectionsData?.length) * 100;

  const sectionLoaderStyle = {
    height: "3px",
    width: `${sectionWidthPercentage}%`,
    backgroundColor: "var(--collection-1-primary-main)",
  };

  return (
    <>
      <Box
        className=""
        style={{
          backgroundColor: "rgb(255, 245, 235)",
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <Box className="d-flex flex-column justify-content-between h-100">
          <Box class="d-flex flex-column flex-sm-row align-items-start justify-content-between px-5 pt-5 gap-3 gap-sm-0">
            <Box onClick={() => navigate("/")}>
              <img src={logo} alt="logo" height="30px" width="auto" />
            </Box>
            <Box className="d-flex">
              {/* <UserLoginAvatar style={{ color: "black" }} textColor="black" /> */}
              <Box className="w-100 py-2">
                {currentSection} {currentQuestion + 1}/
                {sectionQuestionsData?.length}
                <Box class="rounded" style={quizLoaderStyle}></Box>
              </Box>
            </Box>
          </Box>
          {!isLoading ? (
            <Box className="col col-12">
              <Box className="d-md-flex mx-auto align-items-center justify-content-center d-none px-5">
                <Box className="h-100 col col-4 d-flex flex-column justify-content-center align-items-stretch gap-3">
                  <Box>
                    <Typography
                      className="py-2"
                      style={{
                        fontSize: "var(--h-large-font)",
                        fontWeight: "500",
                      }}
                    >
                      {currentSection} :-
                    </Typography>
                    <Typography className="py-3">
                      {sectionQuestionsData[currentQuestion]?.title}
                    </Typography>
                  </Box>
                  {userRole === "Owner" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup>
                          {sectionQuestionsData[currentQuestion]?.options.map(
                            (option, index) => (
                              <FormControlLabel
                                key={index}
                                control={<Checkbox />}
                                checked={answerData.includes(option)}
                                label={option}
                                onChange={() => {
                                  if (answerData.includes(option)) {
                                    // Remove the option using a new array
                                    const updatedAnswerData = answerData.filter(
                                      (item) => item !== option
                                    );
                                    console.log(updatedAnswerData);
                                    setAnswerData(updatedAnswerData);
                                  } else {
                                    if(answerData.length < 3){
                                      setAnswerData([...answerData, option]);
                                    }
                                  }
                                }}
                              />
                            )
                          )}
                        </FormGroup>
                      </FormControl>
                      {answerData.includes("Others, please specify") && (
                        <Textarea
                          value={textData}
                          onChange={(e) => setTextData(e.target.value)}
                        />
                      )}
                    </>
                  ) : (
                    <Textarea
                      style={{ fontSize: "0.7rem" }}
                      color="neutral"
                      disabled={false}
                      minRows={5}
                      size="md"
                      variant="outlined"
                      placeholder={
                        questionnarieData[currentQuestion]?.placeholder
                      }
                      onChange={(e) => setAnswerData(e.target.value)}
                      value={answerData}
                    />
                  )}
                </Box>
                <Box className="d-flex flex-column align-items-end">
                  <img
                    src={business}
                    width="450px"
                    height="auto"
                    alt="business"
                  />
                </Box>
              </Box>
              <Box
                className="d-flex flex-column mx-auto align-items-center justify-content-center d-block d-md-none py-5 gap-4"
                style={{ backgroundColor: "rgb(255, 245, 235)" }}
              >
                <Box className="d-flex flex-column align-items-center">
                  <img
                    src={business}
                    width="80%"
                    height="auto"
                    alt="business"
                    className="mx-auto"
                  />
                </Box>
                <Box className="h-100 w-75 d-flex flex-column justify-content-center align-items-stretch gap-3">
                  <Box>
                    <Typography
                      className="py-2"
                      style={{
                        fontSize: "var(--h-large-font)",
                        fontWeight: "500",
                      }}
                    >
                      {currentSection} :-
                    </Typography>
                    <Typography className="py-3">
                      {sectionQuestionsData[currentQuestion]?.title}
                    </Typography>
                  </Box>
                  {userRole === "Owner" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup>
                          {sectionQuestionsData[currentQuestion]?.options.map(
                            (option, index) => (
                              <FormControlLabel
                                key={index}
                                control={<Checkbox />}
                                checked={answerData.includes(option)}
                                label={option}
                                onChange={() => {
                                  console.log("Clicked ...............")
                                  if (answerData.includes(option)) {
                                    // Remove the option using a new array
                                    const updatedAnswerData = answerData.filter(
                                      (item) => item !== option
                                    );
                                    console.log(updatedAnswerData);
                                    setAnswerData(updatedAnswerData);
                                  } else {
                                    if(answerData.length < 3){
                                      setAnswerData([...answerData, option]);
                                    }
                                  }
                                }}
                              />
                            )
                          )}
                        </FormGroup>
                      </FormControl>
                      {answerData.includes("Others, please specify") && (
                        <Textarea
                          value={textData}
                          onChange={(e) => setTextData(e.target.value)}
                        />
                      )}
                    </>
                  ) : (
                    <Textarea
                      style={{ fontSize: "0.7rem" }}
                      color="neutral"
                      disabled={false}
                      minRows={5}
                      size="md"
                      variant="outlined"
                      placeholder={
                        questionnarieData[currentQuestion]?.placeholder
                      }
                      onChange={(e) => setAnswerData(e.target.value)}
                      value={answerData}
                    />
                  )}
                  {/* <Box className="d-flex justify-content-between">
                    <Button
                      className={`${
                        currentQuestion <= 0 ? "d-none" : "d-block"
                      }`}
                      style={{
                        backgroundColor: "var(--collection-1-primary-main)",
                        color: "white",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        if (currentQuestion > 0) {
                          setCurrentQuestion((prev) => prev - 1);
                        }
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "var(--collection-1-primary-main)",
                        color: "white",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        if (currentQuestion <= questionnarieData?.length - 1) {
                          if (!answerData) {
                            return;
                          }
                          postQuestionnaireData(currentQuestion);
                          // if (currentQuestion < questionnarieData.length - 1) {
                          //   setCurrentQuestion((prev) => prev + 1);
                          // }
                        }
                      }}
                    >
                      {currentQuestion + 1 === questionnarieData?.length
                        ? "Submit"
                        : "Next"}
                    </Button>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box>
            <Box style={{backgroundColor:'rgb(255, 245, 235)'}}>
              <Box className="d-flex justify-content-between px-2 px-md-5 py-5">
                <Box className="col col-5 col-sm-3 col-xxl-3">
                  <Button
                    className={`${
                      currentQuestion <= 0 ? "d-block" : "d-block"
                    } w-100 py-3 cursor-pointer`}
                    style={{
                      backgroundColor: "var(--collection-1-primary-main)",
                      color: "white",
                      width: "fit-content",
                      opacity: `${currentQuestion <= 0 ? '0.5' : '1'}`
                    }}
                    disabled={currentQuestion <= 0}
                    onClick={() => {
                      if (currentQuestion > 0) {
                        setCurrentQuestion((prev) => prev - 1);
                      }
                    }}
                  >
                    <IoChevronBack
                      className="mx-2"
                      style={{ fontWeight: "800" }}
                    />
                    Back
                  </Button>
                </Box>
                <Box className="col col-5 col-sm-3 col-xxl-3">
                  <Button
                    className="w-100 py-3 cursor-pointer"
                    style={{
                      backgroundColor: "var(--collection-1-primary-main)",
                      color: "white",
                      width: "fit-content",
                    }}
                    onClick={() => {
                      if (currentQuestion <= sectionQuestionsData?.length - 1) {
                        console.log("Into the if condition in button");
                        if (!answerData || answerData.length === 0) {
                          console.log("returning ----->");
                          return;
                        }
                        postQuestionnaireData(currentQuestion);
                      }
                    }}
                  >
                    {currentQuestion + 1 === sectionQuestionsData?.length
                      ? "Submit"
                      : "Next"}
                    <GrNext className="mx-2" style={{ fontWeight: "800" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
              style={{
                minHeight: "50px",
                backgroundColor: "var(--collection-1-primary-main)",
                width: "100%",
                color: "white",
              }}
            >
              © 2023 creatorstride.com. All Rights Reserved.
            </Box>
          </Box>

          {showQuestionnaireSuccess && (
            <QuestionnaireSuccess
              showQuestionnaireSuccess={showQuestionnaireSuccess}
              setShowQuestionnaireSuccess={setShowQuestionnaireSuccess}
            />
          )}
          {isLoading ? <LoadingOverlay /> : null}
        </Box>
      </Box>
    </>
  );
};

export default Questionnaire;
