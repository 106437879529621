import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import logo from "../../assets/quiz_header.png";
import internship from "../../assets/internship.jpg";
import { useEffect, useState } from "react";
import business from "../../assets/quiz_business.png";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MuiTextField } from "../MuiTextField/MuiTextField";
import { MuiTextMessage } from "../MuiTextField/MuiTextMessage";
import contactUs from "../../assets/contact_us.png";
import { Close, Diversity1 } from "@mui/icons-material";
// import "./Internship.css";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate } from "react-router-dom";
// import ContactUsSuccess from "./ContactUsSuccess";
import Cookies from "js-cookie";
import google from "../../assets/Google.svg";
import Register from "../homePage/register/Register";
import eye from "../../assets/eye.png";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CryptoJS from "crypto-js";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import LinearColor from "../headerLoader/headerLoader";
import { toast } from "react-toastify";

const TermsConditions = () => {
  let { id } = useParams();
  const role = "Intern";
  const navigate = useNavigate();
  const session_id = Cookies.get("session_id");
  const [showRegister, setShowRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [failedStatus, setFailedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [name, setName] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [specialization, setSpecilization] = useState(null);
  const [year, setYear] = useState(null);
  const [location, setLocation] = useState(null);
  const [emailID, setEmailID] = useState(null);
  const [position, setPosition] = useState(null);
  const [resume, setResume] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [showHeaderLoader, setShowHeaderLoader] = useState(false);

  var startYear = 2020;
  const postions = [
    "Software Engineering Intern",
    "Product Intern",
    "Quality Testing Intern",
    "Founder’s associate",
    "Marketing Intern",
    "Sales Intern",
    "All Internships",
  ];

  const yearsData = ["1st Year", "2nd Year", "3rd Year", "4th Year"];

  const specializations = [
    "Computer Science and Engineering",
    "Information Technology",
    "Electrical Engineering",
    "Mechanical Engineering",
    "Civil Engineering",
    "Aerospace Engineering",
    "Chemical Engineering",
    "Biotechnology Engineering",
  ];

  const quizLoaderStyle = {
    height: "3px",
    width: "100px",
    backgroundColor: "var(--collection-1-primary-main)",
  };

  const wait = async (n) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n);
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     const isLoggedIn = Cookies.get("isLoggedIn");
  //     if (isLoggedIn === "true") {
  //       await wait(1000);
  //       navigate("/dashboard");
  //     } else {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const formSubmition = async () => {
    setShowHeaderLoader(true);
    setShowDialog(false);
    setAlreadySent(false);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("collegeName", collegeName);
    formData.append("specialization", specialization);
    formData.append("year", year);
    formData.append("location", location);
    formData.append("emailId", emailID);
    formData.append("position", position);
    formData.append("resume", resume);

    try {
      const submitResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/submitInternForm`,
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("Submit response : ", submitResponse);
      const response = await submitResponse.json();
      console.log("Response : ", response);
      setShowHeaderLoader(false);
      if (response?.status === "success") {
        setShowDialog(true);
        if (
          response?.status === "success" &&
          response?.message !== "API successfull"
        ) {
          setAlreadySent(true);
        } else {
          setName("");
          setEmailID("");
          setCollegeName("");
          setLocation("");
          setPosition("");
          setResume("");
          setYear("");
          setSpecilization("");
        }
      }
    } catch (error) {
      console.log("Error occured in form submition : ", error);
      toast.error("Login failed", { position: toast.POSITION.TOP_RIGHT });
    }

    // console.log("Payload ==== ", payload);

    // try {
    //   fetch(`${process.env.REACT_APP_SERVER_URL}/internUserValidation`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   })
    //     .then((response) => response.json())
    //     .then((responseData) => {
    //       if (responseData.status === "failed") {
    //         setTimeout(() => {
    //           setFailedStatus("");
    //         }, 1000);
    //         setFailedStatus("Login Failed");
    //       } else if (responseData.status == "success") {
    //         const session_id_ = responseData.session_id;
    //         Cookies.set("isLoggedIn", true);
    //         Cookies.set("session_id", session_id_, { expires: 30 });
    //         navigate("/dashboard");
    //       }
    //     })
    //     .catch((error) => {
    //       throw new Error(error);
    //     });
    // } catch (error) {
    //   throw new Error(error);
    // }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const hashString = (password) => {
    const hashedString = CryptoJS.SHA256(password).toString();
    return hashedString;
  };

  useEffect(() => {
    console.log("Years data in the use effect : ", yearsData);
  }, [yearsData]);

  return (
    <>
      {!isLoading && (
        <Box
          style={{
            backgroundColor: "rgb(255, 245, 235)",
          }}
        >
          {showHeaderLoader && <LinearColor />}
          <Box
            className="px-4 px-sm-5 py-4 py-sm-5"
            style={{
              backgroundColor: "var(--collection-1-primary-main)",
              color: "white",
              width: "fit-content",
              borderBottomLeftRadius: "50%",
              position: "absolute",
              right: "0",
            }}
          ></Box>
          <Box
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <Box className="d-flex flex-column justify-content-between h-100">
              <Box class="d-flex align-items-center justify-content-between px-md-5 px-3 pt-5">
                <Box className="contact-nav">
                  <img
                    src={logo}
                    alt="logo"
                    height="30px"
                    width="auto"
                    onClick={() => navigate("/")}
                  />
                  <Box className="d-flex justify-content-between align-items-center gap-4 gap-sm-5 py-2 px-2">
                    <NavLink to="/">Home</NavLink>
                    {/* <NavLink to="/features">Features</NavLink>
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/faq">FAQ</NavLink> */}
                    <NavLink to="/features">Features</NavLink>
                    <NavLink to="">Blog</NavLink>
                    <NavLink to="">FAQ</NavLink>
                  </Box>
                </Box>
              </Box>
              <Box className="max-1440 py-5">
                <Typography>
                  <strong>Privacy Policy :</strong>
                  <br />
                  <strong>Effective Date:</strong> March 01, 2024.
                  <br />
                  <br />
                  <Typography>
                    Thank you for using our website. This Privacy Policy
                    explains how we collect, use, and protect information when
                    you use our website, particularly in relation to our use of
                    the YouTube Data API services.
                  </Typography>
                  <br />
                  <br />
                  <strong>1. Information We Collect</strong>
                  <br />
                  <Typography>
                    When you use our website, we collect and store certain
                    information about your visit. This information may include:
                  </Typography>
                  <br />
                  <ul>
                    <li>
                      Usage data: We collect information about how you interact
                      with our website, such as the pages visited, the time
                      spent on each page, and other similar analytics data.
                    </li>
                    <li>
                      Device information: We collect information about the
                      device you use to access our website, including the device
                      type, operating system, and browser type.
                    </li>
                    <li>
                      YouTube Data: In order to provide certain features or
                      services on our website, we collect data from the YouTube
                      Data API. This may include information about YouTube
                      videos, channels, playlists, and other related data.
                    </li>
                  </ul>
                  <br />
                  <strong>2. How We Use Your Information</strong>
                  <br />
                  <Typography>
                    We use the information we collect for various purposes,
                    including:
                  </Typography>
                  <br />
                  <ul>
                    <li>Providing and improving our website and services.</li>
                    <li>Personalizing your experience on our website.</li>
                    <li>Analyzing trends and usage patterns.</li>
                    <li>
                      Communicating with you, responding to your inquiries, and
                      providing customer support.
                    </li>
                    <li>
                      Ensuring compliance with the YouTube API Services Terms of
                      Service and Developer Policies.
                    </li>
                  </ul>
                  <br />
                  <strong>3. YouTube API Services Terms of Service</strong>
                  <br />
                  <Typography>
                    By accessing and using our website, you agree to comply with
                    the YouTube API Services Terms of Service provided by
                    YouTube LLC. You can review the full Terms of Service on the
                    YouTube Developer Site.
                  </Typography>
                  <br />
                  <br />
                  <strong>4. Confidentiality of User Data</strong>
                  <br />
                  <Typography>
                    User Data will be maintained completely confidential and
                    will not be disclosed to any other users. The analytics data
                    presented on our website is based on YouTube data, and we
                    operate in compliance with the policies and terms outlined
                    by YouTube. All analytics data provided to users is derived
                    from YouTube data.
                  </Typography>
                  <br />
                  <br />
                  <strong>5. Account Deletion</strong>
                  <br />
                  <Typography>
                    Users have the option to delete their account at any time.
                    Upon account deletion, all associated data will be
                    permanently deleted within 30 days. We are committed to
                    maintaining the confidentiality of user data and personal
                    information.
                  </Typography>
                  <br />
                  <br />
                  <strong>6. Privacy Policy</strong>
                  <br />
                  <Typography>
                    Before using our website, users must agree to our privacy
                    policy, which:
                  </Typography>
                  <br />
                  <ul>
                    <li>
                      Is prominently displayed and easily accessible to users at
                      all times.
                    </li>
                    <li>
                      Notifies users that our website uses YouTube API Services.
                    </li>
                    <li>
                      References and links to the Google Privacy Policy at
                      http://www.google.com/policies/privacy.
                    </li>
                    <li>
                      Clearly and comprehensively explains to users what user
                      information, including API Data relating to users, our
                      website accesses, collects, stores, and otherwise uses.
                    </li>
                    <li>
                      Clearly and comprehensively explains how our website uses,
                      processes, and shares the user information described
                      above, including how the information is shared with either
                      internal or external parties.
                    </li>
                    <li>
                      Discloses, if applicable, that our website allows third
                      parties to serve content, including advertisements.
                    </li>
                    <li>
                      Discloses, if applicable, that our website stores,
                      accesses, or collects (or allows third parties to do so)
                      information directly or indirectly on or from users’
                      devices, including by placing, accessing, or recognizing
                      cookies or similar technology on users' devices or
                      browsers.
                    </li>
                    <li>
                      If our website accesses or uses Authorized Data, explains
                      that, in addition to our normal procedure for deleting
                      stored data, users can revoke our access to their data via
                      the Google security settings page at
                      https://security.google.com/settings/security/permissions.
                    </li>
                    <li>
                      If our website uses Authorized Data, explains how users
                      can contact us with questions or complaints about our
                      privacy practices.
                    </li>
                  </ul>
                  <br />
                  <strong>7. Your Choices</strong>
                  <br />
                  <Typography>
                    You may choose not to provide certain information to us, but
                    please note that this may limit your ability to use certain
                    features of our website.
                  </Typography>
                  <br />
                  <br />
                  <strong>8. Changes to This Privacy Policy</strong>
                  <br />
                  <Typography>
                    We may update this Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </Typography>
                  <br />
                  <br />
                  <strong>9. Contact Us</strong>
                  <br />
                  <Typography>
                    If you have any questions or concerns about this Privacy
                    Policy or our use of the YouTube Data API services, please
                    contact us at{" "}
                    <a href="mailto:info@creatorstride.com">
                      info@creatorstride.com
                    </a>
                    .
                  </Typography>
                  <br />
                  <br />
                </Typography>
              </Box>
              <Box
                className="d-flex justify-content-between px-5"
                // style={{ height: "5%" }}
              >
                <Box
                  className="d-flex justify-content-between"
                  style={{ height: "auto" }}
                ></Box>
              </Box>
              <Box
                className="d-flex justify-content-center px-5 align-items-center text-center py-5 py-md-4"
                style={{
                  minHeight: "50px",
                  backgroundColor: "var(--collection-1-primary-main)",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2023 creatorstride.com. All Rights Reserved.
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isLoading && <LoadingOverlay />}
      {showDialog && (
        <Dialog
          open="true"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="bg-white d-flex justify-content-center position-relative px-5 py-5 rounded">
            <div className="w-100">
              <div className="cross cursor-pointer">
                <IconButton
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {alreadySent ? (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Excellent! You're Ready to Start. Your Details Have Already
                    Been Captured.
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h4" className="pb-3 text-center">
                    Greetings, creator!
                  </Typography>
                  <p className="py-3 text-center">
                    Thanks for submitting! We've received your internship
                    application. Expect a confirmation email soon; check your
                    inbox or spam folder. Any questions? Feel free to reach out.
                    Thanks for your interest!
                  </p>
                  <Box className="text-center">
                    <img src={logo} width="200px" height="auto" />
                  </Box>
                </>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default TermsConditions;
