import React from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useState } from "react";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FlatBlogCard from "./FlatBlogCard";
import './BlogRequest.css'
import BlogSubscriptionSuccess from './BlogSubscriptionSuccess'
import BlogAlreadySubscribed from "./BlogAlreadySubscribed";
import { useNavigate } from "react-router-dom";


const BlogRequest = ({ blogData }) => {
  const [email, setEmail] = useState("");
  const [subscribedNow,setSubscribedNow]=useState(false)
  const [alreadySubscribed,setAlreadySubscribed] = useState(false)
  const navigate=useNavigate()

  console.log("Blog data from blogrequest : ",blogData)


  const handleSubscribe = () => {
    console.log(`Subscribed with email: ${email}`);
    if(!email){
      return
    }
    try{
      const payload = {
        email,
      };
      fetch(`${process.env.REACT_APP_SERVER_URL}/subscribeToBlog`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify(payload)
      })
        .then((response) => response.json())
        .then((responseData) => {
          setEmail('')
          if(responseData?.status === "success" && responseData?.isAlreadySubscribed){
            console.log("Into first if condition")
            setAlreadySubscribed(true)
          }else if(responseData?.status === "success" && !(responseData?.isAlreadySubscribed)){
            console.log("Into the else if condition")
            setSubscribedNow(true)
          }
        });
    }catch(error){
      throw new Error(error)
    }
  };


  return (
    <>
      <Box
        class="col col-5 px-4 py-3 rounded shadow request-blog-container"
        style={{
        //   topBorder: "3px solid var(--collection-1-primary-main)",
        // backgroundColor: "var(--collection-1-primary-main)",
          height: "100%",
          width:"100%"
        }}
      >
        <Box>
            <Box class="d-flex justify-content-start gap-3 py-2">
            <Typography variant="h5">Never Miss a Post – Subscribe Today!</Typography> <NotificationsActiveIcon/>
            </Box>
          <Typography variant="body1">
            Stay updated with our latest blogs by subscribing!
          </Typography>
          <TextField
            label="Your Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: "10px 0", width: "100%" }}
            autoComplete="off" 
          />
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "var(--collection-1-primary-main)",color:'white' }}
            onClick={handleSubscribe}
          >
            Subscribe
          </Button>
        </Box>
        <Box class="py-3 mx-auto">
          <Typography variant="h6"><u>Most Viewed Articles:-</u></Typography>
          {blogData.slice(1).map((blog, index) => (
             <FlatBlogCard
             title={blog.title}
             imageUrl={blog.cover_image_url}
             postedOn={blog.posted_on}
             content={blog.content}
             />
          ))}
        </Box>
        <Box class="py-3 mx-auto">
          <Typography variant="h6"><u>Recent Articles:-</u></Typography>
          {blogData.slice(-2).map((blog, index) => (
             <FlatBlogCard
             title={blog.title}
             imageUrl={blog.cover_image_url}
             postedOn={blog.posted_on}
             content={blog.content}
             />
          ))}
        </Box>
        {subscribedNow && <BlogSubscriptionSuccess
        subscribedNow={subscribedNow}
        setSubscribedNow={setSubscribedNow}
        />}
        {alreadySubscribed && <BlogAlreadySubscribed
        alreadySubscribed={alreadySubscribed}
        setAlreadySubscribed={setAlreadySubscribed}
        />}
      </Box>
    </>
  );
};

export default BlogRequest;
