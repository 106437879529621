const initialState = {
  userQuestionnaireHistory: [],
  contentGenerationHistory: [],
  uploadsHistory: [],
  reduxUserName: null,
  reduxUserDomains: [],
  reduxUserTopics: [],
  reduxUserScript: [],
  reduxSelectedDomains: [],
  reduxSelectedTopics: [],
  reduxProfileImage: null,
  canSearchCompetitor:true,
};

const customReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_QUESTIONNAIRE_HISTORY":
      return {
        ...state, // Spread the current state
        userQuestionnaireHistory: action.payload,
      };
    case "UPDATE_CONTENT_GENERATION_HISTORY":
      return {
        ...state, // Spread the current state
        contentGenerationHistory: action.payload,
      };
    case "UPDATE_UPLOADS_HISTORY":
      return {
        ...state, // Spread the current state
        uploadsHistory: action.payload,
      };
    case "UPDATE_REDUX_USERNAME":
      return {
        ...state, // Spread the current state
        reduxUserName: action.payload,
      };
    case "UPDATE_REDUX_USER_DOMAINS":
      return {
        ...state, // Spread the current state
        reduxUserDomains: action.payload,
      };
    case "UPDATE_REDUX_USER_TOPICS":
      return {
        ...state, // Spread the current state
        reduxUserTopics: action.payload,
      };
    case "UPDATE_REDUX_SELECTED_DOMAINS":
      return {
        ...state, // Spread the current state
        reduxUserSelectedDomains: action.payload,
      };
    case "UPDATE_REDUX_SELECTED_TOPICS":
      return {
        ...state, // Spread the current state
        reduxUserSelectedTopics: action.payload,
      };
    case "UPDATE_REDUX_PROFILE_IMAGE":
      return {
        ...state,
        reduxProfileImage:action.payload,
      };
    case "UPDATE_CAN_SEARCH_COMPETITOR":
      return {
        ...state,
        canSearchCompetitor:action.payload
      }
    case "LOGOUT":
      return initialState;
    default:
      return state; // Return the current state for unknown actions
  }
};

export default customReducer;
