import React, { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const CopyToClipboardButton = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    // Select the text
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);

    // Update the state to indicate that the text has been copied
    setIsCopied(true);

    // Reset the "Copied" state after a brief delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="d-flex gap-2">
      <Tooltip title="Copy To Clipboard">
        <CopyOutlined onClick={copyToClipboard} style={{fontSize:'18px'}}/>
      </Tooltip>
      {isCopied ? "Copied!" : "Copy to Clipboard"}
    </div>
  );
};

export default CopyToClipboardButton;
