import { Box, Typography } from "@mui/material";

const UserCard = ({data,id}) => {
  return (
    <Box className="my-3 d-flex justify-content-end user-card" id={id}>
      <Box
        className="px-3 py-4 w-50 rounded shadow"
        style={{ backgroundColor: "#f2e5e4" }}
      >
          <Typography><b>Topic</b> : {data}</Typography>
      </Box>
    </Box>
  );
};

export default UserCard;
