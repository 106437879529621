import React from "react";
import eye from "../../assets/eye.png";
import heart from "../../assets/heart.png";
import dark_chat from "../../assets/dark_chat.png";
import "./DashboardCard.css";
import { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import BootstrapTable from "react-bootstrap-table-next";
import { Box, Typography, Button } from "@mui/material";

function DashboardCard(props) {
  console.log("Into the dashboard card function");
  const navigate = useNavigate();
  const { channelId, video_id, highlightTerm } = props;
  console.log("Channel id is :", channelId);
  console.log("Video id is :", video_id);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [description, setDescription] = useState();
  const [truncatedDescription, setTruncatedDescription] = useState();
  const [tableRow, setTableRow] = useState([]);
  const [videoData, setVideoData] = useState({});
  const [commentsData, setCommentsData] = useState({});
  const [table, setTable] = useState();
  const [commentList, setCommentList] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [commentsCount, setCommentsCount] = useState(0);
  const [sliceCount, setSliceCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const session_id = Cookies.get("session_id");
  const columns = [
    { dataField: "id", text: "Id" },
    { dataField: "comment_text", text: "Comment", sort: true },
    { dataField: "chat_gpt_response", text: "Response", sort: true },
    { dataField: "comment_author_name", text: "Author", sort: true },
    {
      dataField: "comment_author_subscribers",
      text: "Author Subscribers",
      sort: true,
    },
    {
      dataField: "owner_response_status",
      text: "Owner Response Status",
      sort: true,
    },
    {
      dataField: "editor_response_status",
      text: "Editor Response Status",
      sort: true,
    },
  ];

  useEffect(() => {
    const postData = {
      video_id: video_id,
      session_id: session_id,
      channel_id: channelId,
    };

    // Make a POST request
    fetch(`${process.env.REACT_APP_SERVER_URL}/fetchCardDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("The card details is : ", responseData);
        if (responseData.status === "Unauthorized") {
          window.location.replace("/signIn");
        } else if (responseData.status == "success") {
          const videoDataResponse = responseData.video_data;
          const commentsDataResponse = responseData.comments_data;

          videoDataResponse && setVideoData(videoDataResponse);
          commentsDataResponse && setCommentsData(commentsDataResponse);
          commentsDataResponse && setCommentsCount(commentsDataResponse.length);

          const videoDescription = videoDataResponse.video_description;
          videoDescription && setDescription(videoDescription);
          // console.log("The description is :", videoDescription);

          const truncatedDescription = videoDescription
            .split(" ")
            .slice(0, 30)
            .join(" ");
          // console.log("The truncated description is :", truncatedDescription);
          truncatedDescription && setTruncatedDescription(truncatedDescription);
          const newCommentsData = commentsDataResponse.slice(0, 2);
          newCommentsData && setCommentList(newCommentsData);
          newCommentsData && setSliceCount(newCommentsData.length);
          if (commentsDataResponse.length > 0) {
            setTable(true);
          } else {
            setTable(false);
          }
        } else if (responseData.status === "error") {
          // toast.error("Something went wrong !!", { position: toast.POSITION.TOP_CENTER })
        } else if (responseData.status === "Unauthorized") {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  }, [props.video_id, fetchSuccess]);

  const videoPageHandler = () => {
    console.log("The value in the video page handler is :", video_id);
    console.log("The value in the video page handler is :", channelId);
    navigate(`/videoPage?key=${video_id}`, {
      state: { key: video_id, channel_id: channelId, video_id: video_id },
    });
  };

  const approveAllHandler = (event) => {
    setFetchSuccess(false);
    const status = event.target.getAttribute("data-status");
    const payload = {
      status,
      session_id,
      video_id,
      channelId,
    };
    fetch(`${process.env.REACT_APP_SERVER_URL}/approveAllStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("The response data is : ", responseData);
        if (responseData.status === "success") {
          setFetchSuccess(true);
          toast.success("Approved All", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (responseData.status === "error") {
          // toast.error("Something Went Wrong", { position: toast.POSITION.TOP_CENTER })
        } else if (responseData.status === "Unauthorized") {
          navigate("/");
        } else if (responseData.status === "VIEWER_ACCESS_DENIED") {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (responseData.status === "ALREADY_PERFORMED") {
          toast.info("Actions Already Performed !!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  const buttonstyle = {
    backgroundColor: "var(--collection-1-primary-main)",
    color: "white",
  };

  return (
    <>
      <div className="bg-white br-17px px-3 py-3 my-3">
        <div className="px-3 py-3">
          <div className="d-flex flex-wrap gap-4">
            <div className="w-md-45 w-100">
              <iframe
                width=""
                height="200px"
                src={`https://www.youtube.com/embed/${props.video_id}`}
                frameborder="0"
                className="br-17px w-100"
                allowfullscreen
              ></iframe>
              <div className="d-flex flex-wrap bg-mild br-17px px-2 py-3 justify-content-center my-2">
                <div className="px-4 d-flex align-items-center">
                  <img className="img-icon mx-2" src={eye} alt="view icon" />
                  <p className="m-0">
                    <Typography>{videoData?.view_count} Views</Typography>
                  </p>
                </div>
                <div className="px-4 d-flex align-items-center">
                  <img className="img-icon mx-2" src={heart} alt="like icon" />
                  <p className="m-0">
                    <Typography>{videoData?.like_count} Likes</Typography>
                  </p>
                </div>
                <div className="px-4 d-flex align-items-center">
                  <img
                    className="img-icon mx-2"
                    src={dark_chat}
                    alt="chat icon"
                  />
                  <p className="m-0">
                    <Typography>
                      {videoData?.comments_count} Comments
                    </Typography>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 w-md-45">
              <div
                className={`bg-grey br-17px px-4 py-3 w-100 mx-auto my-3 my-md-0 overflow-auto ${
                  viewportWidth > 768 ? "h-200px" : "w-fc"
                }`}
              >
                <p className="m-0 py-2">
                  <Typography>
                    <b>Description :</b>
                  </Typography>
                </p>
                {showFullDescription ? (
                  <div>
                    <p>
                      <Typography>{description}</Typography>
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() =>
                          setShowFullDescription(!showFullDescription)
                        }
                      >
                        {" "}
                        <Typography>See less...</Typography>
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="py-2 py-md-0">
                    <p>
                      <Typography>{truncatedDescription}</Typography>
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() =>
                          setShowFullDescription(!showFullDescription)
                        }
                      >
                        <Typography>See more...</Typography>
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="overflow-auto text-nowrap">
                <p className="px-3 py-1">
                  <Typography>{videoData?.video_title}</Typography>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div class="bg-grey px-3 px-md-4 py-3 py-md-4 rounded">
              <div class="px-3 bg-m-grey py-3 overflow-auto rounded">
                {table ? (
                  <BootstrapTable
                    keyField="id"
                    columns={columns}
                    data={commentList}
                  />
                ) : (
                  <h5>
                    <Typography>
                      Actions Are Performed For The Current Video..
                    </Typography>
                  </h5>
                )}
                <div className="d-flex flex-end">
                  <h5>
                    {table ? (
                      <Typography>
                        {`${sliceCount} of ${commentsCount}`}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </h5>
                </div>
              </div>
              <div className="d-flex justify-content-center justify-content-md-end mt-md-3 my-2">
                <Button
                  style={buttonstyle}
                  className="b-btn"
                  onClick={videoPageHandler}
                >
                  More
                </Button>
                <Button
                  className="b-btn mx-3"
                  style={buttonstyle}
                  data-status="Approved"
                  onClick={approveAllHandler}
                >
                  Approve All
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCard;
