import React from "react";
import "./style.css";
import traffic from '../../../assets/Trafic.svg'
import capa from '../../../assets/Capa_1.svg'
import line from '../../../assets/Line 6.png'
import streaming from '../../../assets/Streaming.svg'
import Diagram from '../../../assets/Diagram.png'


const AdvancedAnalytics = () => {
    return (
        <div className="advanced-analytics px-3 px-md-5" style={{ maxWidth: "1440px", margin: "auto" }}>
            <div className="d-flex justify-content-between flex-xxl-row flex-column gap-4 mx-auto px-3 px-md-5">
            <div className="content mx-0 mx-lg-5">
                <div className="title">
                    <div className="text-wrapper">The features we offer</div>
                    <div className="div">Advanced Analytics:</div>
                    <p className="p">Leverage Advanced Analytics for Deep Insights.</p>
                </div>
                <div className="content-2">
                    <img className="line" alt="Line" src={line} />
                    <div className="feature">
                        <div className="circle">
                            <div className="ellipse" />
                        </div>
                        <p className="text-wrapper-2">
                            Monitoring of trending topics and <br />
                            audience preferences.
                        </p>
                    </div>
                    <div className="feature">
                        <div className="ellipse-wrapper">
                            <div className="ellipse" />
                        </div>
                        <p className="text-wrapper-2">
                            Comparative analysis to gauge your channel&#39;s <br />
                            performance against competitors.
                        </p>
                    </div>
                    <div className="feature">
                        <div className="div-wrapper">
                            <div className="ellipse" />
                        </div>
                        <p className="text-wrapper-2">
                            Actionable insights to refine your content <br />
                            strategy and boost your YouTube presence.
                        </p>
                    </div>
                </div>
            </div>
            <div className="group mx-0 mx-lg-5 scale d-none d-xl-block">
                <img src={Diagram} alt="image"/>
                <div className="video-wrapper">
                    <div className="video">
                        <div className="text-wrapper-10">Education Video</div>
                        <div className="text-wrapper-11">100,000 View</div>
                        <div className="percen">
                            <img className="capa" alt="Capa" src={capa} />
                            <div className="text-wrapper-12">+50%</div>
                        </div>
                        <img className="trafic" alt="Trafic" src={traffic} />
                    </div>
                </div>
                <div className="group-2">
                    <div className="video">
                        <div className="text-wrapper-10">Streaming Video</div>
                        <div className="text-wrapper-11">800 View</div>
                        <div className="percen">
                            <img className="capa" alt="Capa" src={capa} />
                            <div className="text-wrapper-12">+50%</div>
                        </div>
                        <img className="trafic" alt="Trafic" src={traffic} />
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default AdvancedAnalytics