import { Box, Typography, IconButton, Button, Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUploadsHistory,
  updateContentGenerationHistory,
} from "../../redux/actions/actions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CheckCircleOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const UploadWork = ({
  isUploadButtonClicked,
  setIsUploadButtonClicked,
  topic,
}) => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const contentGenerationHistory = useSelector(
    (state) => state.contentGenerationHistory
  );
  const uploadsHistory = useSelector((state) => state.uploadsHistory);
  const session_id = Cookies.get("session_id");
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [isLinkUploaded, setIsLinkUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [hasYoutubeChannel, setHasYoutubeChannel] = useState(null);
  const [fileSelected,setFileSelected]=useState(false)
  const [hasAnswered, setHasAnswered] = useState(false);
  const uploadWorkDivStyle = {
    height: "80vh", // Adjusted height to make it centered
    position: "absolute",
    backgroundColor: "white",
    zIndex: "1000",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    overflow: "hidden", // Prevent scrolling in the background
    display: "flex",
    flexDirection: "column", // Added to stack the close icon on top of the content
  };

  useEffect(() => {
    console.log("uploads history is : ", uploadsHistory);
    uploadsHistory.forEach((history, index) => {
      console.log("The uploads history is : ", history);
      if (history.topic === topic) {
        if (history.youtube_link) {
          setYoutubeLink(history.youtube_link);
          setIsLinkUploaded(true);
        }
      }
    });
  }, []);

  const uploadFile = () => {
    const formData = new FormData();
    const fileInput = document.querySelector('.input-video-file');
    console.log("File input === ", fileInput);
    if(!fileInput){
        return
    }
    console.log("Number of files selected:", fileInput?.files?.length);
    if (fileInput?.files?.length > 0) {
      const file = fileInput.files[0];
      formData.append("videoFile", file);
      formData.append("session_id", session_id);
      formData.append("topic", topic);
      console.log("Formdata ===== ", formData);
      const hasSessionId = formData.get("session_id") !== null;
      const fill = formData.get("videoFile");
      console.log("file === ", fill);
      fetch(`${process.env.REACT_APP_SERVER_URL}/uploadInternVideo`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("File upload response", data);
          if (data.status === "success" && data.message==="API successfull") {
            setIsFileUploaded(true);
            navigate('/channelId')
            // formData.delete("videoFile");
            // formData.delete("session_id");
            // formData.delete("topic");
            // fileInput.value = null;
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      console.log("No file selected.");
    }
  };

  const backgroundDivStyle = {
    backgroundColor: "black",
  };

  useEffect(() => {
    const body = document.querySelector("body");
    console.log("Body.style is : ", body.style);

    if (true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }
    return () => {
      body.style.overflow = "visible";
    };
  }, []);

  const uploadInternLink = () => {
    const payload = {
      session_id,
      topic,
      youtubeLink,
    };
    if (!youtubeLink) {
      return;
    }
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/uploadInternLink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Response data is : ", responseData);
          // if (responseData.status === "success") {
          //   const isTopicIncluded = uploadsHistory.some(
          //     (history) => history.topic === topic
          //   );
          //   console.log("Is topic included : ", isTopicIncluded);
          //   if (isTopicIncluded) {
          //     const uploadsHistory_ = uploadsHistory.map((history, index) => {
          //       if (history.topic === topic) {
          //         history.youtube_link = youtubeLink;
          //       }
          //     });
          //     console.log(
          //       "New uploads history by updating : ",
          //       uploadsHistory_
          //     );
          //     dispatch(updateUploadsHistory(uploadsHistory_));
          //   }
          //   setIsLinkUploaded(true);
          // }
          if (responseData.status === "success" && responseData.message==="API successfull") {
            navigate('/channelId')
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  };

  const buttonStyles = {
    backgroundColor: "var(--collection-1-primary-main)",
    color: "white",
  };

  const yesButtonStyles = {
    backgroundColor: "#FF0000", // Red color for 'Yes'
    color: "white",
    borderRadius: "8px", // Rounded corners
    marginRight: "10px",
  };

  const noButtonStyles = {
    borderColor: "#FF0000", // Red border color for 'No'
    color: "#FF0000", // Red text color for 'No'
    borderRadius: "8px", // Rounded corners
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if(file){
        setFileSelected(true)
    }
  };


  useEffect(()=>{
      //  navigate('/channelId')
      console.log("The has youtube channel is : ",hasYoutubeChannel)
      if(hasYoutubeChannel){
        navigate('/channelId')
      }
  },[hasYoutubeChannel])

  return (
    <>
      {isUploadButtonClicked && (
        <Box
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            overflow: "hidden",
          }}
        >
          <Box className="shadow rounded col col-11 col-sm-8 col-md-7 col-lg-7 col-xl-6 col-xxl-5 bg-light">
            <Box className="d-flex justify-content-end py-2 px-2">
              <IconButton
                //   style={closeIconStyle}
                onClick={() => setIsUploadButtonClicked(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* <Box className="d-flex justify-content-center align-items-center px-3 px-md-5 py-4 py-md-5">
              <Box style={{ flex: "1" }}>
                <Box className="mx-auto">
                  <Box
                    className="px-4 px-sm-5 py-3 my-3 rounded"
                    style={{
                      border: "1px solid var(--collection-1-primary-main)",
                    }}
                  >
                    <Typography className="py-3" variant="h5">
                      Ready With Your Video? Then Share With Us...
                    </Typography>
                    <TextField
                      id="outlined-password-input"
                      label="Youtube Link"
                      className="w-100"
                      variant="outlined"
                      autoComplete="off"
                      value={youtubeLink}
                      onChange={(e) => setYoutubeLink(e.target.value)}
                    />
                    {!isLinkUploaded ? (
                      <Button
                        className="w-100 my-3"
                        style={buttonStyles}
                        onClick={uploadInternLink}
                      >
                        Upload Link
                      </Button>
                    ) : (
                      <Typography>Link Uploaded..</Typography>
                    )}
                  </Box>
                  <Box>
                    <Box
                      className="py-3 px-4 px-sm-5 rounded"
                      style={{
                        border: "1px solid var(--collection-1-primary-main)",
                      }}
                    >
                      <Typography variant="h5" className="py-3">
                        Not Having A Youtube Channel? Then Upload Your File With
                        Us..
                      </Typography>
                      <Input type="file" className="input-video-file" />
                      <Button
                        className="w-100 my-3"
                        style={{
                          backgroundColor: "var(--collection-1-primary-main)",
                          color: "white",
                        }}
                        onClick={uploadFile}
                      >
                        Upload File
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box> */}
            <Box className="d-flex justify-content-center align-items-center px-md-5 py-md-5 px-3 py-4 pt-3">
              <Box style={{ flex: "1" }}>
                <Box className="px-3 py-3">
                  <Typography variant="h4" className="text-center">
                    Certainly! It seems you've crafted your videos. Feel free to
                    share them with us.
                  </Typography>
                  {!hasAnswered && (
                    <Box textAlign="center" p={4}>
                      <Typography variant="h5" gutterBottom>
                        Do you have a YouTube channel or have you posted a video
                        on YouTube?
                      </Typography>
                      <Button
                        style={yesButtonStyles}
                        onClick={() => {
                          // setHasAnswered(true);
                          // setHasYoutubeChannel(true);
                          navigate('/channelId')
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        style={noButtonStyles}
                        variant="outlined"
                        onClick={() => {
                          // setHasAnswered(true);
                          // setHasYoutubeChannel(false);
                          setIsUploadButtonClicked(false)
                        }}
                      >
                        No
                      </Button>
                    </Box>
                  )}
                </Box>
                {hasAnswered && (
                  <Box className="mx-auto">
                    {hasYoutubeChannel ? (
                      <Box
                        className="p-4 rounded border"
                        style={{
                          borderColor: "var(--collection-1-primary-main)",
                        }}
                      >
                        {!isLinkUploaded && (
                          <Typography className="py-3" variant="h5">
                            Have your video ready? Share the link with us here!
                          </Typography>
                        )}
                        <TextField
                          id="outlined-password-input"
                          label="Youtube Link"
                          className="w-100 mb-3"
                          variant="outlined"
                          autoComplete="off"
                          // Assuming youtubeLink is a state variable
                          // value={youtubeLink}
                          onChange={(e) => setYoutubeLink(e.target.value)}
                        />
                        {!isLinkUploaded ? (
                          <Button
                            className="w-100"
                            style={buttonStyles}
                            // Assuming uploadInternLink is the function
                            onClick={uploadInternLink}
                            disabled={!youtubeLink}
                          >
                            <CloudUploadIcon className="me-2" />
                            Upload Link
                          </Button>
                        ) : (
                          <Box className="text-center">
                            <Typography>
                              Link Uploaded. <CheckCircleOutlined />
                            </Typography>
                            <Typography className="py-3">
                              We genuinely value the kindness of users who share
                              their work with us. Keep up the outstanding
                              effort!
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box
                        className="mt-4 p-4 rounded border"
                        style={{
                          borderColor: "var(--collection-1-primary-main)",
                        }}
                      >
                        {!isFileUploaded && (
                          <Typography variant="h5" className="py-3">
                            Don't have a YouTube channel? Upload your file with
                            us instead.
                          </Typography>
                        )}
                        <input type="file" className="input-video-file mb-3" onChange={handleFileChange} />
                        {!isFileUploaded ? (
                          <Button
                            className="w-100"
                            style={buttonStyles}
                            // Assuming uploadFile is the function
                            onClick={uploadFile}
                            disabled={!fileSelected}
                          >
                            <CloudUploadIcon className="me-2" />
                            Upload File
                          </Button>
                        ) : (
                          <Box className="text-center">
                            <Typography>
                              File Uploaded. <CheckCircleOutlined />
                            </Typography>
                            <Typography className="py-3">
                              We genuinely value the kindness of users who share
                              their work with us. Keep up the outstanding
                              effort!
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UploadWork;
