import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, MenuProvider, theme, Button } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CREATORSTRIDE_LOGO from "../../assets/quiz_header.png";
import { Box, Typography, useScrollTrigger } from "@mui/material";
import { BookOutlined, HistoryOutlined } from "@mui/icons-material";
import RegenerateButton from "./RegenerateButton";
import UserLoginAvatar from "../User/UserLoginAvatar";
import LoadingOverlay from "../dashboard/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import {
  updateContentGenerationHistory,
  updateReduxUserDomains,
  updateReduxUserTopics,
  reduxLogoutHandler,
} from "../../redux/actions/actions";
import UserCard from "./UserCard";
import BotCard from "./BotCard";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/quiz_header.png";
import { IconButton } from "@mui/material";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const NewPlayground = () => {
  const dispatch = useDispatch();
  const contentGenerationHistory = useSelector(
    (state) => state.contentGenerationHistory
  );
  const reduxUserDomains = useSelector((state) => state.reduxUserDomains);
  const reduxUserTopics = useSelector((state) => state.reduxUserTopics);
  const session_id = Cookies.get("session_id");
  const [collapsed, setCollapsed] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
  const [nicheData, setNicheData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [historyTopics, setHistoryTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [script, setScript] = useState(null);
  const [scriptGenerated, setScriptGenerated] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [contentHistory, setContentHistory] = useState([]);
  const [loadingText, setLoadingText] = useState("Generating...");
  const [domainsCount, setDomainsCount] = useState(4);
  const navigate = useNavigate();
  const layoutContainerRef = useRef(null);
  const [isQuotaExceeded, setIsQuotaExceeded] = useState(false);
  const dialogContent =
    "Trial accounts are limited to a set number of requests for evaluation purposes. Upgrade to a paid plan for unrestricted access";

  const handleScrollToTop = () => {
    if (layoutContainerRef.current) {
      layoutContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (reduxUserDomains.length > 0) {
      console.log("redux user domains length greater than 0", reduxUserDomains);
      return;
    }
    console.log("user redux domains length less than zero");
    setIsLoading(true);
    const payload = {
      session_id,
    };
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserDomains`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseData) => {
          setIsLoading(false);
          if (responseData.status === "success") {
            let domains_ = responseData.domains;
            if (!domains_) {
              navigate("/internship/questionnaire");
            } else {
              console.log("domains_ ==== ", domains_);
              console.log("domains  ==== ", typeof domains_);
              setNicheData(domains_);
            }
            const oldTopics = responseData?.topics;
            if (oldTopics) {
              setHistoryTopics(oldTopics.slice(0, 6));
            }
            const contentHistory_ = responseData?.contentHistory;
            if (contentHistory_) {
              setContentHistory(contentHistory_);
            }
          } else if (responseData.status === "Unauthorized") {
            navigate("/");
            Cookies.remove("session_id");
            Cookies.set("isLoggedIn", false);
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    console.log(
      "The content History from the use Effect is : ",
      contentHistory
    );
    dispatch(updateContentGenerationHistory(contentHistory));
  }, [contentHistory]);

  useEffect(() => {
    console.log(
      "The redux content Generation history from use Effect is : ",
      contentGenerationHistory
    );
  }, [contentGenerationHistory]);

  useEffect(() => {
    if (windowSize.innerWidth < 800) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const logoStyle = {
    display: collapsed ? "none" : "flex",
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const generateTopics = async (item) => {
    setIsLoading(true);
    setLoadingText("Generating Topics From Domains...");
    const payload = {
      selectedDomains: [item],
      session_id,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getTopicsOnDomain`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      setIsLoading(false);
      if (responseData?.status_code == "401") {
        setIsQuotaExceeded(true);
        return;
      }
      if (responseData?.status === "success") {
        console.log("The generated topics response is:", responseData);

        let data = "";

        console.log(typeof responseData?.data);

        if (typeof responseData?.data !== "object") {
          console.log("The JSON string is:", responseData?.data);
          try {
            console.log("It is object");
            data = JSON.parse(responseData?.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.log("It is not object");
          data = responseData?.data;
        }
        setTopics(data);
        dispatch(updateReduxUserTopics(data));
        setLoadingText("Generating...");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    setTopics(reduxUserTopics);
    console.log("THe redux user Topics is : ", reduxUserTopics);
  }, [reduxUserTopics]);

  useLayoutEffect(() => {
    setNicheData(reduxUserDomains);
    console.log("The redux user domains is : ", reduxUserDomains);
  }, [reduxUserDomains]);

  const handleItemClick = (item) => {
    setSelectedDomains([item]);
    generateTopics(item);
  };

  const handleTopicClick = (item) => {
    setSelectedTopics([item]);
    generateScript(item);
  };

  const generateScript = async (item) => {
    setScript("");
    setScriptGenerated(false);
    setLoadingText("Generating Script From Topic...");
    const payload = {
      topics: [item],
      session_id,
    };
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/generateScriptOnTopic`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response?.json();
    setIsLoading(false);
    setScriptGenerated(true);
    setLoadingText("Generating...");
    console.log("Generate script on topic response : ",responseData)
    if(responseData.status_code == "401"){
      alert("Ell")
      setIsQuotaExceeded(true)
      return
    }
    if (responseData?.status === "success") {
      if (responseData?.data) {
        console.log("The script is : ", responseData?.data);
        setScript(responseData?.data);
        setScriptGenerated(true);
        const newHistoryTopics = new Set([...historyTopics, item]);
        setHistoryTopics(Array.from(newHistoryTopics));
        const isTopicIncluded = contentGenerationHistory.some(
          (history) => history?.topic === selectedTopics[0]
        );
        console.log("Is topic included : ", isTopicIncluded);
        if (isTopicIncluded) {
          const contentGenerationHistory_ = contentGenerationHistory.map(
            (history, index) => {
              if (history?.topic === item) {
                history.response = responseData?.data;
              }
            }
          );
          console.log(
            "New content generation history by updating : ",
            contentGenerationHistory_
          );
          dispatch(updateContentGenerationHistory(contentGenerationHistory_));
        } else {
          const data = {
            topic: item,
            response: responseData?.data,
          };
          const newContentGenerationData = [...contentGenerationHistory, data];
          console.log(
            "New content generation history by adding : ",
            newContentGenerationData
          );
          dispatch(updateContentGenerationHistory(newContentGenerationData));
        }
      }
    } else if (responseData.status === "Unauthorized") {
      navigate("/");
      Cookies.remove("session_id");
      Cookies.set("isLoggedIn", false);
    }
  };

  useEffect(() => {
    const layoutContainer = layoutContainerRef.current;
    console.log("From layout container use effect : ", layoutContainer);
    // Scroll to the bottom of the layout container
    if (layoutContainer) {
      console.log(
        "The scroll height from useEffect is : ",
        layoutContainer.scrollHeight
      );
      layoutContainer.scrollTop = layoutContainer.scrollHeight;
    }
  }, [selectedDomains, selectedTopics, script]);

  useEffect(() => {
    // Update defaultSelectedKeys whenever selectedDomains changes
    setDefaultSelectedKeys(selectedDomains);
    console.log("THe default selected keys are : ", selectedDomains);
  }, [selectedDomains]);

  const RegenerateDomains = () => {
    setIsLoading(true);
    setLoadingText("Regenerating Domains ....");
    // const payload = {
    //   session_id,
    // };
    // try {
    //   fetch(`${process.env.REACT_APP_SERVER_URL}/regenerateDomains`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   })
    //     .then((response) => response.json())
    //     .then((responseData) => {
    //       setIsLoading(false)
    //       console.log(
    //         "Response data from regenerate Domains is : ",
    //         responseData
    //       );
    //       if (responseData?.status === "success" && nicheData.length > 0) {
    //         setNicheData(responseData?.nicheData);
    //         dispatch(updateReduxUserDomains(responseData?.nicheData))
    //       }
    //     });
    // } catch (error) {
    //   console.error("Error occured in the regenerate Domains : ", error);
    // }
    setTimeout(() => {
      setIsLoading(false);
      setDomainsCount((prev) => prev + 3);
    }, 3000);
  };

  // useEffect(()=>{
  //   console.log("In the useeffect of domains count")
  //   const newDomains=nicheData?.slice(0,domainsCount)
  //   console.log("New domains in domains count dependency : ",newDomains)
  //   dispatch(updateReduxUserDomains(newDomains))
  // },[domainsCount])

  useEffect(() => {
    console.log("The redux user domains changed : ", reduxUserDomains);
  }, [reduxUserDomains]);

  useEffect(() => {
    console.log("The history topics from use effect is : ", historyTopics);
  }, [historyTopics]);

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
        width="450px"
        style={{
          height: "100vh",
          overflow: "none",
        }}
      >
        <Box className="overflow-auto">
          <Box
            className="py-4 text-center d-flex justify-content-center position-relative"
            style={{ position: "relative" }}
          >
            <img
              src={CREATORSTRIDE_LOGO}
              style={logoStyle}
              width="150px"
              height="auto"
              className="cursor-pointer"
              onClick={() => navigate("/")}
            />
          </Box>
          <div className="demo-logo-vertical" />
          <Box className="overflow-auto">
            <Menu
              theme="dark"
              mode="inline"
              style={{ position: "relative" }}
              defaultSelectedKeys={defaultSelectedKeys}
            >
              <Menu.SubMenu
                key="Domains"
                icon={<GlobalOutlined />}
                title="Domains"
              >
                {nicheData?.slice(0, domainsCount).map((niche, index) => (
                  <Menu.Item key={niche} onClick={() => handleItemClick(niche)}>
                    {niche}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            </Menu>
            {topics.length > 0 ? (
              <Menu theme="dark" mode="inline" style={{ position: "relative" }}>
                <Menu.SubMenu
                  key="topics"
                  icon={<BookOutlined />}
                  title="Topics"
                >
                  {Array.from(new Set(topics)).map((topic, index) => (
                    <Menu.Item
                      key={topic}
                      onClick={() => handleTopicClick(topic)}
                    >
                      {topic}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              </Menu>
            ) : null}
            {historyTopics.length > 0 ? (
              <Menu theme="dark" mode="inline" style={{ position: "relative" }}>
                <Menu.SubMenu
                  key="/internship/v1/playground/history"
                  icon={<HistoryOutlined />}
                  title="History"
                >
                  {Array.from(new Set(historyTopics)).map((topic, index) => (
                    <Menu.Item
                      key={`/internship/v1/playground/history/${topic}`}
                    >
                      <Link
                        to={`/internship/v1/playground/history?topic=${topic}`}
                        style={{ textDecoration: "none" }}
                      >
                        {topic}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              </Menu>
            ) : null}
          </Box>
        </Box>
        <Box className="d-flex justify-content-center w-100 py-3">
          <UserLoginAvatar collapsed={collapsed} />
        </Box>
        {/* <Box
          className="d-flex justify-content-center w-100"
          style={{ position: "absolute", bottom: "25px" }}
        >
          <UserLoginAvatar collapsed={collapsed} />
        </Box> */}
      </Sider>
      {nicheData.length > 0 ? (
        <Layout
          style={{ overflow: "auto" }}
          id="layout-container"
          ref={layoutContainerRef}
        >
          {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        /> */}
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <>
              <Box className="text-center py-5">
                <img
                  src={CREATORSTRIDE_LOGO}
                  width={windowSize.innerWidth < 800 ? "50%" : "20%"}
                  height="auto"
                />
              </Box>
              <Box className="text-center">
                <Typography variant="h5">
                  Generate content effortlessly with us. !!
                </Typography>
              </Box>
            </>
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              {/* <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item> */}
            </Breadcrumb>
            <div
              className="shadow"
              style={{
                padding: 24,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Box className="px-1 px-sm-3 px-md-5 py-4 py-md-4">
                {nicheData.length > 0 ? (
                  <Box>
                    {/* <Typography className="my-2">Hello User,</Typography> */}
                    <Typography>
                      We have identified several domains that align perfectly
                      with your interests. Explore the carefully curated list
                      below to discover the one that best suits you. Click on a
                      domain to generate topics related to that specific area.
                    </Typography>
                    <Box className="my-3">
                      {nicheData.slice(0, domainsCount).map((niche, index) => (
                        <Box
                          key={index}
                          className="mx-2 px-3 py-3 my-2 cursor-pointer rounded"
                          style={{
                            display: "inline-block",
                            border:
                              "2px solid var(--collection-1-primary-main)",
                            backgroundColor: selectedDomains.includes(niche)
                              ? "var(--collection-1-primary-main)"
                              : "white",
                            color: selectedDomains.includes(niche)
                              ? "white"
                              : "black",
                          }}
                          onClick={() => handleItemClick(niche)}
                        >
                          {niche}
                        </Box>
                      ))}
                    </Box>
                    {domainsCount < 11 && (
                      <Box className="d-flex justify-content-end">
                        <RegenerateButton action={RegenerateDomains} />
                      </Box>
                    )}
                  </Box>
                ) : null}
              </Box>
            </div>
            {/* {selectedDomains.length > 0 ? (
            <Box className="my-3 d-flex justify-content-end">
              <Box
                className="px-3 py-4 w-75 rounded shadow"
                style={{ backgroundColor: "#f2e5e4" }}
              >
                {selectedDomains.length > 0 ? (
                  <Typography>
                    <b>Topic : </b>
                    {selectedDomains[0]}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : null} */}
            {topics.length > 0 ? (
              <Box className="my-3 d-flex justify-content-start">
                <Box
                  className="px-3 px-md-5 py-4  py-md-5 rounded shadow bg-light"
                  stye={{ backgroundColor: "white !important" }}
                >
                  {topics.length > 0 ? (
                    <>
                      <Typography className="py-3">
                        {" "}
                        Explore the upcoming video topics below, and simply
                        click on a topic to generate a script for that
                        particular subject.
                      </Typography>
                      {topics.map((topic, index) => (
                        <Box
                          key={index}
                          className="mx-2 px-3 py-3 my-2 cursor-pointer rounded"
                          style={{
                            display: "inline-block",
                            border:
                              "2px solid var(--collection-1-primary-main)",
                            backgroundColor: selectedTopics.includes(topic)
                              ? "var(--collection-1-primary-main)"
                              : "white",
                            color: selectedTopics.includes(topic)
                              ? "white"
                              : "black",
                          }}
                          onClick={() => handleTopicClick(topic)}
                        >
                          {topic}
                        </Box>
                      ))}
                    </>
                  ) : null}
                  {/* <Box className="d-flex justify-content-end">
                      <RegenerateButton action={RegenerateDomains} />
                    </Box> */}
                </Box>
              </Box>
            ) : null}
            {selectedTopics.length > 0 ? (
              <>
                <UserCard data={selectedTopics[0]} />
              </>
            ) : null}
            {script && (
              <BotCard
                topic={selectedTopics[0]}
                script={script}
                isScript={true}
                layoutContainerRef={layoutContainerRef}
              />
            )}
            <Button
              type="primary"
              shape="circle"
              icon={<ArrowUpOutlined />}
              size="large"
              style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                display: layoutContainerRef.current ? "block" : "none",
              }}
              onClick={handleScrollToTop}
            />
          </Content>
          <Footer
            className="d-none"
            style={{
              textAlign: "center",
              position: "fixed",
              bottom: "0",
            }}
          >
            ©{new Date().getFullYear()} creatorstride.com. All Rights Reserved.
          </Footer>
        </Layout>
      ) : null}
      {isLoading && <LoadingOverlay text={loadingText} />}
      {isQuotaExceeded && (
        <Dialog open="true">
          <Box className="d-flex justify-content-end px-1 py-1">
            <IconButton onClick={() => setIsQuotaExceeded(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="pb-5 px-4 px-md-5 text-center">
            <Box className="pb-3 mx-auto text-center cursor-pointer">
              <img
                src={logo}
                alt="logo"
                height="30px"
                width="auto"
                onClick={() => navigate("/")}
              />
            </Box>
            <Box className="py-2">{dialogContent}</Box>
          </Box>
        </Dialog>
      )}
    </Layout>
  );
};
export default NewPlayground;
