import { Box, Typography } from "@mui/material";
import USER_LOGO from "../../assets/user.png";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import React, { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { UseDispatch, useSelector } from "react-redux";
import {
  updateReduxUsername,
  reduxLogoutHandler,
} from "../../redux/actions/actions";
import { useDispatch } from "react-redux";

const UserLoginAvatar = ({ width, height, collapsed, textColor }) => {
  const dispatch = useDispatch();
  const reduxUserName = useSelector((state) => state.reduxUserName);
  const session_id = Cookies.get("session_id");
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log("The reduxUserName from useEffect is : ", reduxUserName);
    if (!reduxUserName) {
      const payload = {
        session_id,
      };
      fetch(`${process.env.REACT_APP_SERVER_URL}/fetchUserProfileDetails`, {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response data from fetch user profile details : ", data);
          if (data.status === "success") {
            setUserName(data.username);
            dispatch(updateReduxUsername(data.username)); // Dispatch the action here
          }
        });
    }
  }, [session_id, reduxUserName, dispatch]);

  const LogoutHandler = () => {
    handleClose();
    navigate("/");
    Cookies.set("isLoggedIn", false);
    Cookies.remove("session_id");
    dispatch(reduxLogoutHandler());
  };

  return (
    <>
      <Box className="d-flex justify-content-center cursor-pointer bg-light rounded px-3 py-3 shadow w-100 mx-1 mx-md-3">
        <Box className="d-flex align-items-center gap-3 w-100">
          <Box>
            {/* <img src={USER_LOGO} width={width} height={height} /> */}
            <Avatar onClick={handleClick} />
          </Box>
          <Box
            className="text-center"
            onClick={handleClick}
            style={{ display: collapsed ? "none" : "block", color: "black" }}
          >
            {reduxUserName}
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                LogoutHandler();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

UserLoginAvatar.defaultProps = {
  width: "40px",
  height: "auto",
  collapsed: false,
  textColor: "black",
};

export default UserLoginAvatar;
