import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './FlatBlogCard.css'

const FlatBlogCard = ({ title, content, imageUrl, postedOn, likes }) => {
  const navigate=useNavigate()
  function findMinContentLength(contents) {
    const contentArray=contents.split('')
    const time=Math.ceil(contentArray.length / 200)
    return time;
  }

  

  return (
    <>
      <Box
        class="d-flex justify-content-between align-items-stretch gap-3 my-3 bg-light-st shadow py-2 px-2 rounded w-100 cursor-pointer flat-blog-card mx-auto position-relative"
        style={{ minHeight: "100px",borderBottom:'1px solid var(--collection-1-primary-main)'}}
        onClick={()=>navigate(`/blog/${title}`)}
      >
        <img
          className="w-25"
          src={imageUrl}
          alt="article-image"
          width="auto"
          height="80px"
        />
        <Typography className="w-75 h5 text-dark">{title}</Typography>
        <Box  class="px-2 py-2 text-light rounded" style={{position:'absolute',bottom:'-10px', right:'10px',backgroundColor:'var(--collection-1-primary-main)',fontSize:'0.7rem',fontWeight:'600'}}>
            {`${findMinContentLength(content)} min read`}
        </Box>
      </Box>
    </>
  );
};

export default FlatBlogCard;
